import { observer } from "mobx-react";
import { FC } from "react";
import { Dialog, DialogContent } from "@mui/material";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import SecondaryCustomButton from "components/button/SecondaryButton";

type DiscardFilterPopupChangesProps = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
  isLoading: boolean;
};

const DiscardFilterPopupChanges: FC<DiscardFilterPopupChangesProps> = observer((props) => {
  return (
    <>
      <StyledDialog open={props.open}>
        <DialogTitle>
          Are you sure you want to discard changes to your filters?
        </DialogTitle>
        <TrendsLabelText>
          You have unsaved changes. Any changes made to your filters will be discarded.
        </TrendsLabelText>

        <StyledDialogContent>
          <ButtonWrapper>
            <DiscardCustomButton 
              variant="contained"
              onClick={props.onDiscard}
              disabled={props.isLoading}
            >
              Yes, discard changes
            </DiscardCustomButton>

            <StayCustomButton onClick={props.onClose}>
              Stay on to apply filters
            </StayCustomButton>
          </ButtonWrapper>
        </StyledDialogContent>
      </StyledDialog>
    </>
  );
});

export default DiscardFilterPopupChanges;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 586px !important;
    overflow: hidden !important;
  }

  .MuiDialogContent-root {
    padding: 0px 48px !important;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  margin: 40px 0px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorGrayForeground6);
    border-radius: 20px;
    border: 3px solid var(--colorGrayForeground6);
  }
`;

const DialogTitle = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  padding: 0px 48px;
  margin-top: 40px;
`;

const TrendsLabelText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
  padding: 0px 48px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

const DiscardCustomButton = styled(CustomButton)`
  width: 40%;
  height: 36px;
`;

const StayCustomButton = styled(SecondaryCustomButton)`
  width: 40%;
  height: 36px;
  color: var(--colorNeutralForeground5) !important;
  border: 1px solid var(--colorNeutralForeground5) !important;
`;
