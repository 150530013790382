import NotificationStore, { ImportReview } from "../store";
import { EmployeeImportUIState, FileUploaded } from "types/employee-import.type";
import { makeAutoObservable, reaction } from "mobx";
import { importJobApi } from "api/employeeImport/useImportJob";
import { importStatusApi } from "api/employeeImport/useImportStatus";
import { useAddEditImportStatus } from "api/employeeImport/useAddEditImportStatus";
import { useGeneratePresignURL } from "api/useGeneratePresignURL";
import { useUploadS3 } from "api/useUploadS3";
import { SELECTED_ACTION_REQUIRED } from "constants/import-emploee";
import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { v4 as uuidv4 } from "uuid";
import useCancelImport from "api/employeeImport/useCancelImport";
import useConfirmImport from "api/employeeImport/useConfirmImport";
import { ImportStatusType } from "constants/import-status.type";
import { ImportStatusTypeKeys } from "types/import-status-type-key";
import { ImportStatus } from "types/import-status";
import { Metadata } from "types/sse-event";
import { ImportErrror, ImportValidationError } from "types/import-validation-error";
import { getFormattedSize } from "utils/getFilesizeFormatted";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { format } from "date-fns";
import { parseFileUploaded } from "utils/parseFileUploaded";

class EmployeeImportManagerStore {
  importId = "";

  constructor() {
    reaction(
      () => NotificationStore.employeeImportEvent,
      (event) => {
        if (event) {
          this.latestType = event.type;
          switch (event.type) {
            case ImportStatusType.EXCEL_ONGOING.valueOf():
              this.getOngoing(event.metadata);
              break;
            case ImportStatusType.EXCEL_UPLOAD_SUCCESS.valueOf():
              this.getUploadSuccess(event.metadata);
              break;
            case ImportStatusType.EXCEL_UPLOAD_FAILED.valueOf():
              this.uploadFailed(event.metadata);
              break;
            case ImportStatusType.EXCEL_CANCELED.valueOf():
              this.getCancelled();
              break;
            case ImportStatusType.EXCEL_IMPORT_SUCCESS.valueOf():
              this.getImportSuccess(event.metadata);
              break;
            case ImportStatusType.EXCEL_IMPORT_FAILED.valueOf():
              this.getImportFailed(event.metadata);
              break;
            case ImportStatusType.PROGRESS_EXCEL_ONGOING.valueOf():
              this.getUploadProgress(event.metadata);
              break;
            case ImportStatusType.PROGRESS_EXCEL_IMPORT_ONGOING.valueOf():
              this.getImportProgress(event.metadata);
              break;
            default:
              break;
          }
        }
      },
    );
    if (StoreLayout.isEnableImportV2) {
      this.getOrGenerateImportID();
    }
    makeAutoObservable(this);
  }

  uploadExcelProgress: number | null = null;
  importExcelProgress: number | null = null;
  importSelectedFile: File = null;
  importSelectedMergeChoice: string = null;
  lockMergeReplace: boolean = false;
  canOpenUploadFailedDialog: boolean = true;
  canOpenImportUnsuccessfulDialog: boolean = true;
  canOpenBanner = true;
  hasOngoingImport = false;

  employeeImportUIState: EmployeeImportUIState = {
    uploadStatus: "empty",
    reviewStatus: "not started",
    importStatus: "not started",
    cancelStatus: null,
  };

  employeeImportUploadFile: FileUploaded = {
    fileId: null,
    filename: null,
    fileSize: null,
    progress: null,
    status: "empty",
  };

  importReview: ImportReview | null = null;
  latestType: ImportStatusTypeKeys | null = null;
  importSuccessDate: string | null = null;
  importIdFromParams: string | null = null;

  openUploadSuccessToast = false;
  openDeleteFileSuccessToast = false;

  setOpenUploadSuccessToast = (value: boolean) => {
    this.openUploadSuccessToast = value;
  };

  setOpenDeleteFileSuccessToast = (value: boolean) => {
    this.openDeleteFileSuccessToast = value;
  };

  get formatDate() {
    if (!this.importSuccessDate) return null;
    return format(new Date(this.importSuccessDate), "dd MMM yyyy h:mm a");
  }

  get parsedFile(): FileUploaded[] {
    try {
      return parseFileUploaded({
        file: this.importSelectedFile,
        fileUploaded: this.employeeImportUploadFile,
        progress: this.uploadExcelProgress,
      });
    } catch (e) {
      return [];
    }
  }

  setOngoingImport = (value: boolean) => {
    this.hasOngoingImport = value;
  };

  setHasUnexpectedError = (value: boolean) => {
    this.employeeImportUIState.hasUnexpectedError = value;
  };

  setCanOpenBanner = (value: boolean) => {
    this.canOpenBanner = value;
  };

  setCanOpenImportUnsuccessfulDialog = (value: boolean) => {
    this.canOpenImportUnsuccessfulDialog = value;
  };

  isOpenImportFailedDialog = () => {
    if (
      this.canOpenImportUnsuccessfulDialog &&
      this.employeeImportUIState.importStatus === "error"
    ) {
      return true;
    }

    return false;
  };

  isOpenUploadFailedDialog = () => {
    if (
      this.canOpenUploadFailedDialog &&
      this.employeeImportUIState.uploadStatus === "error" &&
      this.employeeImportUIState.importStatus !== "error"
    ) {
      return true;
    }

    return false;
  };

  isOpenFileImportUnsucessfulDialog = () => {
    const hasPermission = StorePermission.entityPermissions.editEmployee.hasPermission;
    const isImportFailed = this.employeeImportUIState.importStatus === "error";
    const createdBy = this.employeeImportUIState.createdBy === StoreLayout.currentUserId;

    return hasPermission && isImportFailed && createdBy && this.canOpenImportUnsuccessfulDialog;
  };

  setCanOpenUploadFailedDialog = (value: boolean) => {
    this.canOpenUploadFailedDialog = value;
  };

  setInitialImportUIState = () => {
    this.lockMergeReplace = false;
    this.employeeImportUIState = {
      uploadStatus: "empty",
      reviewStatus: "not started",
      importStatus: "not started",
      cancelStatus: null,
    };
  };

  findCurrentImport = (
    importStatus: ImportStatus[],
    statusType: ImportStatusTypeKeys,
    importId: string,
  ) => {
    return importStatus.find((x) => x.status === statusType.valueOf() && x.import_id === importId);
  };

  private generateImportID = () => {
    return uuidv4();
  };

  getOrGenerateImportID = async () => {
    try {
      const ongoingImport = await importStatusApi({
        entity_id: StoreLayout.currentEntityId,
        status: ImportStatusType.EXCEL_ONGOING,
      });

      if (ongoingImport && ongoingImport.data && ongoingImport.data.length > 0) {
        this.importId = ongoingImport.data[0].import_id;
        console.log("Ongoing import found", this.importId);
      } else {
        throw new Error("No ongoing import found");
      }
    } catch (error) {
      this.importId = this.generateImportID();

      console.error("Error getting ongoing import status", error);
    }
  };

  changeImportSelectedMergeChoice = (choice: string) => {
    this.importSelectedMergeChoice = choice;
  };

  regenerateImportID = () => {
    this.importId = this.generateImportID();
  };

  setUploadProgress(progress: number) {
    this.uploadExcelProgress = progress;
  }

  setImportProgress(progress: number) {
    this.importExcelProgress = progress;
  }

  setImportReview(review: ImportReview) {
    this.importReview = review;
  }

  setImportIdFromParams(id: string) {
    this.importIdFromParams = id;
  }

  async fetchImportStatusWithImportId(importId: string) {
    try {
      const importStatus = await importStatusApi({
        entity_id: StoreLayout.currentEntityId,
        import_id: importId,
      });

      if (importStatus.data.length > 0) {
        const isUploadSuccess = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_UPLOAD_SUCCESS,
          importId,
        );

        const isConfirmSuccess = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_IMPORT_SUCCESS,
          importId,
        );

        const isUploadFailed = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_UPLOAD_FAILED,
          importId,
        );
        const isImportFailed = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_IMPORT_FAILED,
          importId,
        );
        const isImportSuccess = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_IMPORT_SUCCESS,
          importId,
        );

        this.importSuccessDate = importStatus.data[0].update_at;

        const importJob = await importJobApi({
          entity_id: StoreLayout.currentEntityId.toString(),
          import_id: importId,
        });

        this.employeeImportUploadFile = {
          fileId: importJob.data.id.toString(),
          filename: importJob.data.raw_data_filename,
          fileSize: importJob.data.filezise,
          progress: 100,
          status: "success",
        };

        this.importSelectedMergeChoice = importJob.data.replace
          ? SELECTED_ACTION_REQUIRED.replace_entire_list
          : SELECTED_ACTION_REQUIRED.remove_employees;
        this.lockMergeReplace = true;

        if (isUploadSuccess) {
          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "not started",
            cancelStatus: null,
          };

          this.uploadExcelProgress = 100;
        } else if (isConfirmSuccess) {
          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "success",
            cancelStatus: null,
          };
          this.uploadExcelProgress = 100;
          this.importExcelProgress = 100;
        } else if (isUploadFailed) {
          this.uploadExcelProgress = null;
          this.canOpenUploadFailedDialog = true;
          let validationError: ImportErrror = null;
          try {
            const parsed = JSON.parse(importStatus.data[0].errors);
            const childParsed = JSON.parse(parsed.error_validation);
            validationError = {
              error: parsed.error,
              error_validation: childParsed,
            };
          } catch (error) {
            console.error("Error parsing validation errors", error);
          }
          this.employeeImportUIState = {
            uploadStatus: "error",
            reviewStatus: "not started",
            importStatus: "not started",
            errorUploadMsg: validationError.error,
            validationErrors: validationError.error_validation,
            cancelStatus: null,
            createdBy: importStatus.data[0].created_by,
          };
        } else if (isImportFailed) {
          this.importExcelProgress = null;
          this.canOpenUploadFailedDialog = true;
          let validationError: ImportErrror = null;
          try {
            const parsed = JSON.parse(importStatus.data[0].errors);
            const childParsed = JSON.parse(parsed.error_validation);
            validationError = {
              error: parsed.error,
              error_validation: childParsed,
            };
          } catch (error) {
            console.error("Error parsing validation errors", error);
          }
          console.log(`Validation errors: ${JSON.stringify(validationError)}`);
          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "error",
            errorImportMsg: validationError.error,
            validationErrors: validationError.error_validation,
            createdBy: importStatus.data[0].created_by,
            cancelStatus: null,
          };
          this.uploadExcelProgress = 100;
        } else if (isImportSuccess) {
          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "success",
            cancelStatus: null,
          };
          this.uploadExcelProgress = 100;
        }
        this.importReview = JSON.parse(importStatus.data[0].result);
        this.uploadExcelProgress = 100;
        this.importExcelProgress = 100;
      }
    } catch (error) {
      console.error("Error fetching import status with ID", error);
    }
  }

  async getOngoing(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        status: ImportStatusType.EXCEL_ONGOING,
      });

      if (importStatus.data.length > 0) {
        const isUploadSuccess = importStatus.data.find(
          (v) => v.status === ImportStatusType.EXCEL_UPLOAD_SUCCESS,
        );

        const isOngoing = importStatus.data.find(
          (v) => v.status === ImportStatusType.EXCEL_ONGOING,
        );

        const isConfirmSuccess = this.findCurrentImport(
          importStatus.data,
          ImportStatusType.EXCEL_IMPORT_SUCCESS,
          meta.import_id,
        );

        this.importSuccessDate = importStatus.data[0].update_at;

        if (!this.importIdFromParams && !isUploadSuccess) {
          return;
        }

        if (isUploadSuccess) {
          const importJob = await importJobApi({
            entity_id: meta.entity_id,
            import_id: importStatus.data[0].import_id,
          });

          this.employeeImportUploadFile = {
            fileId: importJob.data.id.toString(),
            filename: importJob.data.raw_data_filename,
            fileSize: importJob.data.filezise,
            progress: 100,
            status: "success",
          };

          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "not started",
            cancelStatus: null,
          };
          this.importSelectedMergeChoice = importJob.data.replace
            ? SELECTED_ACTION_REQUIRED.replace_entire_list
            : SELECTED_ACTION_REQUIRED.remove_employees;
          this.lockMergeReplace = true;

          this.uploadExcelProgress = 100;
          this.importReview = JSON.parse(importStatus.data[0].result);
        } else if (isConfirmSuccess) {
          this.employeeImportUIState = {
            uploadStatus: "success",
            reviewStatus: "success",
            importStatus: "success",
            cancelStatus: null,
          };
        } else if (isOngoing) {
          this.employeeImportUIState = {
            uploadStatus: "loading",
            reviewStatus: "not started",
            importStatus: "not started",
            cancelStatus: null,
          };
          this.uploadExcelProgress = 0;
          this.importExcelProgress = 0;
        }
      } else {
        this.uploadExcelProgress = 0;
        this.importExcelProgress = 0;
        this.importReview = null;
        this.setInitialImportUIState();
      }
    } catch (error) {
      console.error("Error getting ongoing import status", error);
    }
  }

  async getUploadSuccess(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
        status: ImportStatusType.EXCEL_UPLOAD_SUCCESS,
      });

      const importJob = await importJobApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
      });

      this.employeeImportUploadFile = {
        fileId: importJob.data.id.toString(),
        filename: importJob.data.raw_data_filename,
        fileSize: importJob.data.filezise,
        progress: 100,
        status: "success",
      };

      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "not started",
        cancelStatus: null,
      };
      this.importReview = JSON.parse(importStatus.data[0].result);
      this.uploadExcelProgress = 100;
      this.setOpenUploadSuccessToast(true);
      this.lockMergeReplace = true;
    } catch (error) {
      console.error("Error getting upload success import status", error);
    }
  }

  async getCancelled() {
    try {
      this.setInitialImportUIState();
    } catch (error) {
      console.error("Error getting cancelled import status", error);
    }
  }

  async uploadFailed(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
      });

      this.uploadExcelProgress = null;
      this.canOpenUploadFailedDialog = true;
      let validationError: ImportErrror = null;
      try {
        const parsed = JSON.parse(importStatus.data[0].errors);
        const childParsed = JSON.parse(parsed.error_validation);
        validationError = {
          error: parsed.error,
          error_validation: childParsed,
        };
      } catch (error) {
        console.error("Error parsing validation errors", error);
      }

      try {
        const importJob = await importJobApi({
          entity_id: StoreLayout.currentEntityId.toString(),
          import_id: this.importId,
        });

        this.employeeImportUploadFile = {
          fileId: importJob.data.id.toString(),
          filename: importJob.data.raw_data_filename,
          fileSize: importJob.data.filezise,
          progress: 100,
          status: "error",
        };
      } catch (e) {
        console.error("Error getting upload failed import");
      }

      this.employeeImportUIState = {
        uploadStatus: "error",
        reviewStatus: "not started",
        importStatus: "not started",
        errorUploadMsg: validationError.error,
        validationErrors: validationError.error_validation,
        cancelStatus: null,
        createdBy: importStatus.data[0].created_by,
      };
    } catch (error) {
      console.error("Error getting upload failed import status", error);
    }
  }

  async getImportSuccess(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
        status: ImportStatusType.EXCEL_IMPORT_SUCCESS,
      });

      this.importReview = JSON.parse(importStatus.data[0].result);
      this.uploadExcelProgress = 100;
      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "success",
        cancelStatus: null,
      };
    } catch (error) {
      console.error("Error getting import success import status", error);
    }
  }

  async getImportFailed(meta: Metadata) {
    try {
      const importStatus = await importStatusApi({
        entity_id: meta.entity_id,
        import_id: meta.import_id,
      });

      this.importExcelProgress = null;
      this.canOpenUploadFailedDialog = true;
      let validationError: ImportErrror = null;

      try {
        const parsed = JSON.parse(importStatus.data[0].errors);
        const childParsed = JSON.parse(parsed.error_validation);
        validationError = {
          error: parsed.error,
          error_validation: childParsed,
        };
      } catch (error) {
        console.error("Error parsing validation errors", error);
      }

      try {
        const importJob = await importJobApi({
          entity_id: StoreLayout.currentEntityId.toString(),
          import_id: this.importId,
        });

        this.employeeImportUploadFile = {
          fileId: importJob.data.id.toString(),
          filename: importJob.data.raw_data_filename,
          fileSize: importJob.data.filezise,
          progress: 100,
          status: "error",
        };
      } catch (e) {
        console.error("Error getting upload failed import");
      }

      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "error",
        errorImportMsg: validationError.error,
        createdBy: importStatus.data[0].created_by,
        cancelStatus: null,
      };
    } catch (error) {
      console.error("Error getting import failed import status", error);
    }
  }

  async getUploadProgress(meta: Metadata) {
    try {
      this.uploadExcelProgress = meta.progress;
      this.employeeImportUIState.uploadStatus = "loading";
    } catch (error) {
      console.error("Error getting progress import status", error);
    }
  }

  async getImportProgress(meta: Metadata) {
    try {
      this.importExcelProgress = meta.progress;
      this.employeeImportUIState.importStatus = "loading";
    } catch (error) {
      console.error("Error getting progress import status", error);
    }
  }
  handleImportSelectedFile = (file: File) => {
    this.importSelectedFile = file;
  };

  handleUpload = async () => {
    this.employeeImportUIState.uploadStatus = "loading";
    this.uploadExcelProgress = 0;
    const ongoingImport = await importStatusApi({
      entity_id: StoreLayout.currentEntityId,
      status: ImportStatusType.EXCEL_ONGOING,
    });

    if (ongoingImport && ongoingImport.data && ongoingImport.data.length > 0) {
      await this.cancelImport();
    } else {
      this.regenerateImportID();
    }

    const changeImportStatus = await this.addEditImportStatus(
      ImportStatusType.EXCEL_ONGOING,
      this.importId,
    );

    if (changeImportStatus?.status !== 201 && changeImportStatus?.status !== 200) {
      this.employeeImportUIState = {
        uploadStatus: "error",
        reviewStatus: "not started",
        importStatus: "not started",
        errorUploadMsg: "Oops! Something went wrong.",
      };
      return;
    }

    this.employeeImportUIState = {
      uploadStatus: "loading",
      ...this.employeeImportUIState,
    };

    try {
      const response = await useGeneratePresignURL("employee-import", {
        filename: this.importSelectedFile.name,
        filesize: getFormattedSize(this.importSelectedFile.size),
        import_id: this.importId,
        entity_id: StoreLayout.currentEntityId,
        insert: true,
        replace: this.importSelectedMergeChoice === SELECTED_ACTION_REQUIRED.replace_entire_list,
      });

      const uploadURL = response.data.url;

      await useUploadS3({
        file: this.importSelectedFile,
        presignUrl: uploadURL,
      });
    } catch (err) {
      console.log(`Error uploading file: ${err}`);
      // notify server that upload failed with id from  *addEditImportStatus of "EXCEL_ONGOING"*
      // changeImportStatus.data is returning id from *addEditImportStatus of "EXCEL_ONGOING"*
      this.addEditImportStatus(
        ImportStatusType.EXCEL_UPLOAD_FAILED,
        this.importId,
        changeImportStatus.data,
      );
      this.employeeImportUIState = {
        uploadStatus: "error",
        errorUploadMsg: "Oops! Something went wrong. Try uploading your file again.",
        importStatus: "not started",
        reviewStatus: "not started",
      };

      this.setOpenUploadSuccessToast(true);
    }
  };

  clearFileUpload = () => {
    this.importSelectedFile = null;
  };

  setInitialUploadState = () => {
    this.employeeImportUploadFile = {
      fileId: null,
      filename: null,
      fileSize: null,
      progress: null,
      status: "empty",
    };
  };

  private cancelImport = async (): Promise<any> => {
    try {
      const cancel = await useCancelImport({
        entity_id: StoreLayout.currentEntityId,
        import_id: this.importId,
      });

      return cancel;
    } catch (err) {
      console.log(err);
      return "Failed to cancel import";
    }
  };
  handleCancelImport = async (cb?: () => void) => {
    try {
      await this.getOrGenerateImportID();

      const cancel = await this.cancelImport();

      // cancel has result means has errors
      if (cancel) {
        return;
      }

      this.setInitialImportUIState();

      this.setInitialUploadState();
      this.clearFileUpload();
      this.regenerateImportID();

      if (cb) {
        cb.call(this);
      }
    } catch (err) {
      console.log(err);
    }
  };

  handleConfirmReview = async () => {
    // console.log(`confirming review for import id: ${this.importId}`);
    try {
      const confirm = await useConfirmImport({
        entity_id: StoreLayout.currentEntityId,
        import_id: this.importId,
      });

      await useConfirmImport({
        entity_id: StoreLayout.currentEntityId,
        import_id: this.importId,
      });

      if (confirm) {
        // if confirm has result means has errors
        return;
      }

      this.setInitialUploadState();

      this.employeeImportUploadFile = null;
      this.importSelectedFile = null;

      const importJob = await importJobApi({
        entity_id: StoreLayout.currentEntityId.toString(),
        import_id: this.importId,
      });

      this.employeeImportUploadFile = {
        fileId: importJob.data.id.toString(),
        filename: importJob.data.raw_data_filename,
        fileSize: importJob.data.filezise,
        progress: 100,
        status: "success",
      };

      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "success",
      };
    } catch (e) {
      this.employeeImportUIState = {
        uploadStatus: "success",
        reviewStatus: "success",
        importStatus: "error",
      };
    }
  };

  addEditImportStatus = async (status: string, import_id: string, id?: number | undefined) => {
    try {
      const importType = "EMPLOYEE";

      const response = await useAddEditImportStatus({
        id,
        entity_id: StoreLayout.currentEntityId,
        import_type: importType,
        status: status,
        import_id: import_id,
      });

      if (response?.status === 201 || response?.status === 200) {
        return response;
      }

      throw response;
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    }
  };

  async checkImportStatus() {
    try {
      const response = await importStatusApi({
        entity_id: StoreLayout.currentEntityId,
        status: ImportStatusType.EXCEL_ONGOING,
      });

      if (![200, 201].includes(response.status)) {
        return;
      }

      if (!response.data) {
        this.setOngoingImport(false);
        return;
      }

      if (response.data.length > 0) {
        this.setOngoingImport(true);
      }
    } catch (e) {
      // TODO: decide what to do here
      console.log(e);
    }
  }

  handleUploadError = (error: string) => {
    this.employeeImportUIState = {
      uploadStatus: "error",
      importStatus: "not started",
      reviewStatus: "not started",
      errorUploadMsg: error,
    };
    this.clearFileUpload();
    this.setInitialUploadState();
  };

  clearUploadError = async () => {
    await this.resetInitialState();
  };

  handleFileDelete = async () => {
    await this.resetInitialState();
    this.setOpenDeleteFileSuccessToast(true);
  };

  private resetInitialState = async () => {
    this.setInitialImportUIState();
    this.setInitialUploadState();
    this.clearFileUpload();
    await this.handleCancelImport();

    this.regenerateImportID();
  };

  isInitial = () =>
    this.employeeImportUIState.uploadStatus === "empty" &&
    this.employeeImportUIState.reviewStatus === "not started" &&
    this.employeeImportUIState.importStatus === "not started";

  isUploadSuccess = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.importStatus === "not started";

  isOnReview = () => this.employeeImportUIState.uploadStatus === "success";
  isNeedConfirm = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.reviewStatus === "success" &&
    this.employeeImportUIState.importStatus === "not started";

  isImportInProgress = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.reviewStatus === "success" &&
    this.employeeImportUIState.importStatus === "loading";

  isFinished = () =>
    this.employeeImportUIState.uploadStatus === "success" &&
    this.employeeImportUIState.reviewStatus === "success" &&
    this.employeeImportUIState.importStatus === "success";
}

const importStore = new EmployeeImportManagerStore();

export default importStore;
