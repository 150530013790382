import { observer } from "mobx-react";

import store from "./store";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";

const BelowThresholdLabel = observer(() => {
  return (
    <Container>
      <EmptyWrapper>
        <ErrorIcon />
        <EmptyText>
            {`It looks like there are fewer than ${store.selectedSurvey?.threshold} responses that match your filters.
            Try removing some filters before applying them.`}
        </EmptyText>
      </EmptyWrapper>
    </Container>
  );
});

export default BelowThresholdLabel;

const Container = styled.div`
  width: 100%;
  background-color: var(--colorNeutralBackground1);
  border-radius: 8px;
`;

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorPalleteLightBlue);
  padding: 10px 48px;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;
