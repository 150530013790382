import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import styled from "styled-components";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type AccordionsProps = {
  items: {
    title: string;
    list: string[];
  }[];
};
const NebulaAccordion = ({ items }: AccordionsProps) => {
  return (
    <AccordionWrapper>
      {items.map((error, index) => (
        <AccordionItem title={error.title} listItems={error.list} key={index} />
      ))}
    </AccordionWrapper>
  );
};

type ItemsProps = {
  title: string;
  listItems: string[];
};

const AccordionItem = ({ title, listItems }: ItemsProps) => {
  return (
    <Accordion
      elevation={0}
      sx={{ "&:before": { height: "0px" } }}
      style={{ marginBottom: "8px", width: "100%", boxShadow: "none" }}
    >
      <AccordionSummary style={{ width: "100%" }} expandIcon={<ExpandIconColored />}>
        <AccordionTitleText>{title}</AccordionTitleText>
      </AccordionSummary>
      <AccordionDetailsWrapper>
        {listItems.map((value, index) => (
          <AccordionListItems key={index}>
            <AccordionListText>{value}</AccordionListText>
          </AccordionListItems>
        ))}
      </AccordionDetailsWrapper>
    </Accordion>
  );
};

const AccordionTitleText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const AccordionWrapper = styled.div`
  width: 100%;
`;

const AccordionListItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccordionListText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const ExpandIconColored = styled(ExpandMoreIcon)`
  color: var(--colorBrandForeground1);
`;

const AccordionDetailsWrapper = styled(AccordionDetails)`
  display: block;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 0 12px 12px 12px;
  max-height: 200px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid transparent;
    background-clip: padding-box;
    background: rgb(201, 201, 201);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(166, 166, 166);
  }
`;

export default NebulaAccordion;
