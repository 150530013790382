import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getSurveyPermissions = (entityId: number, searchValue: string): Promise<any> => {
  const query: {
    entity_id: string;
    search_value: string;
  } = {
    entity_id: entityId.toString(),
    search_value: searchValue,
  };

  const url = createUrl({
    path: "/permission/entity_permissions/SurveyPermissions",
    query: query,
  });
  return http.getGO(url);
};
