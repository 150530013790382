import { IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import styled from "styled-components";

type SearchResultBannerProps = {
  id?: string;
  resultsCount: number;
  searchText: string;
  onClearContainer: () => void;
  clearLabel?: string;
};

const SearchResultBanner = ({
  id = "Search_Result_Banner",
  resultsCount,
  searchText,
  onClearContainer,
  clearLabel = "Clear search",
}: SearchResultBannerProps) => {
  return (
    <SearchResultContainer id={id}>
      <SearchInformation>
        {resultsCount} results found for {`"${searchText}"`}
      </SearchInformation>
      <SearchClearContainer onClick={onClearContainer}>
        <IconButton id="SearchInformation_ClearBtn">
          <CloseIcon
            style={{ color: "var(--colorBrandForeground1)", width: "14px", height: "14px" }}
          />
        </IconButton>
        <label>{clearLabel}</label>
      </SearchClearContainer>
    </SearchResultContainer>
  );
};

const SearchResultContainer = styled.div`
  background-color: var(--colorPalleteLightBlue);
  border-radius: 4px;
  padding: 8px 24px 8px 24px;
  display: flex;
  column-gap: 16px;
  align-items: center;
  margin-bottom: 16px;
`;

const SearchInformation = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const SearchClearContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;

  label {
    color: var(--colorBrandForeground1);
    cursor: pointer;
  }
`;

export default SearchResultBanner;
