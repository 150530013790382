import { useEffect, useMemo, useState } from "react";
import store from "../store";
import { observer } from "mobx-react";
import styled from "styled-components";
import { DashboardPermission, SurveyManagementPermissions } from "types/permission";
import {
  DASHBOARD_PERMISSION_COLUMN_HEAD,
  DASHBOARD_PERMISSION_VIEW_ALL_RESPONDENTS,
  DASHBOARD_PERMISSION_VIEW_OWN_TEAM,
} from "constants/SurveyPermission";
import CustomSquareCheckbox from "components/customSquareCheckbox";

type TablePermissionProps = {
  onHasChanges: (hasChanges: boolean) => void;
};

const TablePermission = observer(({ onHasChanges }: TablePermissionProps) => {
  const [surveyManagementPermissions, setSurveyManagementPermissions] =
    useState<SurveyManagementPermissions | null>(store.surveyManagementPermissions);
  const [originalSurveyManagementPermissions, setOriginalSurveyManagementPermissions] =
    useState<SurveyManagementPermissions | null>(store.surveyManagementPermissions);
  const [dashboardPermissions, setDashboardPermissions] = useState<DashboardPermission[] | []>(
    store.dashboardPermissions,
  );
  const [originalDashboardPermissions, setOriginalDashboardPermissions] = useState<
    DashboardPermission[] | []
  >(store.dashboardPermissions);

  useEffect(() => {
    setDashboardPermissions(store.dashboardPermissions);
    setOriginalDashboardPermissions(store.dashboardPermissions);
  }, [store.dashboardPermissions]);

  useEffect(() => {
    if (store.surveyManagementPermissions) {
      setSurveyManagementPermissions(store.surveyManagementPermissions);
      setOriginalSurveyManagementPermissions(store.surveyManagementPermissions);
    }
  }, [store.surveyManagementPermissions]);

  const hasChanges = useMemo(() => {
    let isChanged = false;
    let hasSurveyManagementChanges = false;

    if (surveyManagementPermissions) {
      hasSurveyManagementChanges = Object.keys(surveyManagementPermissions).some(
        (key) =>
          surveyManagementPermissions[key as keyof SurveyManagementPermissions] !==
          originalSurveyManagementPermissions[key as keyof SurveyManagementPermissions],
      );
    }

    if (hasSurveyManagementChanges) {
      store.setData(surveyManagementPermissions, "updatedSurveyManagementPermissions");
    } else {
      store.setData(null, "updatedSurveyManagementPermissions");
    }

    dashboardPermissions?.forEach((item: DashboardPermission, index) => {
      const original = originalDashboardPermissions[index];

      const hasViewAllChanged =
        item.allow_view_all_respondent_data !== original.allow_view_all_respondent_data;
      const hasViewOwnChanged = item.allow_view_own_team_data !== original.allow_view_own_team_data;

      if (hasViewAllChanged || hasViewOwnChanged) {
        const itemToBeUpdated = {
          id: item.id,
          code: item.code,
          allow_view_all_respondent_data: item.allow_view_all_respondent_data,
          allow_view_own_team_data: item.allow_view_own_team_data,
        } as DashboardPermission;

        store.appendToDashboardPermissionUpdate(itemToBeUpdated);
      } else {
        store.RemoveFromDashboardPermissionUpdate(item.id, item.code);
      }

      if (!isChanged) {
        isChanged = hasViewAllChanged || hasViewOwnChanged || hasSurveyManagementChanges;
      }
    });

    return isChanged;
  }, [
    dashboardPermissions,
    originalDashboardPermissions,
    surveyManagementPermissions,
    originalSurveyManagementPermissions,
  ]);

  useEffect(() => {
    onHasChanges(hasChanges);
  }, [hasChanges, onHasChanges]);

  const handleDashboardPermissionChange = (
    index: number,
    checkType: "viewRespondents" | "viewOwnTeam",
  ) => {
    setDashboardPermissions((prevPermissions) =>
      prevPermissions.map((permission, i) =>
        i === index
          ? {
              ...permission,
              [checkType === "viewRespondents"
                ? "allow_view_all_respondent_data"
                : "allow_view_own_team_data"]:
                !permission[
                  checkType === "viewRespondents"
                    ? "allow_view_all_respondent_data"
                    : "allow_view_own_team_data"
                ],
            }
          : permission,
      ),
    );
  };

  const handleSurveyManagementChange = (key: keyof SurveyManagementPermissions) => {
    setSurveyManagementPermissions((prev) => {
      const isEditSurveys = key === "edit_surveys";
      const isEditSurveysEnabled = prev.edit_surveys;

      if (isEditSurveysEnabled && !isEditSurveys) {
        return prev;
      }

      const newValue = !prev[key];

      if (isEditSurveys && newValue) {
        return Object.fromEntries(
          Object.keys(prev).map((permissionKey) => [permissionKey, newValue]),
        ) as SurveyManagementPermissions;
      }

      return { ...prev, [key]: newValue };
    });
  };

  return (
    <MainContainer>
      <BlueCardContainer>
        <CardTitle>{`${store.selectedProjectId === 0 ? "All survey" : "Survey"} management permissions`}</CardTitle>

        <ColFlexBox>
          <FlexGapBox onClick={() => handleSurveyManagementChange("view_surveys")}>
            <CustomSquareCheckbox
              id={"Survey_Permission_View_Option_Checkbox"}
              size="small"
              checked={surveyManagementPermissions?.view_surveys}
              disabled={store.isOtherPermissionReadOnly}
            />
            <label>{`${store.selectedProjectId === 0 ? "View all survey projects" : "View Surveys"}`}</label>
          </FlexGapBox>
          <FlexGapBox onClick={() => handleSurveyManagementChange("edit_surveys")}>
            <CustomSquareCheckbox
              id={"Survey_Permission_Edit_Option_Checkbox"}
              size="small"
              checked={surveyManagementPermissions?.edit_surveys}
              disabled={store.isOtherPermissionReadOnly}
            />
            <label>{`${store.selectedProjectId === 0 ? "Edit all survey projects" : "Edit Surveys"}`}</label>
          </FlexGapBox>
        </ColFlexBox>
      </BlueCardContainer>
      <WhiteCardContainer>
        <CardTitle>Dashboard Permissions</CardTitle>

        <PermissionsWrapper>
          <ViewPermissionWrapper>
            <p>{DASHBOARD_PERMISSION_VIEW_ALL_RESPONDENTS}</p>
            <p>{DASHBOARD_PERMISSION_VIEW_OWN_TEAM}</p>
          </ViewPermissionWrapper>

          <PermissionColWrapper>
            {dashboardPermissions.map((permission: DashboardPermission, index) => (
              <ColFlexPermission key={`Dashboard_Permission_${index}`}>
                <PermissionKeyLabel>
                  {DASHBOARD_PERMISSION_COLUMN_HEAD[permission.code]}
                </PermissionKeyLabel>

                <CustomSquareCheckbox
                  id={`Survey_Permission_View_Respondents_Checkbox_${index}`}
                  size="small"
                  disabled={store.isOtherPermissionReadOnly}
                  checked={permission.allow_view_all_respondent_data}
                  onChange={() => handleDashboardPermissionChange(index, "viewRespondents")}
                />
                <CustomSquareCheckbox
                  id={`Survey_Permission_View_Own_Team_Checkbox_${index}`}
                  size="small"
                  disabled={store.isOtherPermissionReadOnly}
                  checked={permission.allow_view_own_team_data}
                  onChange={() => handleDashboardPermissionChange(index, "viewOwnTeam")}
                />
              </ColFlexPermission>
            ))}
          </PermissionColWrapper>
        </PermissionsWrapper>
      </WhiteCardContainer>
    </MainContainer>
  );
});

const BlueCardContainer = styled.div`
  padding: 24px 24px 32px 24px;
  width: 270px;
  height: 208px;
  background-color: var(--colorPaletteBlueBackground3);
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  border-radius: 8px;
`;

const WhiteCardContainer = styled.div`
  padding: 24px 24px 32px 24px;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  border-radius: 8px;
`;

const CardTitle = styled.label`
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
`;

const FlexGapBox = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;

  label {
    cursor: pointer;
  }
`;

const PermissionColWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const MainContainer = styled.div`
  display: flex;
  column-gap: 40px;
`;

const ColFlexBox = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  label,
  p {
    color: var(--colorNeutralForeground1);
    font-size: 16px;
    line-height: 24px;
  }
`;

const ViewPermissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  justify-content: flex-end;

  label,
  p {
    color: var(--colorNeutralForeground1);
    font-size: 16px;
    line-height: 24px;
  }
`;

const ColFlexPermission = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  align-items: baseline;

  label,
  p {
    color: var(--colorNeutralForeground1);
    font-size: 14px;
    line-height: 20px;
  }
`;

const PermissionsWrapper = styled.div`
  display: flex;
  column-gap: 32px;
`;

const PermissionKeyLabel = styled.label`
  width: 88px;
`;

export default TablePermission;
