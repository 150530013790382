import { getTemplateExcelEmployees } from "api/useGetTemplateExcelEmployees";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { SELECTED_ACTION_REQUIRED } from "constants/import-emploee";
import MainStore from "MainStore";
import { observer } from "mobx-react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { downloadFile } from "utils/downloadFile";
import DownloadTemplateAccordion from "./components/DownloadTemplateAccordion";
import UploadSection from "./components/UploadSection";
import ListEmployeeStore from "../../Employees/Employee/ListEmployee/store";
import styled from "styled-components";
import EmployeeImportStore from "../store";
import SelectBoxComponent from "./components/SelectBox";
import CustomConfirmationDialog from "components/customConfirmationDialog";
import EmployeeImportManager from "features/Notification/import-manager/store";
import { parseFileUploaded } from "utils/parseFileUploaded";
import readXlsxFile from "read-excel-file";

const UploadStepView: FC = observer(() => {
  const formName = "EmployeeImportScreen";
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isDeleteFileDialogOpen, setIsDeleteFileDialogOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const hasExistingEmployees = useMemo(
    () => ListEmployeeStore.Employees.length > 0,
    [ListEmployeeStore.Employees],
  );

  const fakeButtonEntityIds = (process.env.REACT_APP_FAKE_BUTTON_ENTITY_IDS || "")
    .split(",")
    .filter((id) => id)
    .map((id) => Number(id));

  const saveExcelEmployee = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await getTemplateExcelEmployees(StoreLayout.currentEntityId);
      downloadFile(
        data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        `import_template.xlsx`,
      );
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDelete = (_: string) => {
    EmployeeImportManager.clearUploadError();

    EmployeeImportManager.handleFileDelete().then(() => {
      setIsDeleteFileDialogOpen(false);
    });
  };

  const handleDrop = (event: {
    preventDefault: () => void;
    dataTransfer: { files: any };
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();
    if (!StoreLayout.lockEdit) {
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0) {
        const newFiles = Array.from(droppedFiles);
        handleFileChange(newFiles);
      }
    }
  };

  const isFileSizeMoreThan50MB = (file: File) => {
    return file.size > 50 * 1024 * 1024;
  };

  const handleFileChange = async (files: string | any[] | FileList) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (isFileSizeMoreThan50MB(file)) {
        EmployeeImportManager.setHasUnexpectedError(true);
        EmployeeImportManager.handleUploadError("Maximum file size: 50MB");
        return;
      }

      try {
        await readXlsxFile(file);
      } catch (error) {
        console.log(`====> error reading Excel File`, error);
        EmployeeImportManager.setHasUnexpectedError(true);
        EmployeeImportManager.handleUploadError(
          "Excel file is invalid. Please upload a valid Excel file.",
        );

        return;
      }

      if (file) {
        EmployeeImportManager.handleImportSelectedFile(file!);
        EmployeeImportManager.handleUpload();
        fileInputRef.current.value = null;
      }
    }
  };

  useEffect(() => {
    if (EmployeeImportManager.importSelectedMergeChoice !== selectedOption) {
      setSelectedOption(EmployeeImportManager.importSelectedMergeChoice);
    }
  }, [EmployeeImportManager.importSelectedMergeChoice]);

  return (
    <div>
      <CustomConfirmationDialog
        title="Are you sure you want to delete your uploaded file?"
        bodyMessage="Deleting will remove the uploaded file and reset the process."
        isDialogOpen={isDeleteFileDialogOpen}
        onClose={() => {
          setIsDeleteFileDialogOpen(false);
        }}
        onYes={() => handleDelete("")}
        buttonsCaption={{
          yesButton: "Yes, confirm",
          cancelButton: "Cancel",
        }}
      ></CustomConfirmationDialog>
      <MarginBottom>
        <DownloadTemplateAccordion
          formName={formName}
          isAccordionExpanded={EmployeeImportStore.isAccordionExpanded}
          setToggleAccordion={EmployeeImportStore.setToggleAccordion}
          saveExcelEmployee={saveExcelEmployee}
        ></DownloadTemplateAccordion>
      </MarginBottom>
      {hasExistingEmployees && (
        <>
          <SelectContainerTitle>
            <span>How would you like to update your employee list?</span>
          </SelectContainerTitle>
          <SelectContainer id={`${formName}_selectContainer`}>
            <SelectBoxComponent
              formName={formName}
              isDisabled={EmployeeImportManager.lockMergeReplace}
              isActive={Boolean(selectedOption === SELECTED_ACTION_REQUIRED.remove_employees)}
              onClick={() => {
                EmployeeImportManager.changeImportSelectedMergeChoice(
                  SELECTED_ACTION_REQUIRED.remove_employees,
                );
                setSelectedOption(SELECTED_ACTION_REQUIRED.remove_employees);
              }}
              warningText={"No employees will be removed from your existing list in this process."}
              warningIcon={false}
              title={"Merge list"}
              description={"Employees not in your file will remain in your employee list."}
            />
            <SelectBoxComponent
              formName={formName}
              isDisabled={EmployeeImportManager.lockMergeReplace}
              isActive={Boolean(selectedOption === SELECTED_ACTION_REQUIRED.replace_entire_list)}
              onClick={() => {
                EmployeeImportManager.changeImportSelectedMergeChoice(
                  SELECTED_ACTION_REQUIRED.replace_entire_list,
                );
                setSelectedOption(SELECTED_ACTION_REQUIRED.replace_entire_list);
              }}
              warningText={
                "Deleted employees will be removed from unscheduled and upcoming surveys. Running and completed surveys will not be affected."
              }
              warningIcon={true}
              title={"Replace entire list"}
              description={
                "Employees not found in your file will be deleted from your employee list."
              }
            />
          </SelectContainer>
        </>
      )}

      {(EmployeeImportManager.importSelectedMergeChoice || !hasExistingEmployees) && (
        <UploadSection
          formName={formName}
          handleDrop={handleDrop}
          handleFileChange={handleFileChange}
          handleButtonClick={handleButtonClick}
          fileInputRef={fileInputRef}
          onFileDelete={() => setIsDeleteFileDialogOpen(true)}
          disableFileDelete={false}
          status={EmployeeImportManager.employeeImportUIState.uploadStatus}
          files={parseFileUploaded({
            file: EmployeeImportManager.importSelectedFile,
            fileUploaded: EmployeeImportManager.employeeImportUploadFile,
            progress: EmployeeImportManager.uploadExcelProgress,
          })}
        />
      )}
    </div>
  );
});

const SelectContainerTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-top: 2.5rem;
  margin-bottom: 8px;

  span {
    font-family: Roboto, sans-serif;
    color: var(--colorNeutralForeground2);
    font-weight: 400;
    font-size: 0.875em;
  }
`;

const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-bottom: 12px;
`;

const MarginBottom = styled.div`
  margin-bottom: 1rem;
`;

export default UploadStepView;
