import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";

import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import QuestionsList from "./QuestionsList";
import DimensionsList from "./DimensionsList";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import ErrorPage from "components/errors/ErrorPage";

type OthersTabProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const OthersTab: FC<OthersTabProps> = observer((props) => {
  const [hasQuestions, setHasQuestions] = useState(false);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "responses" &&
      topStore.responsesActiveLocalTab === "others"
    ) {
      store.getDashboardResponsesOtherDimensions(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        props.localFilters
      );
    }
  }, [
    topStore.selectedSurvey,
    topStore.activeTab,
    topStore.role,
    topStore.overviewBenchmark,
    topStore.filterUnitList,
    props.localFilters,
  ]);

  useEffect(() => {
    setHasQuestions(store.othersList && store.othersList.length > 0);
  }, [store.othersList]);

  return (
    <>  
      {topStore.isDashboardDataError ? (
        <ErrorPage />
      ) : (
        <Container hasQuestions={hasQuestions}>
          {store.othersList && store.othersList.length > 0 && <DimensionsList />}
          <Content hasQuestions={hasQuestions}>
            <QuestionsList localFilters={props.localFilters} />
          </Content>
        </Container>
      )}
    </>
  );
});

export default OthersTab;

const Container = styled.div<{ hasQuestions: boolean }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px solid var(--colorNeutralForegroundInverted2);
  padding: ${({ hasQuestions }) => (hasQuestions ? '48px 0px' : '24px 0px')};
`;

const Content = styled.div<{ hasQuestions: boolean }>`
  width: ${({ hasQuestions }) => (hasQuestions ? 'calc(100% - 191px)' : '100%')};
`;
