import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react";
import { FC } from "react";
import ReviewChanges from "./components/ReviewChanges";
import EmployeeImportManager from "features/Notification/import-manager/store";

const ReviewStepView: FC = observer(() => {
  return (
    <div>
      <ReviewChanges
        changelogs={StoreLayout.changeLogs}
        success={StoreLayout.successImport}
        discardClicked={() => {
          StoreLayout.changeImportCancel();
          StoreLayout.clearStoreImportSelectedFile();
        }}
        fileName={EmployeeImportManager.employeeImportUploadFile?.filename ?? "File.xlsx"}
        confirmClicked={() => StoreLayout.handleConfirmImportClick("ReviewChanges")}
      />
    </div>
  );
});

export default ReviewStepView;
