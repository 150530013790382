import { FC, useEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from "./store";
import { useLocation, useNavigate } from "react-router";
import SurveyEdit from "./Survey";
import SurveyTranslations from "./SurveyTranslations";
import CustomButton from "components/button/Button";
import settingsStore from "./SurveySettings/store";
import { Dimensions } from "./Dimensions";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CustomModal from "components/modal/Modal";
import SurveySettings from "./SurveySettings/SurveySettings";
import SelectTemplate from "features/Survey/SelectTemplate/cardGrid";
import ReplaceQuestions from "./ReplaceQuestions";
import EditingLabel from "../EditingLabel";
import EditSurveyPopup from "../SurveyList/components/EditSurveyPopup";
import SelectStore from "./../SelectTemplate/storeSurvey";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import { createUrl } from "helpers/url";
import EmployeeImportManager from "features/Notification/import-manager/store";

type SurveyProps = {};

const Surveys: FC<SurveyProps> = observer(() => {
  const navigate = useNavigate();

  const query = useQuery();
  const idSurvey = query.get("id") !== null ? query.get("id") : "0";
  const idProject = query.get("project_id") !== null ? query.get("project_id") : "0";

  useEffect(() => {
    EmployeeImportManager.setCanOpenBanner(false);

    return () => {
      EmployeeImportManager.setCanOpenBanner(true);
    };
  }, [EmployeeImportManager.canOpenBanner]);

  useEffect(() => {
    const id_survey = Number(idSurvey);
    if (!isNaN(id_survey) && id_survey !== 0) {
      store.changeIdSurvey(Number(idSurvey));
      store.loadAllData(Number(idSurvey));
      settingsStore.changeIdSurvey(Number(idSurvey));
    } else {
      if (!isNaN(+idProject)) {
        store.changeIdProject(Number(idProject));
        store.changeOpenSelectTemplate(true);
      } else {
        window.location.href = "/error-404";
        // wrong url
      }
    }

    store.getAllSupportedLanguages();
    store.getAllQuestionDimensions();
    store.loadAttributesAndSegmentsByEntity();
  }, []);

  useEffect(() => {
    if (store.idSurvey !== null && store.idSurvey !== 0) {
      navigate(
        createUrl({
          path: "/survey",
          query: {
            id: store.idSurvey.toString(),
          },
        }),
      );
    }
  }, [store.idSurvey]);

  return (
    <>
      <CustomModal
        id={"SurveySettings"}
        open={store.openSettings}
        hide={() => store.changeOpenSettings(false)}
        maxWidth="sm"
      >
        <SurveySettings id={store.idSurvey} onSaved={() => store.changeOpenSettings(false)} />
      </CustomModal>
      {StorePermission.features.all_templates.hasFeature && (
        <CustomModal
          padding="0"
          open={store.openSelectTemplate}
          id="SelectTemplate"
          hide={(x) => {
            store.changeOpenSelectTemplate(x);
            SelectStore.clearStore();
          }}
          width="100%"
          maxWidth="xl"
          overflow="clip"
        >
          <SelectTemplate
            project_id={store.idProject}
            templateType="all"
            addedQuestionJsons={store.questionJsons}
            MyOwnQuestionsClicked={() => {
              store.changeOpenSelectTemplate(false);
              SelectStore.clearStore();
            }}
            onQuestionsAdded={(questions, commTemplateId) => {
              store.templateLibraryAdded(questions, commTemplateId);
              store.changeOpenSelectTemplate(false);
            }}
          />
        </CustomModal>
      )}

      <CustomModal
        open={store.openSelectTemplateConfirm}
        hide={(x) => {
          store.changeOpenSelectTemplateConfirm(x);
          SelectStore.clearStore();
        }}
        maxWidth="sm"
      >
        <ReplaceQuestions />
      </CustomModal>

      <CustomModal
        open={store.openRunningSurveyEditPopup}
        hide={(flag) => {
          store.openClickRunSurveyEditPopup(flag);
          store.loadMainInformation(Number(idSurvey));
        }}
        children={
          <EditSurveyPopup
            hide={() => {
              store.openClickRunSurveyEditPopup(false);
              store.loadMainInformation(Number(idSurvey));
            }}
          />
        }
      />

      <MainWrapper $isError={store.isTitleError}>
        <HeaderMenuWrapper>
          <HeaderMenuWrapper1>
            <SurveyTabs
              id="Survey_Creation_Questions_Button"
              $active={store.activePage === "edit"}
              onClick={() => store.changeTab("edit")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.66536 3H0.332031V12.3333C0.332031 13.0667 0.932031 13.6667 1.66536 13.6667H10.9987V12.3333H1.66536V3ZM12.3333 0.335938H4.33333C3.6 0.335938 3 0.935938 3 1.66927V9.66927C3 10.4026 3.6 11.0026 4.33333 11.0026H12.3333C13.0667 11.0026 13.6667 10.4026 13.6667 9.66927V1.66927C13.6667 0.935938 13.0667 0.335938 12.3333 0.335938ZM11.6667 6.33333H5V5H11.6667V6.33333ZM9 9.00521H5V7.67188H9V9.00521ZM11.6667 3.66927H5V2.33594H11.6667V3.66927Z"
                  fill="#707882"
                />
              </svg>
              <SurveyText id="Survey_Creation_Questions_Button_Text">1.1 Questions</SurveyText>
            </SurveyTabs>

            <SurveyTabs
              id="Survey_Creation_Translations_Button"
              $active={store.activePage === "translation"}
              onClick={() => store.changeTab("translation")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M6.99203 0.335938C3.31203 0.335938 0.332031 3.3226 0.332031 7.0026C0.332031 10.6826 3.31203 13.6693 6.99203 13.6693C10.6787 13.6693 13.6654 10.6826 13.6654 7.0026C13.6654 3.3226 10.6787 0.335938 6.99203 0.335938ZM11.612 4.33594H9.64536C9.43203 3.5026 9.12536 2.7026 8.72536 1.9626C9.95203 2.3826 10.972 3.23594 11.612 4.33594ZM6.9987 1.69594C7.55203 2.49594 7.98536 3.3826 8.27203 4.33594H5.72536C6.01203 3.3826 6.44536 2.49594 6.9987 1.69594ZM1.8387 8.33594C1.73203 7.90927 1.66536 7.4626 1.66536 7.0026C1.66536 6.5426 1.73203 6.09594 1.8387 5.66927H4.09203C4.0387 6.10927 3.9987 6.54927 3.9987 7.0026C3.9987 7.45594 4.0387 7.89594 4.09203 8.33594H1.8387ZM2.38536 9.66927H4.35203C4.56536 10.5026 4.87203 11.3026 5.27203 12.0426C4.04536 11.6226 3.02536 10.7759 2.38536 9.66927ZM4.35203 4.33594H2.38536C3.02536 3.22927 4.04536 2.3826 5.27203 1.9626C4.87203 2.7026 4.56536 3.5026 4.35203 4.33594ZM6.9987 12.3093C6.44536 11.5093 6.01203 10.6226 5.72536 9.66927H8.27203C7.98536 10.6226 7.55203 11.5093 6.9987 12.3093ZM8.5587 8.33594H5.4387C5.3787 7.89594 5.33203 7.45594 5.33203 7.0026C5.33203 6.54927 5.3787 6.1026 5.4387 5.66927H8.5587C8.6187 6.1026 8.66536 6.54927 8.66536 7.0026C8.66536 7.45594 8.6187 7.89594 8.5587 8.33594ZM8.72536 12.0426C9.12536 11.3026 9.43203 10.5026 9.64536 9.66927H11.612C10.972 10.7693 9.95203 11.6226 8.72536 12.0426ZM9.90536 8.33594C9.9587 7.89594 9.9987 7.45594 9.9987 7.0026C9.9987 6.54927 9.9587 6.10927 9.90536 5.66927H12.1587C12.2654 6.09594 12.332 6.5426 12.332 7.0026C12.332 7.4626 12.2654 7.90927 12.1587 8.33594H9.90536Z"
                  fill="#707882"
                />
              </svg>
              <SurveyText id="Survey_Creation_Translations_Button_Text">
                1.2 Translations
              </SurveyText>
            </SurveyTabs>

            <SurveyTabs
              id="Survey_Creation_TagDimensions_Button"
              $active={store.activePage === "dimension"}
              onClick={() => store.changeTab("dimension")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="10"
                viewBox="0 0 13 10"
                fill="none"
              >
                <path
                  d="M9.75333 0.895938C9.51333 0.555938 9.11333 0.335938 8.66667 0.335938L1.33333 0.342604C0.6 0.342604 0 0.935937 0 1.66927V8.33594C0 9.06927 0.6 9.6626 1.33333 9.6626L8.66667 9.66927C9.11333 9.66927 9.51333 9.44927 9.75333 9.10927L12.6667 5.0026L9.75333 0.895938Z"
                  fill="#1E004D"
                />
              </svg>
              <SurveyText id="Survey_Creation_TagDimensions_Button_Text">
                1.3 Tag dimensions & set up overall score
              </SurveyText>
            </SurveyTabs>
          </HeaderMenuWrapper1>

          <ButtonWrapper>
            <PreviewSurveyButton
              id="Survey_Creation_Preview_Survey_Button"
              variant="outlined"
              onClick={() =>
                window.open(
                  createUrl({
                    path: "/survey-preview",
                    query: {
                      idSurvey: store.Survey.code,
                    },
                  }),
                  "_blank",
                )
              }
            >
              <RemoveRedEyeOutlinedIcon />
              Preview survey
            </PreviewSurveyButton>
          </ButtonWrapper>
        </HeaderMenuWrapper>

        {store.is_running_survey && <EditingLabel text="Editing questionnaire" />}

        {store.activePage === "edit" && <SurveyEdit />}
        {store.activePage === "translation" && <SurveyTranslations />}
        {store.activePage === "dimension" && <Dimensions />}
      </MainWrapper>
    </>
  );
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SurveyTabs = styled.div<{ $active?: boolean }>`
  background-color: ${(props) =>
    props.$active ? "var(--colorPaletteBlueBackground2)" : `var(--colorNeutralForeground4)`};
  padding: 20px 20px 20px 20px;
  cursor: pointer;
  color: var(--colorNeutralForeground1);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
`;
const SurveyText = styled.span`
  margin-left: 10px;
`;

const ButtonWrapper = styled.div`
  margin: 10px;
`;

const HeaderMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorNeutralBackground1);
`;

const HeaderMenuWrapper1 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--colorNeutralBackground1);
  padding: 0 80px;
`;

const MainWrapper = styled.div<{ $isError?: boolean }>`
  width: 100%;
  .sd-title .sd-title .sv-string-editor[aria-placeholder]:empty:before {
    color: ${(props) => props.$isError && "var(--colorPaletteRedForeground2)"};
  }
  .sd-title .sd-title .sv-string-editor {
    color: ${(props) => props.$isError && "var(--colorPaletteRedForeground2)"};
  }
`;

const PreviewSurveyButton = styled(CustomButton)`
  width: 180px;
  margin-right: 40px !important;

  svg {
    margin-right: 7px;
  }
`;

export default Surveys;
