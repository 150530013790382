import { FC, useEffect } from "react";
import { EmployeeImportScreen } from "features/EmployeeImport";
import EmployeeImportScreenV2 from "features/EmployeeImport/v2/EmployeeImportScreenV2";
import LayoutStore from "components/workspaces-sidebar/StoreLayout";

type EmployeeImportPageProps = {};

const EmployeeImportPage: FC<EmployeeImportPageProps> = () => {
  useEffect(() => {
    LayoutStore.loadFeatureFlagEmployeeImportV2();
  }, [LayoutStore.isEnableImportV2]);

  return LayoutStore.isEnableImportV2 ? <EmployeeImportScreenV2 /> : <EmployeeImportScreen />;
};

export default EmployeeImportPage;
