import { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Box } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FileUploadIcon from "@mui/icons-material/FileUpload";

import { IconchngPhoto } from "./IconchngPhoto";
import Button from "components/button/Button";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import ProgressLoader from "./ProgressLoader";
import Errors from "./Errors";
import ActionRequired from "./ActionRequired";
import ReviewChanges from "./ReviewChanges";
import PreviousImports from "./PreviousImports";
import LeftNavigationBar from "features/Employees/LeftNavigationBar";
import EmployeeNavStore from "./../Employees/store";
import { Step } from "constants/layout-types";
import { getTemplateExcelEmployees } from "api/useGetTemplateExcelEmployees";
import { downloadFile } from "utils/downloadFile";
import MainStore from "MainStore";
import CustomButton from "components/button/Button";

type EmployeeImportScreenProps = {};

const EmployeeImportScreen: FC<EmployeeImportScreenProps> = observer(() => {
  const formName = "EmployeeImportScreen";
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const fakeButtonEntityIds = (process.env.REACT_APP_FAKE_BUTTON_ENTITY_IDS || "")
    .split(",")
    .filter((id) => id)
    .map((id) => Number(id));

  const changeTab = (tab: string) => setActiveTab(tab);

  const handleFileChange = (files: string | any[] | FileList) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        StoreLayout.handleImportSelectedFile(file!);
        StoreLayout.changeFileName(file.name);
        StoreLayout.changeSuccessImport(false);
        StoreLayout.handleConfirmClick("ActionRequired");
        fileInputRef.current.value = null;
      }
    }
  };

  const saveExcelEmployee = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await getTemplateExcelEmployees(StoreLayout.currentEntityId);
      downloadFile(
        data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        `import_template.xlsx`,
      );
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: {
    preventDefault: () => void;
    dataTransfer: { files: any };
    stopPropagation: () => void;
  }) => {
    event.preventDefault();
    event.stopPropagation();
    if (!StoreLayout.lockEdit) {
      const droppedFiles = event.dataTransfer.files;
      if (droppedFiles.length > 0) {
        const newFiles = Array.from(droppedFiles);
        handleFileChange(newFiles);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!StoreLayout.showImportDone && StoreLayout.successImport) {
        StoreLayout.clearStoreImportSelectedFile();
        StoreLayout.changeSuccessImport(false);
      }
    };
  }, []);

  useEffect(() => {
    EmployeeNavStore.getEntitySettingByEntity();
  }, []);

  useEffect(() => {
    EmployeeNavStore.getCurrentEntity(StoreLayout.currentEntityId);
  }, []);

  return (
    <PageWrapper id={`${formName}_pageWrapper`}>
      <LeftNavigationBar changeTab={changeTab} activeTab={activeTab} />
      <ContentLeft id={`${formName}_contentLeft`}>
        <BackButton
          id={`${formName}_backButton`}
          onClick={() => navigate("/employees")}
        >{`< Back to employees`}</BackButton>
        <Header id={`${formName}_header`}>Import employee list</Header>
        <TemplateWrapper id={`${formName}_templateWrapper`}>
          {fakeButtonEntityIds.includes(StoreLayout.currentEntityId) && (
            <OptionParentWrapper>
              <label>Choose an option and download the Excel template</label>
              <OptionsWrapper>
                <OptionCard
                  isSelected={selectedOption === "update"}
                  onClick={() => setSelectedOption("update")}
                >
                  <OptionLabel>Update existing employee list</OptionLabel>
                  <OptionRadioWrapper>
                    {selectedOption === "update" ? (
                      <img
                        alt="checkbox-update"
                        width={24}
                        height={24}
                        src={"/assets/icons/checkbox-circle-checked.svg"}
                      />
                    ) : (
                      <img
                        alt="checkbox-update"
                        width={24}
                        height={24}
                        src={"/assets/icons/checkbox-circle-unchecked.svg"}
                      />
                    )}
                  </OptionRadioWrapper>
                </OptionCard>

                <OptionCard
                  isSelected={selectedOption === "delete"}
                  onClick={() => setSelectedOption("delete")}
                >
                  <OptionLabel>Bulk delete employees</OptionLabel>
                  <OptionRadioWrapper>
                    {selectedOption === "delete" ? (
                      <img
                        alt="checkbox-delete"
                        width={24}
                        height={24}
                        src={"/assets/icons/checkbox-circle-checked.svg"}
                      />
                    ) : (
                      <img
                        alt="checkbox-delete"
                        width={24}
                        height={24}
                        src={"/assets/icons/checkbox-circle-unchecked.svg"}
                      />
                    )}
                  </OptionRadioWrapper>
                </OptionCard>
              </OptionsWrapper>
            </OptionParentWrapper>
          )}

          <TemplateWrapperHeader id={`${formName}_templateWrapperHeader`}>
            Get started by using the latest template.
          </TemplateWrapperHeader>
          <DownloadButton
            id={`${formName}_downloadButton`}
            onClick={async () => {
              await saveExcelEmployee();
            }}
          >
            <DownloadIcon />
            Download template
          </DownloadButton>
        </TemplateWrapper>
        <ImportImageWrapper id={`${formName}_importImageWrapper`}>
          <ImportImage id={`${formName}_importImage`} src={"/assets/img-import.png"} />
        </ImportImageWrapper>
        <Text id={`${formName}_text`}>
          Upload your employee data via an excel file. Ensure that you do not remove columns A – G
          (First name – Tenure) or change their headers. The headers for columns H onwards should
          match your attributes.
        </Text>
        <br />
        <Text id={`${formName}_detailedText`}>
          Please fill in all values for <b>Full name and email address</b> (unique ID if email is
          not present). You will not have to fill in all values for other columns.
        </Text>

        <FileWrapper
          id={`${formName}_fileWrapper`}
          onDrop={handleDrop}
          $disabled={StoreLayout.lockEdit}
          onDragOver={(event) => event.preventDefault()}
        >
          <StyledContainerEmptyFile id={`${formName}_dropFileArea`}>
            <FileUploadIcon id={`${formName}_fileUploadIcon`} />
            <EmptyFileWrapperText $disabled={StoreLayout.lockEdit} id={`${formName}_dragDropText`}>
              Drag and drop xlsx file to upload
            </EmptyFileWrapperText>
            <EmptyFileWrapperText $disabled={StoreLayout.lockEdit} id={`${formName}_fileTypeText`}>
              (.xlsx)
            </EmptyFileWrapperText>
            <EmptyFileWrapperText $disabled={StoreLayout.lockEdit} id={`${formName}_maxSizeText`}>
              Max file size of 5MB
            </EmptyFileWrapperText>
          </StyledContainerEmptyFile>
        </FileWrapper>

        <Box id={`${formName}_fileUploadBox`} display={"flex"} style={{ marginBottom: 50 }}>
          <input
            id={`${formName}_fileInput`}
            type="file"
            ref={fileInputRef}
            accept=".xlsx"
            style={{ display: "none" }}
            onChange={(e) => handleFileChange(e.target.files)}
          />
          <label htmlFor={"uploadFile"}>
            <DownloadButton
              id={`${formName}_browseFilesButton`}
              disabled={StoreLayout.lockEdit}
              onClick={handleButtonClick}
            >
              <IconchngPhoto />
              Browse files
            </DownloadButton>
          </label>
        </Box>
      </ContentLeft>
      <ContentRight id={`${formName}_contentRight`}>
        {fakeButtonEntityIds.includes(StoreLayout.currentEntityId) ? (
          <>
            {StoreLayout.importCurrentStep ? (
              selectedOption === "update" ? (
                <>
                  {StoreLayout.importCurrentStep === Step.ProgressLoader && <ProgressLoader />}

                  {StoreLayout.importCurrentStep === Step.Error && (
                    <Errors file={StoreLayout.importSelectedFile} />
                  )}
                  {StoreLayout.importCurrentStep === Step.ActionRequired && (
                    <ActionRequired file={StoreLayout.importSelectedFile} />
                  )}
                  {StoreLayout.importCurrentStep === Step.ReviewChanges && (
                    <ReviewChanges
                      changelogs={StoreLayout.changeLogs}
                      success={StoreLayout.successImport}
                      discardClicked={() => {
                        StoreLayout.changeImportCancel();
                        StoreLayout.clearStoreImportSelectedFile();
                      }}
                      fileName={StoreLayout.fileName}
                      confirmClicked={() => StoreLayout.handleConfirmImportClick("ReviewChanges")}
                    />
                  )}
                </>
              ) : selectedOption === "delete" ? (
                <>
                  <RightPanelWrapper>
                    <RightPanelTitle>Employee List Update</RightPanelTitle>

                    <RightPanelFileWrapper>
                      <img
                        alt="question-mark"
                        width={24}
                        height={24}
                        src={"/assets/icons/excel.svg"}
                      />
                      <ListUpdateFilename>{StoreLayout.fileName}</ListUpdateFilename>
                    </RightPanelFileWrapper>
                  </RightPanelWrapper>

                  <CustomButton name="proceedButton" color="primary" variant="contained">
                    Proceed
                  </CustomButton>
                </>
              ) : (
                <></>
              )
            ) : (
              <PreviousImports />
            )}
          </>
        ) : (
          <>
            {StoreLayout.importCurrentStep === Step.ProgressLoader && <ProgressLoader />}

            {!StoreLayout.importCurrentStep && <PreviousImports />}

            {StoreLayout.importCurrentStep === Step.Error && (
              <Errors file={StoreLayout.importSelectedFile} />
            )}
            {StoreLayout.importCurrentStep === Step.ActionRequired && (
              <ActionRequired file={StoreLayout.importSelectedFile} />
            )}
            {StoreLayout.importCurrentStep === Step.ReviewChanges && (
              <ReviewChanges
                changelogs={StoreLayout.changeLogs}
                success={StoreLayout.successImport}
                discardClicked={() => {
                  StoreLayout.changeImportCancel();
                  StoreLayout.clearStoreImportSelectedFile();
                }}
                fileName={StoreLayout.fileName}
                confirmClicked={() => StoreLayout.handleConfirmImportClick("ReviewChanges")}
              />
            )}
          </>
        )}
      </ContentRight>
    </PageWrapper>
  );
});

export default EmployeeImportScreen;

const PageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 202px 1fr 1.5fr;
  column-gap: 55px;
  padding-bottom: 120px;
  padding-top: 55px;
`;

const ContentLeft = styled.div`
  width: 100%;
  max-width: 550px;
`;

const ContentRight = styled.div`
  width: 100%;
`;

const BackButton = styled(Button)`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2) !important;
`;

const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const ImportImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const ImportImage = styled.img`
  width: 240px;
  height: 168px;
`;

const Text = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const TemplateWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  border: 1px solid var(--colorNeutralForeground4);
  display: flex;
  flex-direction: column;
`;

//--- PSD Demo Section
const OptionParentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-bottom: 28px;
`;

const OptionsWrapper = styled.div`
  display: flex;
  column-gap: 14px;
`;

const OptionCard = styled.div<{ isSelected: boolean }>`
  display: grid;
  box-shadow: 0 2px 8px 0 var(--colorNeutralBackground3);
  grid-template-columns: repeat(4, minmax(0, 1fr));
  padding: 16px;
  border-radius: 8px;
  width: 245px;
  background-color: var(--colorNeutralBackground1);
  border: ${({ isSelected }) => (isSelected ? "2px solid var(--colorBrandForeground1)" : "none")};
  cursor: pointer;

  p {
    font-size: 16px;
    color: ${({ isSelected }) =>
      isSelected ? "black" : "var(--colorNeutralForeground9) !important"};
  }
`;

const OptionLabel = styled.p`
  grid-column: span 3 / span 3;
`;
const OptionRadioWrapper = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  justify-content: end;
`;

const RightPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-right: 40px;
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 2px solid var(--colorPaletteBlueBackground1);
`;

const RightPanelTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;
const RightPanelFileWrapper = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;
`;
const ListUpdateFilename = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`;
//---

const TemplateWrapperHeader = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 5px;
`;

const DownloadButton = styled(Button)`
  width: fit-content;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;

  svg {
    margin-right: 5px;
  }
`;

const FileWrapper = styled.div<{ $disabled: boolean }>`
  width: 100%;
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px dashed var(--colorPaletteBlueBackground1);
  margin: 40px 0 10px 0;
  border-radius: 4px;

  svg {
    color: ${({ $disabled }) =>
      !$disabled ? "var(--colorNeutralForeground5)" : "var(--colorPaletteBlueBackground1)"};
  }
`;

const StyledContainerEmptyFile = styled.div`
  text-align: center;
`;

const EmptyFileWrapperText = styled.p<{ $disabled: boolean }>`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ $disabled }) =>
    !$disabled ? "var(--colorNeutralForeground5)" : "var(--colorPaletteBlueBackground1)"};
`;

const UploadedFile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  svg {
    width: 40px;
    height: 40px;
    color: var(--colorBrandForeground1);
  }

  p {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: var(--colorBrandForeground1);
    margin-top: 8px;
  }
`;

const StyledCloseButton = styled(Button)`
  position: absolute !important;
  top: 0;
  right: 0;
  padding: 0 !important;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;
