import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import styled from "styled-components";
import { observer } from "mobx-react";
import { MenuItem, Select } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";

type SelectOption = {
  id: number;
  name: string;
  countRecipients?: number;
};

type SelectType = {
  data: SelectOption[];
  value: number;
  label?: string;
  name?: string;
  errorText: string;
  error: boolean;
  disabledOption?: number[];
  onChange: (e) => void;
};

const CustomDayInterval = observer((props: SelectType) => {
  return (
    <FormControl sx={{ m: 1, minWidth: 200 }} {...props}>
      {props.label && (
        <InputLabel htmlFor="customized-select-native" shrink id="customized-select-native-label">
          {props.label}
        </InputLabel>
        // </LabelBg>
      )}
      <Select
        value={props.value}
        name={props.name}
        onChange={props.onChange}
        variant="outlined"
        label={props.label}
        IconComponent={KeyboardArrowDown}
        labelId="customized-select-native-label"
        inputProps={{
          id: "customized-select-native",
        }}
        sx={{
          "& .MuiInputBase-input": {
            borderRadius: 4,
            position: "relative",
            fontSize: 16,
            padding: "12px 16px 12px 16px",
            fontFamily: [
              "-apple-system",
              "BlinkMacSystemFont",
              '"Segoe UI"',
              "Roboto",
              '"Helvetica Neue"',
              "Arial",
              "sans-serif",
              '"Apple Color Emoji"',
              '"Segoe UI Emoji"',
              '"Segoe UI Symbol"',
            ].join(","),
            "&:focus": {
              borderColor: "var(--colorBrandForeground1)",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: "#c3c8cd",
          },
          "&.Mui-hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--colorBrandForeground1)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--colorTooltipBackground)",
          },
        }}
      >
        {props.data.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            disabled={props.disabledOption?.includes(option.id)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText error={true}>{props.errorText}</FormHelperText>}
      {props.name === "recipients" && (
        <HelperText>
          {props.data.filter((x) => x.id === Number(props.value))[0]?.countRecipients} recipients
        </HelperText>
      )}
    </FormControl>
  );
});

const HelperText = styled.div`
  margin: 5px 5px;
  color: var(--colorNeutralForeground5);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default CustomDayInterval;
