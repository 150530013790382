import { FC, useState } from "react";
import { observer } from "mobx-react";
import {
  Button,
  IconButton,
  SelectChangeEvent,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import store from "../store";
import styled from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { decodeUnicode } from "utils/decodeUnicode";
import UnitSelectionPopup from "../../UnitSelectionPopup";
import { TDashboardUnitFilter } from "types/dashboard";
import { getUnitName } from "utils/dashboard/useGetSegmentName";
import topStore  from "features/Dashboard/store";
import SingleSelectMenuItem from "./SingleSelectMenuItem";
import MultipleSelectMenuItem from "./MultipleSelectMenuItem";

type SettingsListProps = {
  localSelectedSegments: { attribute: string; mode: string; value: string | string[] }[];
  handleModeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  handleAttributeSelect: (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => void;
  changeSegments: (changedIndex: number, field: string, value: any) => void;
  clearLastAttribute: (changedIndex: number) => void;
  removeFilter: (index: number) => void;
  clearSubsequentFilters: (fromIndex: number) => void;
};

const SettingsList: FC<SettingsListProps> = observer((props) => {
  const [openSelects, setOpenSelects] = useState<{ [key: number]: boolean }>({});
  const [showUnitPopup, setShowUnitPopup] = useState(false);
  const [activeRowIndex, setActiveRowIndex] = useState<number | null>(null);

  const handleSelectOpen = (index: number) => {
    setOpenSelects((prev) => ({ ...prev, [index]: true }));
  };

  const handleSelectClose = (index: number) => {
    setOpenSelects((prev) => ({ ...prev, [index]: false }));
    // Only clear search if all selects are closed
    const areAllSelectsClosed = Object.values({ ...openSelects, [index]: false }).every(
      (isOpen) => !isOpen
    );
    if (areAllSelectsClosed) {
      store.setData("", "segmentSearchValue");
    }
  };

  const handleRemoveFilter = (index: number) => {
    
    props.removeFilter(index);
    props.clearSubsequentFilters(index);
    
    // Force UI update
    setOpenSelects(prev => ({...prev}));
    store.setData("", "segmentSearchValue");
  };

  const getSegmentsList = (filter: any, rowIndex: number) => {
    if (filter.attribute === "Unit") {
      const previousRow = props.localSelectedSegments[rowIndex - 1];
      const previousUnitId = previousRow?.attribute === "Unit" 
        ? (typeof previousRow.value === 'string' 
            ? previousRow.value 
            : Array.isArray(previousRow.value) && previousRow.value.length === 1 
              ? previousRow.value[0] 
              : null)
        : null;

      // Get all units in the hierarchy
      const allUnits = store.attributeList
        .find((el) => String(el.label) === "Unit")
        ?.list || [];

      if (rowIndex === 0) {
        return allUnits.map((item) => ({
          value: item.id.toString(),
          label: `${item?.name} (Level ${item.level})`,
        }));
      }

      // Find the previous unit
      const previousUnit = allUnits.find(item => item.id.toString() === previousUnitId);
      if (!previousUnit) return [];

      // Filter units that are descendants of the previous unit
      return allUnits
        .filter(item => {
          if (!item?.name) return false;
          
          // Check if this unit is a descendant of the selected unit
          let currentUnit = item;
          while (currentUnit) {
            if (currentUnit.parent_id === previousUnit.id) {
              return true;
            }
            currentUnit = allUnits.find(u => u.id === currentUnit.parent_id);
          }
          return false;
        })
        .map((item) => ({
          value: item.id.toString(),
          label: `${item?.name} (Level ${item.level})`,
        }));
    }

    return (
      store.attributeList
      .find((el) => String(el.label) === String(filter.attribute))
        ?.list.filter((item) => typeof item.value !== "undefined")
      .map((item) => item.value.toString()) || []
    );
  };

  const renderValue = (row: { attribute: string; mode: string; value: string | string[] }, rowIndex: number) => {
    if (row.attribute === "Unit") {
      const previousRow = props.localSelectedSegments[rowIndex - 1];
      const previousUnitId = previousRow?.attribute === "Unit"
        ? (typeof previousRow.value === 'string'
            ? previousRow.value
            : Array.isArray(previousRow.value) && previousRow.value.length === 1
              ? previousRow.value[0]
            : null)
        : null;

      const unitItems =
        store.attributeList
          .find((el) => String(el.label) === "Unit")
          ?.list.filter((item) => {
            if (!item?.name) return false;

            if (rowIndex === 0) {
              return true;
            }

            // Check if this unit is a descendant of the selected unit
            let currentUnit = item;
            while (currentUnit) {
              if (currentUnit.parent_id === Number(previousUnitId)) {
                return true;
              }
              currentUnit = store.attributeList
                .find((el) => String(el.label) === "Unit")
                ?.list.find(u => u.id === currentUnit.parent_id);
            }
            return false;
          })
          .map((item, index) => (
            <MenuItem key={item.id.toString() + index} value={item.id.toString()}>
              {`${item?.name} (Level ${item.level})`}
            </MenuItem>
          )) || [];

      return unitItems;
    }

    const items =
      store.attributeList
        .find((el) => String(el.label) === String(row.attribute))
        ?.list.filter((item) => {
          if (!item?.value) return false;
          // Only filter if there's an active search and any select is open
          if (store.segmentSearchValue && Object.values(openSelects).some((isOpen) => isOpen)) {
            return item.value.toLowerCase().includes(store.segmentSearchValue.toLowerCase());
          }
          return true;
        })
        .map((item, index) => (
          <MenuItem key={item.value + index} value={item.value}>
            {item.value}
          </MenuItem>
        )) || [];

    return items;
  };

  const handleValueChange = (rowIndex: number, value: string | string[]) => {
    props.changeSegments(rowIndex, "value", value);
    
    const currentRow = props.localSelectedSegments[rowIndex];
    if (currentRow.attribute === "Unit" && rowIndex < props.localSelectedSegments.length - 1) {
      props.clearSubsequentFilters(rowIndex + 1);
      setOpenSelects({});
    }
  };

  const handleModeSelect = (
    e: SelectChangeEvent<string>,
    row: { attribute: string; mode: string; value: string | string[] },
    rowIndex: number
  ) => {
    if (row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) {
      setActiveRowIndex(rowIndex);
      setShowUnitPopup(true);
    }
    props.handleModeSelect(e, row, rowIndex);
  };

  const handleUnitSelect = (selectedUnits: (string | number)[]) => {
    if (activeRowIndex !== null) {
      // Normalize all unit IDs to strings
      const normalizedSelectedUnits = selectedUnits.map(unit => unit.toString());

      if (activeRowIndex > 0 && props.localSelectedSegments[activeRowIndex - 1]?.attribute === "Unit") {
        const prevRowUnitId = props.localSelectedSegments[activeRowIndex - 1].value;
        if (prevRowUnitId && !normalizedSelectedUnits.includes(prevRowUnitId.toString())) {
          const prevUnit = store.attributeList
            .find(attr => attr.label === "Unit")
            ?.list.find(unit => unit.id.toString() === prevRowUnitId.toString());
          
          if (prevUnit) {
            store.filterUnitList.forEach(unit => {
              if (unit.id.toString() === prevRowUnitId.toString()) {
                unit.selected = true;
              }
            });
          }
        }
      }

      const updateUnitSelection = (unitList: TDashboardUnitFilter[]) => {
        unitList.forEach(unit => {
          unit.selected = normalizedSelectedUnits.includes(unit.id.toString());
          if (unit.children?.length) {
            updateUnitSelection(unit.children);
          }
        });
      };

      updateUnitSelection(store.filterUnitList);

      // Update the value with the normalized unit IDs
      handleValueChange(
        activeRowIndex, 
        normalizedSelectedUnits.length === 1 ? normalizedSelectedUnits[0] : normalizedSelectedUnits
      );
    }
    setShowUnitPopup(false);
    setActiveRowIndex(null);
  };

  const getLabelById = (id: string, items: TDashboardUnitFilter[], filterIndex: number): string => {
    // Special case: if "direct members" or other special unit in hierarchical structure
    const findSpecialUnit = (units: TDashboardUnitFilter[]): { unit: TDashboardUnitFilter, path: string[] } | null => {
      for (const item of units) {
        if (item.id.toString() === id) {
          // Find immediate parent to build path
          const parentUnit = units.find(u => u.children?.some(child => child.id === item.id));
          return { 
            unit: item, 
            path: parentUnit ? [parentUnit.label, item.label] : [item.label] 
          };
        }
        if (item.children) {
          const found = findSpecialUnit(item.children);
          if (found) {
            // Prepend this item to the path
            found.path.unshift(item.label);
            return found;
          }
        }
      }
      return null;
    };
    
    const specialUnit = findSpecialUnit(items);
    if (specialUnit) {
      // For hierarchical display, show the last 2 elements of path
      const path = specialUnit.path;
      if (path.length > 2) {
        const segments = path.length - 2;
        return `${path[0]} > ${segments > 0 ? `${segments} level${segments > 1 ? 's' : ''} > ` : ''}${path[path.length - 1]}`;
      } else if (path.length === 2) {
        return `${path[0]} > ${path[1]}`;
      }
      return specialUnit.unit.label;
    }
    
    // Regular unit handling from unitList
    const unitList = store.attributeList
      .find((el) => String(el.label) === "Unit")
      ?.list || [];

    // Find the target unit
    const unit = unitList.find(item => item.id.toString() === id);
    if (!unit) return '';

    // For first filter, just show the unit name
    if (filterIndex === 0) {
      return unit.name;
    }

    // Get the parent unit
    const parent = unitList.find(u => u.id === unit.parent_id);
    if (!parent) {
      return unit.name;
    }

    // For second filter, show "parent > current"
    if (filterIndex === 1) {
      return `${parent.name} > ${unit.name}`;
    }

    // For third+ filters, show correct breadcrumb path based on levels skipped
    if (filterIndex >= 2) {
      // Find the full path of units to show correct level skipping
      const path = [];
      path.push(unit.name);
      
      let currentUnit = unit;
      let unitsBack = 0;
      
      // Traverse up the hierarchy to build the path
      while (currentUnit && currentUnit.parent_id) {
        const parentUnit = unitList.find(u => u.id === currentUnit.parent_id);
        if (!parentUnit) break;
        
        path.unshift(parentUnit.name);
        currentUnit = parentUnit;
        unitsBack++;
        
        // Stop if we've reached the first unit in the chain
        if (unitsBack >= filterIndex) break;
      }
      
      // Only show the root unit and current unit with level count in between if needed
      if (path.length > 2) {
        const levelsSkipped = path.length - 2;
        return `${path[0]} > ${levelsSkipped > 0 ? `${levelsSkipped} level${levelsSkipped > 1 ? 's' : ''} > ` : ''}${path[path.length - 1]}`;
      } else if (path.length === 2) {
        return `${path[0]} > ${path[1]}`;
      }
    }

    return unit.name;
  };

  return (
    <>
      <Container>
        {props.localSelectedSegments.map((row, rowIndex, arraySegments) => (
          <FilterContainer
            $isLast={Boolean(rowIndex + 1 === props.localSelectedSegments.length)}
            key={rowIndex}
          >
            <FilterRow>
              <FilterRowContent>
                <LabelsRow>
                  {(() => {
                    const rowValue = arraySegments[rowIndex];
                    
                    if (Array.isArray(rowValue.value) && rowValue.value.length > 0) {
                      return (
                        <LabelGroup>
                          {rowValue.value.slice(0, 10).map((el, indexLabel) => (
                            <Label key={indexLabel}>
                              <span dangerouslySetInnerHTML={{ __html:  rowValue.attribute === "Unit"
                                ? getLabelById(el.toString(), store.filterUnitList, rowIndex)
                                : typeof el === 'string' ? decodeUnicode(el) : el}} />
                            </Label>
                          ))}
                          {rowValue.value.length > 10 && (
                            <CounterLabels>+ {rowValue.value.length - 10} more</CounterLabels>
                          )}
                          <ClearButon
                            variant="text"
                            onClick={() => props.clearLastAttribute(rowIndex)}
                          >
                            Clear all
                          </ClearButon>
                        </LabelGroup>
                      );
                    }
                    
                    // Handle single value selection
                    if (rowValue.value) {
                      const isLastSelectedFilter = rowIndex === arraySegments.length - 1 || 
                        !arraySegments[rowIndex + 1].value;
                      
                      // Make sure value is always treated as string
                      const valueStr = rowValue.value.toString();
                      
                      return (
                        <LabelGroup>
                          <Label>
                            <span dangerouslySetInnerHTML={{ __html: rowValue.attribute === "Unit"
                              ? getLabelById(valueStr, store.filterUnitList, rowIndex)
                              : decodeUnicode(valueStr)}} />
                          </Label>
                          {!isLastSelectedFilter && <ArrowForwardIosIcon />}
                        </LabelGroup>
                      );
                    }

                    // Show "Select segment" only for empty row
                    return (
                      <LabelGroup>
                        <SelectLabel>Select segment</SelectLabel>
                      </LabelGroup>
                    );
                  })()}
                </LabelsRow>
                {rowIndex > 0 && (
                  <RemoveFilterButton
                    onClick={() => {
                      handleRemoveFilter(rowIndex);
                    }}
                    aria-label="remove filter"
                  >
                    <CloseIcon />
                  </RemoveFilterButton>
                )}
              </FilterRowContent>
            </FilterRow>
            <FilterRow>
              <StyledSelectAttribute
                id="unit_or_attribute"
                value={row.attribute}
                onChange={(e: SelectChangeEvent<string>) => {
                  props.handleAttributeSelect(e, row, rowIndex);
                }}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{ PaperProps: { sx: { maxHeight: 220 } } }}
                renderValue={
                  row.attribute
                    ? () => row.attribute
                    : () => <Placeholder>Select attribute</Placeholder>
                }
              >
                {store.attributeList
                  .filter((el) => {
                    // Check if we should show the Unit option
                    if (el.label === "Unit") {
                      // Get all units
                      const allUnits = store.attributeList
                        .find(attr => attr.label === "Unit")
                        ?.list || [];

                      // Check all previous rows for Unit selections
                      for (let i = 0; i < rowIndex; i++) {
                        const prevRow = props.localSelectedSegments[i];
                        if (prevRow?.attribute === "Unit" && prevRow.value) {
                          const unitId = Array.isArray(prevRow.value) 
                            ? prevRow.value[0] 
                            : prevRow.value;
                          
                          const selectedUnit = allUnits.find(unit => unit.id.toString() === unitId);
                          
                          // If any unit in the chain has no children, don't show Unit option
                          const hasChildren = selectedUnit && allUnits.some(unit => 
                            unit.parent_id === selectedUnit.id
                          );
                          
                          if (!hasChildren) return false;
                        }
                      }

                      // For first filter, always show Unit
                      return true;
                    }
                    // For other attributes, keep existing logic
                    return !props.localSelectedSegments.find((item) => item.attribute === el.label);
                  })
                  .map((attribute, index) => (
                    <MenuItem key={attribute.value + index} value={attribute.label}>
                      {attribute.label}
                    </MenuItem>
                  ))}
              </StyledSelectAttribute>
              <StyledSelectModeValue
                id="mode"
                value={row.mode}
                onChange={(e: SelectChangeEvent<string>) => handleModeSelect(e, row, rowIndex)}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                inputProps={{ "aria-label": "Without label" }}
                key={`mode-select-${rowIndex}-${row.mode}`}
                onClick={() => {
                  if (row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) {
                    setActiveRowIndex(rowIndex);
                    setShowUnitPopup(true);
                  }
                }}
                renderValue={
                  row.mode !== ""
                    ? undefined
                    : () => <Placeholder>Select mode of analysis</Placeholder>
                }
              >
                <MenuItem value={"multiple"}>Analyze multiple segments</MenuItem>
                <MenuItem value={"one"}>Select 1 segment to analyze deeper</MenuItem>
              </StyledSelectModeValue>
            </FilterRow>
            {row.mode && row.attribute && !(row.attribute === "Unit" && topStore.isDashboardUnitFilterEnabled) && (
              <FilterSecondaryRow>
                {row.mode === "one" ? (
                  <SingleSelectMenuItem
                    row={row}
                    rowIndex={rowIndex}
                    openSelects={openSelects}
                    handleSelectOpen={handleSelectOpen}
                    handleSelectClose={handleSelectClose}
                    handleValueChange={handleValueChange}
                    segmentSearchValue={store.segmentSearchValue}
                    setSegmentSearchValue={(value) => store.setData(value, "segmentSearchValue")}
                    attributeList={store.attributeList}
                    renderValue={renderValue}
                  />
                ) : (
                  <MultipleSelectMenuItem
                    row={row}
                    rowIndex={rowIndex}
                    handleValueChange={handleValueChange}
                    getSegmentsList={getSegmentsList}
                  />
                )}
              </FilterSecondaryRow>
            )}
          </FilterContainer>
        ))}
      </Container>

      {showUnitPopup && topStore.isDashboardUnitFilterEnabled && (
        <UnitSelectionPopup
          open={showUnitPopup}
          onClose={() => setShowUnitPopup(false)}
          onSelect={handleUnitSelect}
          isSingleSelect={props.localSelectedSegments[activeRowIndex!]?.mode === "one"}
          units={store.filterUnitList}
        />
      )}
    </>
  );
});

export default SettingsList;

const Container = styled.div`
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  background-color: var(--colorPaletteGrayBackground1);
  margin-top: 24px;
  border-radius: 10px;
`;

const FilterContainer = styled.div<{ $isLast?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 0px;
  border-bottom: ${(props) =>
    props.$isLast ? "none" : "1px solid var(--colorPaletteBlueBackground1)"};
`;

const FilterRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const FilterSecondaryRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 8px;
`;

const StyledSelectAttribute = styled(Select)`
  height: 39px;
  width: 170px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 8px;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const StyledSelectModeValue = styled(Select)`
  height: 39px;
  width: calc(100% - 178px) !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-family: Roboto !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  div {
    padding: 8px !important;
  }

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }

  svg {
    font-size: 16px;
  }
`;

const LabelsRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

const Label = styled.span`
  padding: 2px 8px;
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: var(--colorNeutralForeground1);
  background-color: var(--colorPaletteGreenBackground4);
  margin: 0px 4px 4px 0px;
`;

const Placeholder = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground9);
`;

const ClearButon = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
`;

const LabelGroup = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    font-size: 12px;
    margin-bottom: 4px;
    color: var(--colorNeutralForeground2);
  }
`;

const SelectLabel = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground2);
`;

const CounterLabels = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  margin-bottom: 4px;
`;

const FilterRowContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

const RemoveFilterButton = styled(IconButton)`
  && {
    padding: 4px;
    margin-left: 8px;
    color: var(--colorNeutralForeground3);
    
    &:hover {
      color: var(--colorNeutralForeground1);
      background-color: var(--colorNeutralBackground3);
    }

    svg {
      font-size: 18px;
    }
  }
`;
