import { Fragment, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import store from "../store";
import { observer } from "mobx-react";
import styled, { css } from "styled-components";
import {
  SurveyEligibility,
  PermissionInProjectSurvey,
  RoleInEntity,
  ManagementProjectSurvey,
} from "types/permission";
import { DEFAULT_ENTITY_ADMINS, DEFAULT_UNIT_HEADS } from "constants/EntityEmployeePermission";
import {
  MANAGEMENT_ALL_SURVEY,
  PERMISSION_ACTION_EDIT,
  PERMISSION_ACTION_VIEW,
  SURVEY_PERMISSION,
} from "constants/SurveyPermission";
import { PreviewBlueIcon, EditBlueIcon, SurveyProjectIcon, SurveysIcon } from "components/icons";
import { useNavigate } from "react-router-dom";
import CustomSquareCheckbox from "components/customSquareCheckbox";

const permissionLabels = {
  [PERMISSION_ACTION_VIEW]: "View other permissions",
  [PERMISSION_ACTION_EDIT]: "Edit other permissions",
};

const permissionIcons = {
  [PERMISSION_ACTION_VIEW]: <PreviewBlueIcon />,
  [PERMISSION_ACTION_EDIT]: <EditBlueIcon />,
};

const TablePermission = observer(() => {
  const [rolesInSurvey, setRolesInSurvey] = useState<RoleInEntity[]>([]);
  const [permissionsInSurvey, setPermissionsInSurvey] = useState<PermissionInProjectSurvey[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    setRolesInSurvey(store.rolesInSurvey);
  }, [store.rolesInSurvey]);

  useEffect(() => {
    setPermissionsInSurvey(store.permissionsInSurvey);
  }, [store.permissionsInSurvey]);

  const handleActionClicked = (projectId: number, roleId: number, actionType: string) => {
    if (actionType === PERMISSION_ACTION_VIEW) {
      store.setData(true, "isOtherPermissionReadOnly");
    } else {
      store.setData(false, "isOtherPermissionReadOnly");
    }

    const roleName = rolesInSurvey.find((item) => item.id === roleId).name;

    navigate(`/otherPermisions/edit?projectId=${projectId}&roleId=${roleId}&roleName=${roleName}`);
  };

  const isPermissionActionEligibility = (
    eligibility: SurveyEligibility,
  ): eligibility is { role_id: number; permission_action: string } => {
    return "permission_action" in eligibility;
  };

  const renderMovingRows = (management: ManagementProjectSurvey, managementIndex: number) => {
    const features = management.features;
    if (features.length === 0) return null;

    const maxEligibilityLength = Math.max(...features.map((f) => f.eligibility.length));

    return (
      <>
        {[...Array(maxEligibilityLength)].map((_, eligibilityIndex) => (
          <TableCellMoveable key={eligibilityIndex}>
            <EligibilityContainer $index={managementIndex}>
              {features.map((feature) => {
                const eligibility: SurveyEligibility = feature.eligibility[eligibilityIndex];
                return (
                  <div key={feature.id}>
                    {isPermissionActionEligibility(eligibility) ? (
                      <PermissionActionWrapper
                        onClick={() =>
                          handleActionClicked(
                            management.project_id,
                            eligibility.role_id,
                            eligibility.permission_action,
                          )
                        }
                      >
                        <label>{permissionLabels[eligibility.permission_action]}</label>
                        {permissionIcons[eligibility.permission_action]}
                      </PermissionActionWrapper>
                    ) : (
                      <>
                        {rolesInSurvey[eligibilityIndex].name.toLocaleLowerCase() ===
                        DEFAULT_ENTITY_ADMINS ? (
                          <label>Always enabled</label>
                        ) : (
                          <CustomSquareCheckbox
                            id={`Permission_Option_Checkbox_${eligibilityIndex}`}
                            size="small"
                            checked={eligibility?.has_permission}
                            disabled={true}
                          />
                        )}
                      </>
                    )}
                  </div>
                );
              })}
            </EligibilityContainer>
          </TableCellMoveable>
        ))}
      </>
    );
  };

  const renderRows = () => {
    if (permissionsInSurvey.length === 0) return <></>;

    const managementList: ManagementProjectSurvey[] =
      permissionsInSurvey.find((item) => item.code.toLowerCase() === SURVEY_PERMISSION)
        ?.managements ?? [];

    return (
      <TableBody id={`EntityPermissions_Roles_Grid_Table_Body`}>
        {managementList.map((management, managementIndex) => (
          <RowCustomWrapper
            id={`EntityPermissions_Roles_Grid_Row_${managementIndex}`}
            key={management.id}
          >
            <TableCellFreeze id={`EntityPermissions_Roles_Grid_Row_Table_0_${managementIndex}`}>
              {managementIndex === 0 && <TopLabel>Survey Management</TopLabel>}

              <ManagementWrapper>
                {management.code === MANAGEMENT_ALL_SURVEY ? (
                  <SurveyProjectIcon />
                ) : (
                  <SurveysIcon />
                )}
                <FlexAlignCenter>{management.name}</FlexAlignCenter>
              </ManagementWrapper>

              {management.features.map((feature, featureIndex) => (
                <Fragment key={featureIndex}>
                  <FeaturesContainer>
                    <label>{feature.name}</label>
                  </FeaturesContainer>
                </Fragment>
              ))}
            </TableCellFreeze>

            {renderMovingRows(management, managementIndex)}
          </RowCustomWrapper>
        ))}
      </TableBody>
    );
  };

  return (
    <>
      {store.surveyPermissionIsFetched && rolesInSurvey.length === 0 ? (
        <StyledContainerEmpty>
          <div>
            <h2 id="Table_noData_Title">No matches found</h2>
            <p id="Table_noData_Description">
              We couldn’t find any survey projects matching your search. Try a different keyword.
            </p>
          </div>
        </StyledContainerEmpty>
      ) : (
        <>
          <TableContainerWrapper>
            <TableWrapper aria-label="simple table">
              <TableHead id={`EntityPermissions_Roles_Grid_Table_Head`}>
                <EmptyTableCellNav>
                  <TopLabel>User roles</TopLabel>
                </EmptyTableCellNav>

                {rolesInSurvey.map((role, indexRole) => {
                  return (
                    <>
                      <TableCellRoles
                        id={`EntityPermissions_Roles_Grid_Table_${indexRole}`}
                        align="left"
                        key={role.id}
                      >
                        <FlexAlignCenter id={`EntityPermissions_Roles_Grid_Table_${indexRole}`}>
                          <HeaderTitleWrapper>
                            <RoleWrapper>
                              <RoleName>
                                {[DEFAULT_ENTITY_ADMINS, DEFAULT_UNIT_HEADS].includes(
                                  role.name.toLowerCase(),
                                ) ? (
                                  <img
                                    src={"/assets/icons/people-and-users.svg"}
                                    width={24}
                                    height={24}
                                    alt="people and users"
                                  />
                                ) : (
                                  <img
                                    src={"/assets/icons/role-user.svg"}
                                    width={24}
                                    height={24}
                                    alt="role users"
                                  />
                                )}

                                <label>{role.name}</label>
                                {[DEFAULT_ENTITY_ADMINS, DEFAULT_UNIT_HEADS].includes(
                                  role.name.toLowerCase(),
                                ) && <span>*</span>}
                              </RoleName>
                            </RoleWrapper>
                            <UserCountLabel>
                              ({role.count} {role.count > 1 ? "users" : "user"})
                            </UserCountLabel>
                          </HeaderTitleWrapper>
                        </FlexAlignCenter>
                      </TableCellRoles>
                    </>
                  );
                })}
              </TableHead>

              {renderRows()}
            </TableWrapper>
          </TableContainerWrapper>

          <InformationWrapper>
            <InformationLabel>
              * These are default user roles which cannot be deleted or renamed.
            </InformationLabel>
          </InformationWrapper>
        </>
      )}
    </>
  );
});

const afterStyle = css`
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 100%;
  background: linear-gradient(
    90deg,
    var(--colorNeutralBackground3) 0,
    var(--colorNeutralBackground1) 6px
  ) !important;
  z-index: 2;
`;

const StyledContainerEmpty = styled("div")`
  background: var(--ER-Theme-white, #fff);
  height: 350px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    color: var(--Blackhole, #3e4450);
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 120% */
  }

  p {
    color: var(--ER-Theme-sys-light-on-tertiary-fixed-variant, #959ba2);
    text-align: center;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const TableContainerWrapper = styled(TableContainer)`
  margin-bottom: 16px;
  box-shadow: none !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    margin-left: 216px !important;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 2px solid transparent;
    background-clip: padding-box;
    background: rgb(177, 177, 177);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgb(145, 146, 145);
  }

  .MuiTableCell-root {
    border-bottom: 0 !important;
  }
`;

const TableWrapper = styled(Table)`
  min-width: 300px;
  width: auto !important;
`;

const InformationWrapper = styled.div`
  padding-top: 12px;
  padding-bottom: 26px;
`;

const InformationLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const RowCustomWrapper = styled(TableRow)`
  && {
    border-bottom: 1px solid var(--colorNeutralBackground3) !important;
  }
`;

const EmptyTableCellNav = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorPaletteBlueBackground3) !important;
  z-index: 2;
  max-width: 216px;
  width: 216px;
  vertical-align: baseline;
  padding: 12px 12px 32px 12px;

  && {
    border-bottom: 1px solid var(--colorNeutralBackground3) !important;
  }

  &::after {
    ${afterStyle}
  }
`;

const TableCellRoles = styled(TableCell)`
  width: 216px;
  border-right: 1px solid var(--colorNeutralBackground3);
  background-color: var(--colorGrayForeground7) !important;
  padding: 12px 12px 32px 12px;

  && {
    border-bottom: 1px solid var(--colorNeutralBackground3) !important;
  }
`;

const EligibilityContainer = styled.div<{ $index?: number }>`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: ${(props) => (props.$index === 0 ? "74px" : "42px")};
  align-items: baseline;

  label {
    color: var(--colorNeutralForeground2);
    font-size: 14px;
    font-weight: 400;
  }
`;

const TableCellFreeze = styled(TableCell)`
  position: sticky;
  left: 0;
  background: var(--colorPaletteBlueBackground3) !important;
  z-index: 1;
  width: 216px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  &::after {
    ${afterStyle}
  }
`;

const TableCellMoveable = styled(TableCell)`
  min-width: 216px;
  max-width: 216px;
  border-right: 1px solid var(--colorNeutralBackground3);
  background-color: var(--colorNeutralBackground1);
`;

const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--colorNeutralForeground1);
`;

const HeaderTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 12px;
    line-height: 16px;
    color: var(--colorNeutralForeground2);
  }
`;

const UserCountLabel = styled.label`
  padding-left: 28px;
`;

const FeaturesContainer = styled.div`
  font-size: 14px;
  padding-left: 24px;
`;

const RoleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  column-gap: 4px;
`;

const RoleName = styled.div`
  display: flex;
  column-gap: 4px;
  align-items: center;

  label {
    color: var(--colorNeutralForeground1);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const TopLabel = styled.label`
  font-size: 16px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
  line-height: 20px;
  margin-bottom: -12px;
`;

const PermissionActionWrapper = styled.div`
  display: flex;
  column-gap: 3px;
  cursor: pointer;

  label {
    color: var(--colorBrandForeground1);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    cursor: pointer;
  }
`;

const ManagementWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: baseline;
`;

export default TablePermission;
