import { makeAutoObservable, runInAction } from "mobx";
import { getDashboardResponcesScoredCommentsAsync } from "api/dasboard/useGetDashboardResponcesScoredComments";
import { getDashboardResponcesScoredDimensionsAsync } from "api/dasboard/useGetDashboardResponcesScoredDimensions";
import { TScoredQuestionsDimension, TScoredQuestionsDimensionResponse, TScoredQuestionsEnpsResponse } from "types/dashboard";

import topStore from "../../store";
import MainStore from "MainStore";
import { USE_DASHBOARD_API } from "constants/config";
import { ResponsesType, useGetDashboardResponses } from "api/dasboard/useGetDashboardResponses";
import { processFilters } from "utils/dashboard/processFilters";
import http from "api/https";

class Store {
    scoredQuestionsList: TScoredQuestionsDimension[] = [];
    activeScoredQuestionDimension: TScoredQuestionsDimension = null;
    isOpenConditionalLogicPopup: boolean = false;
    activeScoredQuestion: TScoredQuestionsDimensionResponse = null;

    constructor() {
        makeAutoObservable(this);
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getDashboardResponsesScoredDimensions = async (
        workspace_id: number,
        entity_id: number,
        project_id: number,
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        let response;
        if (USE_DASHBOARD_API) {
            // const mockBenchmarkParam = topStore.isDashboardMockBenchmarkEnabled ? 
            //     `?benchmark=${encodeURIComponent(topStore.benchmarkSelection)}` : '';
                
            const mockBenchmarkParam = topStore.isDashboardMockBenchmarkEnabled ? 
                topStore.benchmarkSelection : '';
            
            const endpoint = topStore.isDashboardMockBenchmarkEnabled ? 
                `/dashboard/dashboard/mock/responses` : 
                "/dashboard/dashboard/responses";
            
            const requestData = {
                dashboardParameters: {
                    workspace_id,
                    entity_id,
                    project_id,
                    survey_id,
                    viewType: topStore.role,
                },
                attributes: processedFilters,    
                benchmarkType: topStore.overviewBenchmark,
                responsesTypes: [ResponsesType.SCORED],
                variation: mockBenchmarkParam
            };
            
            if (topStore.isDashboardMockBenchmarkEnabled) {
                response = await http.dashboardAPIClient.post(endpoint, requestData);
            } else {
                response = await useGetDashboardResponses(requestData);
            }
            
            response = response?.data?.scored;
            
            // Add mock benchmark data if the feature flag is enabled
            if (topStore.isDashboardMockBenchmarkEnabled && response) {
                response = this.addMockBenchmarkData(response);
            }
        } else {
            response = await getDashboardResponcesScoredDimensionsAsync({
                survey_id,
                attributes: processedFilters,
                benchmarkType: topStore.overviewBenchmark,
            });
        }

        runInAction(() => {
            if (response) {
                this.scoredQuestionsList = response;
                this.activeScoredQuestionDimension = response && response.length > 0 ? response[0] : null;
            }
        });

        MainStore.changeLoader(false);
    };

    // Helper method to add mock benchmark data to questions
    addMockBenchmarkData = (dimensions: TScoredQuestionsDimension[]): TScoredQuestionsDimension[] => {
        return dimensions.map(dimension => {
            // Handle different types of questions (regular or eNPS)
            const updatedQuestions = dimension.questionsList.map(question => {
                // Generate a random benchmark score between 50 and 90
                const benchmark = Math.floor(Math.random() * 40) + 50;
                
                if (dimension.type === "enps") {
                    // Handle eNPS questions
                    const enpsQuestion = question as TScoredQuestionsEnpsResponse;
                    // Generate a benchmark difference between -20 and 20
                    const benchmarkDiff = Math.floor(Math.random() * 40) - 20;
                    
                    return {
                        ...enpsQuestion,
                        benchmark: benchmark,
                        benchmarkDiff: benchmarkDiff
                    };
                } else {
                    // Handle regular scored questions
                    const scoredQuestion = question as TScoredQuestionsDimensionResponse;
                    // Calculate the difference between current score and benchmark
                    const benchmarkDiff = scoredQuestion.favourablePercent - benchmark;
                    
                    return {
                        ...scoredQuestion,
                        benchmark: benchmark,
                        benchmarkDiff: benchmarkDiff
                    };
                }
            });
            
            // Also add benchmark data to the dimension itself
            const dimensionBenchmark = Math.floor(Math.random() * 40) + 50;
            const dimensionBenchmarkDiff = dimension.favourablePercent - dimensionBenchmark;
            
            return {
                ...dimension,
                benchmark: dimensionBenchmark,
                benchmarkDiff: dimensionBenchmarkDiff,
                questionsList: updatedQuestions
            } as TScoredQuestionsDimension;
        });
    };

    getDashboardResponsesScoredComments = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[],
        questionId: number,
        scoredType: number,
        pagination: { offset: number; limit: number }
    ) => {
        MainStore.changeLoader(true);
        const processedFilters = processFilters(filters, topStore.filterUnitList);
        const response = await getDashboardResponcesScoredCommentsAsync({
            survey_id,
            attributes: processedFilters,
            questionId,
            scoredType,
            benchmarkType: topStore.overviewBenchmark,
            pagination,
        });

        MainStore.changeLoader(false);

        return response;
    };
}

const store = new Store();

export default store;
