import EmployeeImportManager from "features/Notification/import-manager/store";
import { makeAutoObservable, runInAction } from "mobx";
import { Employee, GetEmployeesWithAttrByEntity } from "api/go/useGetEmployeesWithAttrByEntity ";
import { deleteEmployees } from "api/go/useDeleteEmployees";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import MainStore from "MainStore";
import { GetAttributesUniqueValue } from "api/go/useGetAttributesUniqueValue";
import { getAttributesByEntityForFilter } from "api/go/useGetAttributesByEntity";
import { entityAttributesRecipients } from "types/entityAttributesRecip";
import { TRecipientsFilter } from "types/recipients";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React from "react";
import { downloadFile } from "utils/downloadFile";
import useGetExportEmployees from "api/go/useGetExportEmployees";
import { importStatusApi } from "api/employeeImport/useImportStatus";
import { ImportStatusType } from "constants/import-status.type";
import { ImportStatus } from "types/import-status";

dayjs.extend(customParseFormat);

class Store {
  data: Employee[] = [];
  openPanel: boolean = false;
  openDialog: boolean = false;
  currentId: number = 0;
  countEmployee: number = 0;
  searchedValue: string = "";
  resultSearchValue: string = "";
  Employees: Employee[] = [];
  Attributes: entityAttributesRecipients[] = [];
  AttributesUnits: entityAttributesRecipients[] = [];
  UniqueValueFirst: string[] = [];
  UniqueValueSecond: string[] = [];
  selected: readonly number[] = [];
  openModal: boolean = false;
  openDisconnectModal: boolean = false;
  paginationActivePage: number = 1;
  paginationItemsPerPage: number = 10;
  deleteEmployeeValue: string = "";
  errorEmployeeTextDelete: string = "";
  isDeleteEmployeeBtnDisabled: boolean = true;
  sortField: string = null;
  sortAttributeId: number = null;
  sortOrganizationId: number = null;
  sortType: string = null;

  filtersResult: TRecipientsFilter[] = [
    {
      select_attribute: null,
      select_segments: [],
    },
  ];
  filters: TRecipientsFilter[] = [
    {
      select_attribute: null,
      select_segments: [],
    },
    {
      select_attribute: null,
      select_segments: [],
    },
  ];

  constructor() {
    makeAutoObservable(this);
  }

  async checkImportStatus() {
    try {
      await EmployeeImportManager.checkImportStatus();
    } catch (e) {
      // TODO: decide what to do here
      console.log(e);
    }
  }

  loadUsers = async () => {
    let filter = this.filters
      .filter((x) => x.select_attribute !== null && x.select_segments.length !== 0)
      .map((x) => {
        let attr = this.Attributes.filter((y) => y.name === x.select_attribute);
        return {
          attribute_id: attr[0].id,
          values: x.select_segments,
          is_organization: attr[0].is_unit_based,
        };
      });
    try {
      if (StoreLayout.isEnableImportV2) {
        this.checkImportStatus();
      }

      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const { data } = await GetEmployeesWithAttrByEntity(
        StoreLayout.currentEntityId,
        this.resultSearchValue,
        this.paginationItemsPerPage,
        this.paginationActivePage,
        filter,
        this.sortType,
        this.sortField,
        this.sortAttributeId,
        this.sortOrganizationId,
      );
      runInAction(() => {
        this.setEmployees(data.employeeData);
        this.countEmployee = data.totalCount;
        MainStore.changeLoader(false);
      });
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Can't load survey users", "error");
      });
    }
  };

  loadAttributes = async () => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const { data } = await getAttributesByEntityForFilter(StoreLayout.currentEntityId);
      runInAction(() => {
        this.setAttributes(data);
        MainStore.changeLoader(false);
      });
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Can't load attributes", "error");
      });
    }
  };

  loadUniqueValueFirst = async (entity_attribute_id: number) => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const { json } = await GetAttributesUniqueValue(entity_attribute_id);
      GetAttributesUniqueValue(entity_attribute_id);
      runInAction(() => {
        this.setUniqueValueFirst(json);
        MainStore.changeLoader(false);
      });
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Can't load attributes", "error");
      });
    }
  };

  loadUniqueValueSecond = async (entity_attribute_id: number) => {
    try {
      runInAction(() => {
        MainStore.changeLoader(true);
      });
      const { json } = await GetAttributesUniqueValue(entity_attribute_id);
      runInAction(() => {
        this.setUniqueValueSecond(json);
        MainStore.changeLoader(false);
      });
    } catch (error) {
      runInAction(() => {
        MainStore.changeLoader(false);
        MainStore.setSnackbar("Can't load attributes", "error");
      });
    }
  };

  valueEmployeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.deleteEmployeeValue = event.target.value;
    if (this.deleteEmployeeValue !== "REMOVE") {
      this.errorEmployeeTextDelete = "Please type in the exact letters";
      this.isDeleteEmployeeBtnDisabled = true;
    }
    if (this.deleteEmployeeValue === "") {
      this.errorEmployeeTextDelete = "";
      this.isDeleteEmployeeBtnDisabled = true;
    }
    if (this.deleteEmployeeValue === "REMOVE") {
      this.errorEmployeeTextDelete = "";
      this.isDeleteEmployeeBtnDisabled = false;
    }
  };

  changeValue = (field: string, value: any) => {
    this[field] = value;
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";
    this.paginationActivePage = 1;
    this.loadUsers();
  };

  removeSearch = () => {
    this.resultSearchValue = "";
    this.searchedValue = "";
    this.paginationActivePage = 1;
    this.loadUsers();
  };

  keyPress = (e: any) => {
    if (e.keyCode === 13) {
      // on enter clicked
      this.onSearchClicked();
    }
  };

  handleClickOpenDialog(flag: boolean) {
    this.openDialog = flag;
    if (flag) {
      this.currentId = this.selected[0];
    } else {
      this.changeValue("deleteEmployeeValue", "");
      this.changeValue("errorEmployeeTextDelete", "");
      this.changeValue("isDeleteEmployeeBtnDisabled", true);
    }
  }

  onKeyPress = (e: any) => {
    if (e.keyCode === 13 && !this.isDeleteEmployeeBtnDisabled) {
      // on enter clicked
      this.deleteButtonClick();
    }
  };

  deleteButtonClick = async () => {
    MainStore.changeLoader(true);
    try {
      await deleteEmployees(this.selected);
      const selectedEmployee = this.Employees?.find((el) => el.id === this.selected[0]);
      MainStore.setSnackbar(
        this.selected.length > 1
          ? `${this.selected.length} employees successfully removed`
          : `“${selectedEmployee?.fullName} (${selectedEmployee?.preferredName})” successfully removed`,
        "success",
      );
      this.loadUsers();
    } catch (error) {
      MainStore.setSnackbar("Something went wrong!", "error");
    } finally {
      MainStore.changeLoader(false);
    }

    this.selected = [];
    store.changeValue("deleteEmployeeValue", "");
    store.changeValue("errorEmployeeTextDelete", "");
    store.changeValue("isDeleteEmployeeBtnDisabled", true);
    this.handleClickOpenDialog(false);
  };

  changeSelect = (sel: readonly number[]) => {
    this.selected = sel;
  };

  paginationSetActivePage = (page: number) => {
    this.paginationActivePage = page;
  };

  changeOpenModal(val: boolean) {
    this.openModal = val;
  }

  setEmployees(emps: Employee[]) {
    this.Employees = emps;
  }

  setAttributes(attr: entityAttributesRecipients[]) {
    this.Attributes = attr;
    let attrWithUnits: entityAttributesRecipients[] = [];
    attr.forEach((x) => {
      if (!x.is_unit_based) {
        attrWithUnits.push(x);
      } else {
        if (attrWithUnits.filter((y) => y.level === x.level).length === 0) {
          x.name = `LEVEL ${x.level} UNIT`;
          attrWithUnits.push(x);
        }
      }
    });
    this.AttributesUnits = attrWithUnits.sort((a, b) => (a.level > b.level ? 1 : -1));
  }

  addAttributes(attr: entityAttributesRecipients[]) {
    this.Attributes.push(...attr);
  }

  setUniqueValueFirst(segment: string[]) {
    this.UniqueValueFirst = segment;
  }

  setUniqueValueSecond(segment: string[]) {
    this.UniqueValueSecond = segment;
  }

  setCurrentId(number: number) {
    this.currentId = number;
  }
  setOpenPanel(boolean: boolean) {
    this.openPanel = boolean;
  }

  setOpenDisconnectModal(flag: boolean) {
    this.openDisconnectModal = flag;
  }

  changeSort = (
    field: string,
    type: string,
    idAttribute: number = null,
    sortOrganizationId: number = null,
  ) => {
    this.sortField = field;
    this.sortType = type;
    this.sortAttributeId = idAttribute;
    this.sortOrganizationId = sortOrganizationId;
    this.loadUsers();
  };

  changeFilterValues = (changeIndex: number, field: string, value: string | string[]) => {
    this.filters = this.filters.map((filter, index) => {
      if (index === changeIndex) {
        return {
          ...filter,
          [field]: value,
        };
      } else return filter;
    });
    if (field === "select_attribute") {
      this.changeFilterValues(changeIndex, "select_segments", []);
    }
  };

  removeFilter = (removeIndex: number) => {
    if (this.filters.length === 1) {
      this.filters = [
        {
          select_attribute: null,
          select_segments: [],
        },
      ];
    } else {
      this.filters = this.filters.filter((_, index) => index !== removeIndex);
    }
  };

  removeFilterAttribute = (removeIndex: number) => {
    if (this.filters.length === 1) {
      this.filters = [
        {
          select_attribute: null,
          select_segments: [],
        },
      ];
    } else {
      this.filters = this.filters.filter((_, index) => index !== removeIndex);
    }
    this.filtersResult = this.filtersResult.filter((_, index) => index !== removeIndex);
  };

  removeFilterSegment = (indexAttribute: number, indexSegment: number) => {
    this.filtersResult = this.filtersResult
      .map((el, index) => {
        if (index === indexAttribute) {
          const updatedSegments = el.select_segments.filter((_, i) => i !== indexSegment);
          if (updatedSegments.length === 0) {
            return null;
          }
          return {
            ...el,
            select_segments: updatedSegments,
          };
        }
        return el;
      })
      .filter((el) => el !== null);

    this.filters = this.filters.map((el, index) => {
      if (index === indexAttribute) {
        const updatedSegments = el.select_segments.filter((_, i) => i !== indexSegment);
        if (updatedSegments.length === 0) {
          return { select_attribute: null, select_segments: [] };
        }
        return {
          ...el,
          select_segments: updatedSegments,
        };
      }
      return el;
    });

    if (this.filters?.every((el) => el.select_segments.length === 0)) {
      this.filters = [{ select_attribute: null, select_segments: [] }];
    } else {
      this.filters = this.filters.filter(
        (el, i, arr) => el.select_segments.length > 0 || (arr.length === 1 && i === 0),
      );
    }
  };

  addFilter = () => {
    if (this.filters.length === 6) return;
    this.filters = [
      ...this.filters,
      {
        select_attribute: null,
        select_segments: [],
      },
    ];
  };

  exportExcel = async () => {
    try {
      MainStore.changeLoader(true);
      const data = await useGetExportEmployees(StoreLayout.currentEntityId);
      downloadFile(
        data,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        `Employees - ${StoreLayout.currentEntityName}.xlsx`,
      );
    } catch (err) {
      MainStore.setSnackbar("Something went wrong", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
}

const store = new Store();
export default store;
