import React from "react";
import { Box, LinearProgress, Typography, linearProgressClasses } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "components/icons/DeleteIcon";
import UploadFileIcon from "components/icons/UploadFileIcon";
import SuccessIcon from "components/icons/SuccessIcon";
import { FileUploaded, FileUploadStatus } from "types/employee-import.type";

interface FileUploadCardProps {
  status: FileUploadStatus;
  item: FileUploadItem;
  hasUnexpectedError?: boolean;
  onDelete: (fileId: string) => void;
}

type FileUploadItem = FileUploaded;

type FileUploadFinishedProps = {
  item: FileUploadItem;
  onDelete: (fileId: string) => void;
};

type FileUploadProgressProps = {
  item: FileUploadItem;
};

type FileUploadErrorProps = {
  item: FileUploadItem;
  onDelete: (fileId: string) => void;
};

const FileUploadCard: React.FC<FileUploadCardProps> = ({
  status,
  item,
  onDelete,
  hasUnexpectedError,
}: FileUploadCardProps) => {
  if (status === "success") {
    return <FileUploadFinished item={item} onDelete={onDelete} />;
  }

  if (status === "loading") {
    return <FileUploadProgress item={item} />;
  }

  if (status === "error" && hasUnexpectedError) {
    return (
      <FileErrorColumn>
        <FileUploadError item={item} onDelete={onDelete} />
        <FileErrorText>Oops! Something went wrong. Try uploading your file again. </FileErrorText>
        <ContactSupportText>
          If the error persists, drop a note to our customer support at support@engagerocket.co, and
          we’ll help you sort it out!
        </ContactSupportText>
      </FileErrorColumn>
    );
  }

  return <></>;
};

const FileUploadProgress: React.FC<FileUploadProgressProps> = ({
  item: { filename, fileSize, progress },
}: FileUploadProgressProps) => {
  return (
    <FileBox display="flex" alignItems="start" padding={2} borderRadius={2}>
      <IconWrapper>
        <StyledFileIcon color="#491c96" />
      </IconWrapper>
      <Box flexGrow={1}>
        <Typography variant="body1">{filename}</Typography>
        <Typography variant="body2" color="textSecondary">
          {fileSize}
        </Typography>
      </Box>
      <ProgressWrapper display="flex" alignItems="center">
        <BorderLinearProgress variant="determinate" value={progress} />
        <Typography variant="body2" style={{ marginLeft: 16 }}>{`${progress}%`}</Typography>
      </ProgressWrapper>
    </FileBox>
  );
};

const FileUploadFinished: React.FC<FileUploadFinishedProps> = ({
  item: { fileId, filename, fileSize },
  onDelete,
}: FileUploadFinishedProps) => {
  return (
    <FileBox display="flex" alignItems="start" padding={2} borderRadius={2}>
      <IconWrapper>
        <StyledFileIcon color="#491c96" />
      </IconWrapper>
      <Box flexGrow={1}>
        <Typography variant="body1">{filename}</Typography>
        <Typography variant="body2" color="textSecondary">
          {fileSize}
        </Typography>
      </Box>
      <ProgressWrapper
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={"1rem"}
      >
        <div
          onClick={() => onDelete(fileId)}
          title="Delete file"
          style={{ cursor: "pointer", height: "24px", width: "24px" }}
        >
          <DeleteIcon />
        </div>
        <SuccessIcon />
      </ProgressWrapper>
    </FileBox>
  );
};

const FileUploadError: React.FC<FileUploadErrorProps> = ({
  item: { fileId, filename, fileSize },
  onDelete,
}: FileUploadErrorProps) => {
  return (
    <FileBoxError display="flex" alignItems="start" padding={2} borderRadius={2}>
      <IconWrapper>
        <StyledFileIcon color="#BD0202" />
      </IconWrapper>
      <Box flexGrow={1}>
        <Typography variant="body1">{filename}</Typography>
        <Typography variant="body2" color="textSecondary">
          {fileSize}
        </Typography>
      </Box>
      <ProgressWrapper
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={"1rem"}
      >
        <div
          onClick={() => onDelete(fileId)}
          title="Delete file"
          style={{ cursor: "pointer", height: "24px", width: "24px" }}
        >
          <CloseIcon />
        </div>
      </ProgressWrapper>
    </FileBoxError>
  );
};

const IconWrapper = styled.div`
  margin-right: 16px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const StyledFileIcon = styled(UploadFileIcon)`
  margin-right: 16px;
  width: 24px;
  height: 24px;
`;

const FileBox = styled(Box)`
  width: 100%;
  border: 1px solid #cdd3ec;
  border-radius: 8px;
`;

const FileBoxError = styled(FileBox)`
  width: 100%;
  border: 1px solid #fa6b6b;
  background-color: #ffebeb;
  border-radius: 8px;
`;

const ProgressWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FileErrorColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;
`;

const FileErrorText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: #bd0202;
`;

const ContactSupportText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  color: #3e4450;
  cursor: pointer;
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  width: "130px",
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E0E0E0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#7562FC",
  },
}));

export default FileUploadCard;
