import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Drawer } from "@mui/material";
import { TScoredQuestionsEnpsResponse } from "types/dashboard";
import store from "./store";
import topStore from "../../store";
import { DEFAULT_PAGINATION_DATA } from "constants/dashboard-responses";
import PaginationList from "../PaginationList";
import {
  DETRACTORS,
  DETRACTORS_VALUE,
  PASSIVES,
  PASSIVES_VALUE,
  PROMOTERS,
  PROMOTERS_VALUE,
} from "constants/dashboard-overview";

interface CommentBoxProps {
  question: TScoredQuestionsEnpsResponse;
  localFilters: { id: number; name: string; segments: string[] }[];
  isExpanded: boolean;
  onClose: () => void;
}

const CommentBox: React.FC<CommentBoxProps> = ({
  question,
  localFilters,
  isExpanded,
  onClose
}) => {
  const [activeCommentsTab, setActiveCommentsTab] = useState<number>(PROMOTERS_VALUE);
  const [commentsList, setCommentsList] = useState<string[]>([]);
  const [commentsPagination, setCommentsPagination] = useState(DEFAULT_PAGINATION_DATA);

  const changeCommentsPagination = (field: string, value: number) =>
    setCommentsPagination((prev) => ({ ...prev, [field]: value }));

  const changeCommentsTab = (tab: number) => {
    setActiveCommentsTab(tab);
    setCommentsPagination(DEFAULT_PAGINATION_DATA);
  };

  useEffect(() => {
    const getData = async () => {
      if (isExpanded && topStore.selectedSurvey && typeof question.id === "number") {
        const response = await store.getDashboardResponsesScoredComments(
          topStore.selectedSurvey.value,
          localFilters,
          question.id,
          activeCommentsTab,
          { offset: commentsPagination.active_page, limit: commentsPagination.count_per_page }
        );

        setCommentsList(response?.data || []);
        setCommentsPagination({
          active_page: response?.active_page || 0,
          count_per_page: response?.count_per_page || 0,
          total_count: response?.total_count || 0,
          total_pages: response?.total_pages || 0,
        });
      }
    };

    getData();
  }, [
    isExpanded,
    activeCommentsTab,
    topStore.selectedSurvey,
    commentsPagination.active_page,
    topStore.overviewBenchmark,
    localFilters,
    question.id
  ]);

  if (!isExpanded) return null;

  return (
    <Drawer
      anchor="right"
      open={isExpanded}
      onClose={onClose}
    >
      <Container>
        <Header>
          <Title>{question.title} - Comments</Title>
          <CloseButton onClick={onClose}>Close</CloseButton>
        </Header>

        <TabContainer>
          <Tab
            $active={activeCommentsTab === PROMOTERS_VALUE}
            onClick={() => changeCommentsTab(PROMOTERS_VALUE)}
          >
            <TabImg src="/assets/dashboard_overview_funny_smile.png" />
            <TabText>
              {PROMOTERS} ({question.positiveCommentsCount || 0})
            </TabText>
          </Tab>
          <Tab
            $active={activeCommentsTab === PASSIVES_VALUE}
            onClick={() => changeCommentsTab(PASSIVES_VALUE)}
          >
            <TabImg src="/assets/dashboard_overview_neutral_smile.png" />
            <TabText>
              {PASSIVES} ({question.passiveCommentsCount || 0})
            </TabText>
          </Tab>
          <Tab
            $active={activeCommentsTab === DETRACTORS_VALUE}
            onClick={() => changeCommentsTab(DETRACTORS_VALUE)}
          >
            <TabImg src="/assets/dashboard_overview_angry_smile.png" />
            <TabText>
              {DETRACTORS} ({question.negativeCommentsCount || 0})
            </TabText>
          </Tab>
        </TabContainer>

        <CommentsContent>
          <PaginationList
            list={commentsList}
            pagination={commentsPagination}
            changePagination={changeCommentsPagination}
          />
        </CommentsContent>
      </Container>
    </Drawer>
  );
};

export default CommentBox;

const Container = styled.div`
  width: 550px;
  height: 100%;
  background-color: var(--colorNeutralBackground1);
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
`;

const CloseButton = styled(Button)`
  text-transform: none !important;
`;

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid var(--colorNeutralBackground3);
  margin-bottom: 16px;
`;

type TabProps = {
  $active: boolean;
};

const Tab = styled.div<TabProps>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 16px;
  cursor: pointer;
  border-bottom: 3px solid ${(props) => props.$active ? 'var(--colorBrandForeground1)' : 'transparent'};
  
  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const TabImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const TabText = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
`;

const CommentsContent = styled.div`
  flex: 1;
  overflow-y: auto;
`; 