import { memo, ReactElement, useEffect, useRef } from "react";
import { settings, SurveyCreatorComponent } from "survey-creator-react";
import { ICreatorOptions } from "survey-creator-core";
import "survey-creator-core/survey-creator-core.min.css";
import "survey-core/defaultV2.min.css";
import { Serializer } from "survey-core";
import "survey-core/survey.i18n";
import { CustomSurveyEditorStyles } from "./surveyEditorOverrides/CustomSurveyJsStyles";
import translationsOverrides from "./surveyEditorOverrides/translationsOverrides";
import "./surveyEditorOverrides/propertyOverrides";
import "./surveyEditorOverrides/eNPS/registerENPSQuestion";
import "./surveyEditorOverrides/employeeSegmentLogicField/registerEmployeeSegmentLogicField";
import "./surveyEditorOverrides/translationsFormLibrary/registerTranslations";
import { observer } from "mobx-react";
import { Question } from "types/templates";
import store from "./store";
import storeSurvey from "features/Survey/SurveyCreation/store";
import { SURVEY_STATUS } from "constants/survey-status";

translationsOverrides("en");

settings.designer.defaultAddQuestionType = "rating";

export type SurveyEditorMode = "open" | "preview";

export type OnSurveySaved = (
  surveyJson: string,
  surveyTitle: string,
  surveyLocales: string[],
  surveyQuestions: Question[],
  notifySurveyJsSuccessCallback: () => void,
) => void;

type newQuestions = {
  id: number;
  name: string;
  title: string;
  dimension_id: number;
  type_id: number;
  type: string;
  dimension: string;
  questionJson: string;
  question_code?: string;
  question_key?: string;
};

export type SurveyEditorProps = {
  onSurveySaved: OnSurveySaved;
  onModified?: (flag: boolean) => void;
  surveyJson: string;
  editorMode?: SurveyEditorMode;
  newQuestions?: newQuestions[];
  typeAddNewQuestionsAddOrReplace?: "add" | "replace";
};

const SurveyEditor = observer(
  ({
     onSurveySaved,
     onModified,
     surveyJson,
     editorMode = "open",
     newQuestions,
     typeAddNewQuestionsAddOrReplace,
   }: SurveyEditorProps): ReactElement => {
    const wrapperRef = useRef(null);

    useEffect(() => {
      if (editorMode !== "open") {
        return;
      }
      if (
        newQuestions !== undefined &&
        newQuestions?.length !== 0 &&
        typeAddNewQuestionsAddOrReplace
      ) {
        // adding question from library
        let page = store.creator.survey.pages[0];
        if (!page) {
          store.creator.survey.addNewPage();
        }
        page = store.creator.survey.pages[0];

        if (!page) return;
        if (typeAddNewQuestionsAddOrReplace === "add") {
          newQuestions.forEach((item) => {
            let questionType = item.type;
            if (!questionType) {
              questionType = JSON.parse(item.questionJson).type;
            }
            const question1 = Serializer.createClass(questionType);
            let json = JSON.parse(item.questionJson);
            if (item?.name.split("^").length === 2) {
              json.name = `${item?.id}^${item?.name.split("^")[1]}`;
            } else {
              json.name = `${item?.id}^${item?.name}`;
            }
            json.dimension_id = item.dimension_id;
            json.question_code = item.question_code;
            json.question_key = item.question_key;
            question1.fromJSON(json);
            page.addQuestion(question1);
          });
        } else {
          page.delete();
          page = store.creator.survey.addNewPage();
          newQuestions.forEach((item) => {
            let questionType = item.type;
            if (!questionType) {
              questionType = JSON.parse(item.questionJson).type;
            }
            const question1 = Serializer.createClass(questionType);
            let json = JSON.parse(item.questionJson);
            json.name = `${item?.id}^${item?.name}`;
            json.dimension_id = item.dimension_id;
            json.question_code = item.question_code;
            json.question_key = item.question_key;
            question1.fromJSON(json);
            page.addQuestion(question1);
          });
        }
      }
    }, [newQuestions]);

    useEffect(() => {
      store.setCreator(editorMode, onModified);
    }, []);

    useEffect(() => {
      if (editorMode !== "open") {
        return;
      }
      store.creator.saveSurveyFunc = (
        saveNo: number,
        callback: (saveNo: number, isSuccess: boolean) => void,
      ): void => {
        let page = store.creator.survey.pages[0];
        if (!page) {
          store.creator.survey.addNewPage();
        }
        let questions: Question[] = [];
        store.creator.survey.pages.forEach((page) => {
          page.questions.forEach((x) => {
            questions.push({
              name: x.name,
              questionJson: JSON.stringify(x.toJSON()),
              title: x.title,
              dimensionId: x.dimension_id,
              question_code: x.question_code,
              question_key: x.question_key,
              type: x.getType(),
              isQuestionConfition: Boolean(x.visibleIf) || Boolean(x.visibleIfAnswer),
            });
          });
        });
        onSurveySaved(
          store.creator.text,
          store.creator.survey.title,
          store.creator.survey.getUsedLocales(),
          questions,
          () => {
            callback(saveNo, true);
          }
        );
      };
    }, [editorMode, onSurveySaved]);

    useEffect(() => {
      if (surveyJson !== "") {
        store.creator.text = surveyJson;
      } else {
        store.creator.text =
          "{\n \"logoPosition\": \"right\",\n \"completedHtml\": \"<h3>Thank you for completing the survey! At {{entity_name}}, we appreciate your feedback!</h3>\",\n \"pages\": [\n  {\n   \"name\": \"page1\"\n  }\n ]\n}";
      }
    }, [surveyJson]);

    useEffect(() => {
      if (storeSurvey.Survey?.status_idCode === SURVEY_STATUS.complete) {
        const wrapper = wrapperRef.current;
        if (wrapper) {
          const fileInput = wrapper.querySelector("input[type='file']") as HTMLInputElement;
          const containerEditRemove = wrapper.querySelector(
            ".svc-logo-image-controls"
          ) as HTMLDivElement;
          if (containerEditRemove) {
            containerEditRemove.style.display = "none";
          }
          if (fileInput) {
            fileInput.disabled = true;
          }
        }
      }
    }, [storeSurvey.Survey]);

    return (
      <div ref={wrapperRef}>
        <CustomSurveyEditorStyles
          $isReadOnly={storeSurvey.Survey?.status_idCode === SURVEY_STATUS.complete}
          $isPreview={editorMode === "preview"}
        >
          {store.creator && <SurveyCreatorComponent creator={store.creator} />}
        </CustomSurveyEditorStyles>
      </div>
    );
  },
);

export default memo(SurveyEditor);

export function getSurveyCreatorOptions(editorMode: SurveyEditorMode): Partial<ICreatorOptions> {
  const modeConfig = {
    open: {
      isAutoSave: true,
      showDesignerTab: true,
      showJSONEditorTab: false,
      showPreviewTab: false,
      showTranslationTab: false,
    },
    preview: {
      isAutoSave: false,
      showDesignerTab: false,
      showJSONEditorTab: false,
      showPreviewTab: true,
      showTranslationTab: false,
    },
  };

  return modeConfig[editorMode];
}
