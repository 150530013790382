import { observer } from "mobx-react";

import store from "../store";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";

type EmptyLabelProps = {
  setHeight?: boolean;
};

const EmptyLabel = observer(({ setHeight = true }: EmptyLabelProps) => {
  return (
    <Container setHeight={setHeight}>
      <EmptyWrapper>
        <ErrorIcon />
        <EmptyText>
          Results will be shown when there are {store.selectedSurvey?.threshold} or more responses
        </EmptyText>
      </EmptyWrapper>
    </Container>
  );
});

export default EmptyLabel;

const Container = styled.div<{ setHeight: boolean }>`
  width: 100%;
  ${(props) => props.setHeight && "height: 285px;"}
  background-color: var(--colorNeutralBackground1);
  border-radius: 8px;
`;

const EmptyWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--colorPalleteLightBlue);
  margin-top: 24px;
  padding: 10px;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;
