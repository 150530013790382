import { Button } from "@mui/material";
import ErrorDialog from "components/ErrorDialog/ErrorDialog";
import ReviewChanges from "features/EmployeeImport/v2/components/ReviewChanges";
import DownloadTemplateAccordion from "features/EmployeeImport/v2/components/DownloadTemplateAccordion";
import ErrorAlert from "features/EmployeeImport/v2/components/ErrorAlert";
import FileUploadCard from "features/EmployeeImport/v2/components/FileUploadCard";
import UploadSection from "features/EmployeeImport/v2/components/UploadSection";
import { DeleteIcon } from "features/Employees/AttributeList/components/DeleteIcon";
import { useState } from "react";
import styled from "styled-components";
import ProgressCard from "features/EmployeeImport/v2/components/ProgressCard";
import CustomConfirmationDialogWithChildren from "components/customConfirmationDialog/customConfirmationDialogWithChildren";
import DownloadButton from "features/EmployeeImport/v2/components/DownloadButton";
import CloseIconRed from "components/icons/CloseIconRed";
import FileIcon from "components/icons/FileIcon";
import ProgressIcon from "components/icons/ProgressIcon";
import SuccessIcon from "components/icons/SuccessIcon";
import UploadFileIcon from "components/icons/UploadFileIcon";
import DismissButton from "features/EmployeeImport/v2/components/DismissButton";
import BackButton from "features/EmployeeImport/v2/components/BackButton";
import NebulaAccordion from "components/Accordion/NebulaAccordion";
import { WarningIconNew } from "components/icons/WarningIcon";
import CustomSimpleFormModalDialog from "components/customSimpleFormModalDialog";
import CustomSearchField from "components/customSearchField";
import DropdownPopOver from "components/dropdownPopOver";
import SearchResultBanner from "components/searchResultBanner";
import CustomTimeField from "components/customTimeField";
import { Dayjs } from "dayjs";
import CustomDateField from "components/customDateField";
import CustomDayInterval from "components/customDayInterval";

type SelectedItem = {
  id: number;
  name: string;
};

type SelectOption = {
  id: number;
  name: string;
  countRecipients?: number;
};

const Docs = () => {
  const [currentPage, setCurrentPage] = useState("Buttons");

  return (
    <>
      <AppMainWrapper>
        <Sidebar>
          <SidebarMenuButton
            type="button"
            title="Buttons"
            onClick={() => setCurrentPage("Buttons")}
          >
            Buttons
          </SidebarMenuButton>
          <SidebarMenuButton type="button" title="Inputs" onClick={() => setCurrentPage("Inputs")}>
            Inputs
          </SidebarMenuButton>
          <SidebarMenuButton type="button" title="Icons" onClick={() => setCurrentPage("Icons")}>
            Icons
          </SidebarMenuButton>
          <SidebarMenuButton
            type="button"
            title="Components"
            onClick={() => setCurrentPage("Components")}
          >
            Components
          </SidebarMenuButton>
          <SidebarMenuButton type="button" title="Alerts" onClick={() => setCurrentPage("Alerts")}>
            Alerts
          </SidebarMenuButton>
          <SidebarMenuButton
            type="button"
            title="Banners"
            onClick={() => setCurrentPage("Banners")}
          >
            Banners
          </SidebarMenuButton>
          <SidebarMenuButton
            type="button"
            title="Dialogs"
            onClick={() => setCurrentPage("Dialogs")}
          >
            Dialogs
          </SidebarMenuButton>
          <SidebarMenuButton
            type="button"
            title="Popovers"
            onClick={() => setCurrentPage("Popovers")}
          >
            Popovers
          </SidebarMenuButton>
        </Sidebar>
        <Column>
          {currentPage === "Buttons" && <Buttons />}
          {currentPage === "Inputs" && <Inputs />}
          {currentPage === "Icons" && <Icons />}
          {currentPage === "Components" && <Components />}
          {currentPage === "Alerts" && <Alerts />}
          {currentPage === "Banners" && <Banners />}
          {currentPage === "Dialogs" && <Dialogs />}
          {currentPage === "Popovers" && <Popovers />}
        </Column>
      </AppMainWrapper>
    </>
  );
};

const Icons = () => {
  return (
    <Column>
      <h2>Icons</h2>
      <Grids>
        <Section name="Delete Icon">
          <DeleteIcon />
        </Section>
        <Section name="UploadFileIcon">
          <UploadFileIcon width={20} height={20} />
        </Section>
        <Section name="SuccessIcon">
          <SuccessIcon />
        </Section>
        <Section name="CloseIconRed">
          <CloseIconRed height="20px" width="20px" />
        </Section>
        <Section name="ProgressIcon">
          <ProgressIcon />
        </Section>
        <Section name="FileIcon">
          <FileIcon />
        </Section>
        <Section name="WarningIcon">
          <WarningIconNew></WarningIconNew>
        </Section>
      </Grids>
    </Column>
  );
};

const Buttons = () => {
  return (
    <Column>
      <h2>Buttons</h2>

      <Section name="DownloadButton">
        <DownloadButton onClick={() => {}} id="" disabled={false} />
      </Section>

      <Section name="DismissButton">
        <DismissButton onClick={() => {}}></DismissButton>
      </Section>

      <Section name="BackButton">
        <BackButton
          onClick={() => alert("Back button clicked")}
          text="Back to employee list"
        ></BackButton>
      </Section>
    </Column>
  );
};

const Inputs = () => {
  const [searchText, setSearchText] = useState("");
  const [timeValue, setTimeValue] = useState<Dayjs | null>(null);
  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [dayIntervalValue, setDayIntervalValue] = useState<number>(0);

  const dayIntervalOptions: SelectOption[] = [
    { id: 1, name: "1 day" },
    { id: 2, name: "2 days" },
    { id: 3, name: "3 days" },
    { id: 4, name: "4 days" },
    { id: 5, name: "5 days" },
  ];

  return (
    <Column>
      <h2>Inputs</h2>

      <Section name="SearchField">
        <CustomSearchField
          searchedValue={searchText}
          placeholder="Enter search text"
          onClearClick={() => setSearchText("")}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              alert("Search Executed!");
            }
          }}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Section>

      <Section name="CustomDateField">
        <CustomDateField value={dateValue} changeValue={(value: Dayjs) => setDateValue(value)} />
      </Section>

      <Section name="CustomTimeField">
        <CustomTimeField
          value={timeValue}
          changeValue={(value: Dayjs) => setTimeValue(value)}
          minTime={null}
          pairWithDate={false}
        />
      </Section>

      <Section name="CustomDayInterval">
        <CustomDayInterval
          data={dayIntervalOptions}
          value={dayIntervalValue}
          label="Day Interval"
          onChange={(e) => setDayIntervalValue(parseInt(e.target.value))}
          errorText="error message"
          error={false}
        />
      </Section>
    </Column>
  );
};

const Components = () => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  return (
    <Column>
      <h2>Components</h2>
      <h3>Upload Section</h3>
      <Section name="FileUploadCard">
        <FileUploadCard
          status="success"
          item={{
            fileId: "1",
            filename: "test.csv",
            fileSize: "10000",
            progress: 100,
            status: "success",
          }}
          onDelete={() => {}}
        />
      </Section>
      <Section name="ReviewChanges">
        <ReviewChanges
          fileName="test.csv"
          confirmClicked={() => {}}
          discardClicked={() => {}}
          success={true}
          changelogs={[]}
        />
      </Section>

      <Section name="UploadSection Empty">
        <UploadSection
          formName="UploadSection"
          handleDrop={() => {}}
          handleFileChange={() => {}}
          handleButtonClick={() => {}}
          fileInputRef={null}
          files={[]}
          onFileDelete={() => {}}
          disableFileDelete={false}
          status="empty"
        />
      </Section>

      <Section name="UploadSection Success">
        <UploadSection
          formName="UploadSection"
          handleDrop={() => {}}
          handleFileChange={() => {}}
          handleButtonClick={() => {}}
          fileInputRef={null}
          files={[
            {
              fileId: "1",
              filename: "test.csv",
              fileSize: "10000",
              progress: 100,
              status: "success",
            },
          ]}
          onFileDelete={() => {}}
          disableFileDelete={false}
          status="success"
        />
      </Section>

      <Section name="UploadSection Loading">
        <UploadSection
          formName="UploadSection"
          handleDrop={() => {}}
          handleFileChange={() => {}}
          handleButtonClick={() => {}}
          fileInputRef={null}
          files={[
            {
              fileId: "1",
              filename: "test.csv",
              fileSize: "10000",
              progress: 50,
              status: "loading",
            },
          ]}
          onFileDelete={() => {}}
          disableFileDelete={false}
          status="loading"
        />
      </Section>

      <Section name="DownloadTemplateAccordion">
        <DownloadTemplateAccordion
          formName="DownloadTemplateAccordion"
          isAccordionExpanded={isAccordionExpanded}
          setToggleAccordion={() => setIsAccordionExpanded(!isAccordionExpanded)}
          saveExcelEmployee={() => {}}
        />
      </Section>

      <Section name="ProgressCard">
        <ProgressCard
          title="You can continue using the platform while the file is being processed in the background."
          progress={44}
        />
      </Section>

      <Section name="NebulaAccordion">
        <NebulaAccordion
          items={[
            {
              title: "Column A – Full name can’t be blank",
              list: [
                "Error 1",
                "Error 2",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
                "Error 3",
              ],
            },
            {
              title: "Error 2",
              list: ["Error 4", "Error 5", "Error 6"],
            },
            {
              title: "Error 1",
              list: ["Error 1", "Error 2", "Error 3"],
            },
            {
              title: "Error 2",
              list: ["Error 4", "Error 5", "Error 6"],
            },
          ]}
        />
      </Section>
    </Column>
  );
};

const Alerts = () => {
  return (
    <Column>
      <h2>Alerts</h2>
      <Section name="ErrorAlert">
        <ErrorAlert
          errorMessage="Oops! Your Excel file contains errors. Review the list of errors on the right and try uploading your file again."
          onDismiss={() => {}}
        />
      </Section>
    </Column>
  );
};

const Banners = () => {
  const [searctText, setSearchText] = useState("Searched text");
  return (
    <Column>
      <h2>Banners</h2>
      <Section name="SearchResultBanner">
        {searctText && (
          <SearchResultBanner
            resultsCount={10}
            searchText={searctText}
            onClearContainer={() => setSearchText("")}
            clearLabel="Clear"
          />
        )}
      </Section>
    </Column>
  );
};

const Dialogs = () => {
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [isCustomConfirmationDialogOpen, setIsCustomConfirmationDialogOpen] = useState(false);
  const [isCustomSimpleFormModalDialogOpenModeA, setIsCustomSimpleFormModalDialogOpenModeA] =
    useState(false);
  const [isCustomSimpleFormModalDialogOpenModeB, setIsCustomSimpleFormModalDialogOpenModeB] =
    useState(false);

  return (
    <Column>
      <h2>Dialogs</h2>
      <Section name="ErrorDialog">
        <Button onClick={() => setIsErrorDialogOpen(!isErrorDialogOpen)}>Open Error Dialog</Button>
        <ErrorDialog
          title="File import unsuccessful"
          fileName="test.xlsx"
          open={isErrorDialogOpen}
          errorMessage="Oops! Your Excel file contains errors. Review the list of errors on the right and try uploading your file again."
          onClose={() => {
            setIsErrorDialogOpen(false);
          }}
          onYes={() => {}}
          buttonsCaption={{
            yesButton: "Review file",
            cancelButton: "Cancel",
          }}
        />
      </Section>

      <Section name="CustomConfirmationDialogWithChildren">
        <Button onClick={() => setIsCustomConfirmationDialogOpen(!isCustomConfirmationDialogOpen)}>
          Open Custom Confirmation Dialog
        </Button>
        <CustomConfirmationDialogWithChildren
          title="Are you sure you want to bulk import users?"
          isDialogOpen={isCustomConfirmationDialogOpen}
          onClose={() => {
            setIsCustomConfirmationDialogOpen(false);
          }}
          onYes={() => {}}
        >
          <p>The following actions will be performed:</p>
          <ul>
            <li>197 employees to be updated</li>
            <li>1 employee to be created</li>
            <li>1 employee to be deleted</li>
          </ul>
        </CustomConfirmationDialogWithChildren>
      </Section>

      <Section name="CustomSimpleFormModalDialog">
        <Button
          onClick={() =>
            setIsCustomSimpleFormModalDialogOpenModeA(!isCustomSimpleFormModalDialogOpenModeA)
          }
        >
          Open Custom Simple Form Modal Dialog (Simple Form)
        </Button>
        <CustomSimpleFormModalDialog
          title="Create user role"
          fieldLabel="This is a field label for description"
          isDialogOpen={isCustomSimpleFormModalDialogOpenModeA}
          buttonsCaption={{
            yesButton: "Save",
            cancelButton: "Cancel",
          }}
          onClose={() => {
            setIsCustomSimpleFormModalDialogOpenModeA(false);
          }}
          onYes={() => {}}
        />

        <Button
          onClick={() =>
            setIsCustomSimpleFormModalDialogOpenModeB(!isCustomSimpleFormModalDialogOpenModeB)
          }
        >
          Open Custom Simple Form Modal Dialog (Typing Confirmation)
        </Button>
        <CustomSimpleFormModalDialog
          title="Confirmation"
          fieldLabel="This is a field label for description"
          isDialogOpen={isCustomSimpleFormModalDialogOpenModeB}
          oldValue="Selected value"
          buttonsCaption={{
            yesButton: "Proceed",
            cancelButton: "Abort",
          }}
          isShowConfirmField={true}
          confirmFieldLabel={"Type the letter 'OK' to confirm"}
          confirmFieldPlaceholder={"OK"}
          onClose={() => {
            setIsCustomSimpleFormModalDialogOpenModeB(false);
          }}
          onYes={() => {}}
        />
      </Section>
    </Column>
  );
};

const Popovers = () => {
  const [currentEl, setCurrentEl] = useState<HTMLElement | null>(null);
  const [openPopupMultiSelect, setOpenPopupMultiSelect] = useState(false);
  const [openPopupSingleSelect, setOpenPopupSingleSelect] = useState(false);

  return (
    <Column>
      <h2>Popovers</h2>
      <Section name="Popover">
        <Button
          onClick={(event) => {
            setOpenPopupMultiSelect(!openPopupMultiSelect);
            setCurrentEl(event.currentTarget);
          }}
        >
          Open Popover (Multi-select)
        </Button>
        <DropdownPopOver
          anchorElement={currentEl}
          isOpen={openPopupMultiSelect}
          items={[
            { id: 1, name: "Role 1" },
            { id: 2, name: "Role 2" },
          ]}
          onSelect={(items: SelectedItem[]) => alert(items.map((item) => item.name).join(", "))}
          onClose={() => {
            setOpenPopupMultiSelect(false);
            setCurrentEl(null);
          }}
          anchorPosition={{ vertical: "bottom", horizontal: "left" }}
          customStyle={{ width: `${currentEl?.clientWidth}px`, maxHeight: "288px" }}
        />

        <Button
          onClick={(event) => {
            setOpenPopupSingleSelect(!openPopupSingleSelect);
            setCurrentEl(event.currentTarget);
          }}
        >
          Open Popover (Single-select)
        </Button>
        <DropdownPopOver
          anchorElement={currentEl}
          isOpen={openPopupSingleSelect}
          items={[
            { id: 1, name: "Role 1" },
            { id: 2, name: "Role 2" },
          ]}
          onSelect={(item: SelectedItem) => alert(item.name)}
          onClose={() => {
            setOpenPopupSingleSelect(false);
            setCurrentEl(null);
          }}
          isMultiChoice={false}
          anchorPosition={{ vertical: "bottom", horizontal: "left" }}
          customStyle={{ width: `${currentEl?.clientWidth}px`, maxHeight: "288px" }}
        />
      </Section>
    </Column>
  );
};

const Section = ({ name, children }: { name: string; children: React.ReactNode }) => {
  return (
    <SectionContainer>
      <SectionTitle>{name}</SectionTitle>
      {children}
    </SectionContainer>
  );
};

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  return <SidebarContainer>{children}</SidebarContainer>;
};

const SidebarContainer = styled.aside`
  padding-top: 10rem;
  padding-left: 4rem;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100px;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 10px;
`;

const SidebarTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
`;

const SectionTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;
  gap: 10px;
`;

const Grids = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  gap: 10px;
  padding: 1.2rem;
  border: 1px solid #a0a0a0;
  border-radius: 10px;
  background-color: #fff;
  max-width: 700px;
  width: 100%;
`;

const AppMainWrapper = styled.div`
  height: 100vh;
  position: relative;
  overflow-y: scroll;
  top: 0;
  padding-bottom: 200px;

  /* display: grid; */
  /* grid-template-columns: 100px 1fr;
    gap: 10px; */
`;

const SidebarMenuButton = styled.button`
  font-size: 1rem;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  display: flex;
  width: 100%;
  height: 40px;
  border: none;
  background-color: none;
  color: blue;
  cursor: pointer;
`;

export default Docs;
