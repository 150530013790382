import { useEffect, useState } from "react";
import EventStore from "features/Notification/store";
import CustomConfirmationDialogWithChildren from "components/customConfirmationDialog/customConfirmationDialogWithChildren";
import EmployeeImportManager from "features/Notification/import-manager/store";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "components/ErrorDialog";
import { Alert, ButtonBase, Snackbar } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styled from "styled-components";
import { CloseIconRed } from "components/icons";
import DismissButton from "features/EmployeeImport/v2/components/DismissButton";

const Notification = () => {
  const [eventSourceHasSet, setEventSourceHasSet] = useState(false);

  useEffect(() => {
    if (!EventStore.eventSource && eventSourceHasSet) {
      EventStore.initializeEventSource();
    }

    setEventSourceHasSet(true);
    return () => {
      EventStore.closeEventSource();
    };
  }, [eventSourceHasSet]);

  return (
    <>
      <Dialogs />
    </>
  );
};

const Dialogs = observer(() => {
  const nav = useNavigate();

  return (
    <>
      <ErrorDialog
        title="File import unsuccessful"
        fileName={
          EmployeeImportManager.parsedFile.length > 0
            ? EmployeeImportManager.parsedFile[0].filename
            : "file.xlsx"
        }
        open={EmployeeImportManager.isOpenFileImportUnsucessfulDialog()}
        errorMessage="Oops! Your Excel file contains errors. Review the list of errors on the right and try uploading your file again."
        onClose={() => {
          EmployeeImportManager.setCanOpenImportUnsuccessfulDialog(false);
        }}
        onYes={() => {
          EmployeeImportManager.setCanOpenImportUnsuccessfulDialog(false);
          nav(`employees-import/${EmployeeImportManager.importId}`);
        }}
        buttonsCaption={{
          yesButton: "Review file",
          cancelButton: "Cancel",
        }}
      />

      <Snackbar
        id="Alert_Snackbar"
        anchorOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        open={EmployeeImportManager.isOpenImportFailedDialog()}
        onClose={() => EmployeeImportManager.setCanOpenImportUnsuccessfulDialog(false)}
        autoHideDuration={8000}
        key={"bottomleft"}
        sx={{
          backgroundColor: "#FFEBEB",
          borderRadius: "16px",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <StyledAlert>
          <AlertRow>
            <CloseIconRed />
            <AlertColumn>
              <AlertRow>
                <AlertTitle>Error</AlertTitle>
              </AlertRow>
              <AlertDesc>
                Oops! Something went wrong. If the error persists, drop a note to our customer
                support at support@engagerocket.co, and we’ll help you sort it out!
              </AlertDesc>
              <RetryButton
                onClick={() => {
                  EmployeeImportManager.handleConfirmReview();
                }}
              >
                Retry
              </RetryButton>
            </AlertColumn>
            <DismissButton
              onClick={() => {
                EmployeeImportManager.setCanOpenImportUnsuccessfulDialog(false);
              }}
            ></DismissButton>
          </AlertRow>
        </StyledAlert>
      </Snackbar>

      <ErrorDialog
        title="File upload unsuccessful"
        fileName={
          EmployeeImportManager.parsedFile.length > 0
            ? EmployeeImportManager.parsedFile[0].filename
            : "file.xlsx"
        }
        open={EmployeeImportManager.isOpenUploadFailedDialog()}
        errorMessage="Oops! Your Excel file contains errors. Review the list of errors on the right and try uploading your file again."
        onClose={() => {
          EmployeeImportManager.setCanOpenUploadFailedDialog(false);
        }}
        onYes={() => {
          EmployeeImportManager.setCanOpenUploadFailedDialog(false);
          nav(`employees-import/${EmployeeImportManager.importId}`);
        }}
        buttonsCaption={{
          yesButton: "Review file",
          cancelButton: "Cancel",
        }}
      />
    </>
  );
});

const StyledAlert = styled.div`
  background-color: #ffebeb !important;
  color: var(--colorNeutralBackground1, #fff) !important;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-align: center;
  padding: 1rem;
  gap: 1rem;
  max-width: 400px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AlertColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const AlertDesc = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--colorNeutralForeground1);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
`;

const RetryButton = styled(ButtonBase)`
  color: var(--colorBrandForeground1, #bd0202);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto;
`;

const AlertTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  color: var(--colorNeutralForeground1);
`;

const CancelIconWrapp = styled(CancelIcon)`
  color: var(--colorPaletteRedForeground2, #bd0202) !important;
`;

const CheckIconWrapp = styled(CheckCircleIcon)`
  color: var(--colorPaletteGreenBackground2, #3e4450) !important;
`;

export default Notification;
