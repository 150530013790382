import http from "../https";

export type TDashboardParameters = {
  workspace_id: number;
  entity_id: number;
  project_id: number;
  survey_id: number;
  viewType: string;
}

type TRequestBody = {
  dashboardParameters: TDashboardParameters,
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  variation?: string;
};

export const useGetDashboardData = (requestData: TRequestBody, endpoint: string = "/dashboard/dashboard/overview") =>
  http.dashboardAPIClient.post(endpoint, requestData);
