import { FC } from "react";

import { Box, Button } from "@mui/material";
import styled from "styled-components";
import UploadFileIcon from "components/icons/UploadFileIcon";

type UploadDropAreaProps = {
  formName: string;
  handleDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  handleFileChange: (files: FileList) => void;
  handleButtonClick: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  disabled: boolean;
};

const UploadDropArea: FC<UploadDropAreaProps> = ({
  formName,
  handleDrop,
  handleFileChange,
  fileInputRef,
  disabled,
}: UploadDropAreaProps) => {
  return (
    <FileWrapper
      id={`${formName}_fileWrapper`}
      onDrop={disabled ? () => {} : handleDrop}
      $disabled={disabled}
      onDragOver={(event) => event.preventDefault()}
    >
      <StyledContainerEmptyFile id={`${formName}_dropFileArea`}>
        <UploadBoxContent
          formName={formName}
          fileInputRef={fileInputRef}
          handleFileChange={handleFileChange}
          disabled={disabled}
        />
      </StyledContainerEmptyFile>
    </FileWrapper>
  );
};

type UploadBoxContentProps = {
  formName: string;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileChange: (files: FileList) => void;
  disabled: boolean;
};

const UploadBoxContent = ({
  formName,
  fileInputRef,
  handleFileChange,
  disabled,
}: UploadBoxContentProps) => {
  return (
    <Box id={`${formName}_fileUploadBox`} display={"flex"}>
      <ColumnWrapper
        onClick={() => {
          if (!disabled) {
            fileInputRef.current?.click();
          }
        }}
      >
        <UploadFileIcon />

        <EmptyFileWrapperText id={`${formName}_dragDropText`}>
          <TextWrapper $disabled={disabled}>
            <BrowseFilesText> Browse files </BrowseFilesText> or drag and drop to upload
          </TextWrapper>
          <TextWrapper $disabled={disabled}>.xlsx format, up to 50 MB</TextWrapper>
        </EmptyFileWrapperText>
        <input
          title="Upload file"
          id={`${formName}_fileInput`}
          type="file"
          ref={fileInputRef}
          accept=".xlsx"
          disabled={disabled}
          style={{ display: "none" }}
          onChange={(e) => handleFileChange(e.target.files)}
        />
        <label htmlFor={"uploadFile"}></label>
      </ColumnWrapper>
    </Box>
  );
};

const BrowseFilesText = styled.span`
  color: #1477f8;
`;

const FileWrapper = styled.div<{ $disabled: boolean }>`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background-color: var(--colorNeutralBackground1);
  border: 2px dashed var(--colorPaletteBlueBackground1);
  border-radius: 4px;
  margin-top: 8px;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  opacity: ${({ $disabled }) => ($disabled ? "0.4" : "1")};
  svg {
    color: ${({ $disabled }) =>
      !$disabled ? "var(--colorNeutralForeground5)" : "var(--colorPaletteBlueBackground1)"};
  }
`;

const StyledContainerEmptyFile = styled.div`
  text-align: center;
  width: 100%;
`;

const DownloadButton = styled(Button)`
  width: fit-content;
  padding: 8px !important;
  display: flex;
  margin-top: 10px;
  align-items: center;
  border: 1px solid var(--colorBrandForeground1);
  justify-content: flex-start !important;

  svg {
    margin-right: 5px;
  }
  span {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorBrandForeground1);
  }
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const EmptyFileWrapperText = styled.p`
  font-family: Roboto, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 0.625rem;
`;

const TextWrapper = styled.div<{ $disabled: boolean }>`
  font-family: Roboto, sans-serif;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-left: 0.625rem;
  color: ${({ $disabled }) =>
    !$disabled ? "var(--colorNeutralForeground5)" : "var(--colorPaletteBlueBackground1)"};
`;

export default UploadDropArea;
