import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { CircularProgress, MenuItem, Select, FormControl, SelectChangeEvent } from "@mui/material";

import dayjs from "dayjs";
import store from "../store";
import styled from "styled-components";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import FlagIcon from "@mui/icons-material/Flag";
import EmptyLabel from "./EmptyLabel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE, USE_DASHBOARD_API } from "constants/config";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SURVEY_STATUS_DASHBOARD } from "constants/survey-status";
import { OverViewElementRenderer } from "./OverViewElementRenderer";
import Error403Dialog from "components/errors/Error403";
import ErrorPage from "components/errors/ErrorPage";

type OverviewScreenProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
  onPdfDownload: () => void;
};

const OverviewScreen: FC<OverviewScreenProps> = observer((props) => {
  const handleBenchmarkChange = (event: SelectChangeEvent) => {
    store.setBenchmarkSelection(event.target.value);
  };

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");
    if (el) {
      el.scrollTop = 0;
    }
    
    // Load feature flags with better logging
    store.loadFeatureFlagDashboardMockBenchmark();
  }, []);

  useEffect(() => {
    if (
      store.selectedSurvey &&
      store.activeTab === "overview" &&
      store.overviewBenchmark !== undefined
    ) {
      store.getSurveyResponseCount(props.localFilters);
    }
  }, [
    store.selectedSurvey,
    props.localFilters,
    store.activeTab,
    store.overviewBenchmark,
    store.role,
    store.secondaryRole,
    store.filterUnitList,
  ]);

  useEffect(() => {
    if (
      store.overviewBenchmark !== undefined &&
      store.isSurveyHaveResponseCount &&
      store.activeTab === "overview" &&
      store.selectedSurvey
    ) {
      if (USE_DASHBOARD_API) {
        store.getDashboardData(store.selectedSurvey.value, props.localFilters);
      } else {
        store.getDimensionQuestions(store.selectedSurvey.value);
        store.getDimensionsData(store.selectedSurvey.value, props.localFilters);
        store.getParticipationData(store.selectedSurvey.value, props.localFilters);
        store.getOveralScoreData(store.selectedSurvey.value, props.localFilters);
        store.getEnpsData(store.selectedSurvey.value, props.localFilters);
        store.getSurveyQuestionsList(store.selectedSurvey.value, props.localFilters);
      }
    } else return;
  }, [
    props.localFilters,
    store.role,
    store.secondaryRole,
    store.overviewBenchmark,
    store.isSurveyHaveResponseCount,
    store.activeTab,
    store.filterUnitList,
  ]);

  useEffect(() => {
    if (
      store.selectedSurvey &&
      store.activeTab === "overview" &&
      store.overviewBenchmark !== undefined &&
      store.isSurveyHaveResponseCount
    ) {
      // OLD logic: trends data are fetched only after dimensions are available
      // New logic: dimensions for trends data are comming in the same response
      if (USE_DASHBOARD_API || store.selectedOverviewTrendCardDimenssionType !== null) {
        store.getTrendsData(store.selectedSurvey.value, props.localFilters, true);
      }
    } else return;
  }, [
    props.localFilters,
    store.selectedSurvey,
    store.activeTab,
    store.role,
    store.secondaryRole,
    store.overviewBenchmark,
    store.isSurveyHaveResponseCount,
    store.filterUnitList,
  ]);

  useEffect(() => {
    if (
      store.selectedSurvey &&
      store.activeTab === "overview" &&
      store.overviewBenchmark !== undefined &&
      store.isSurveyHaveResponseCount
    ) {
      // OLD logic: trends data are fetched only after dimensions are available
      // New logic: dimensions for trends data are comming in the same response
      if (USE_DASHBOARD_API || store.selectedOverviewTrendCardDimenssionType !== null) {
        store.getTrendsData(store.selectedSurvey.value, props.localFilters);
      }
    } else return;
  }, [store.overviewTrendCardFilter, store.selectedOverviewTrendCardDimenssionType]);

  useEffect(() => {
    if (
      store.activeTab === "overview" &&
      store.questionSelectedOpenEndedCard &&
      store.overviewBenchmark !== undefined &&
      store.isSurveyHaveResponseCount &&
      !USE_DASHBOARD_API
    ) {
      store.getOpenEndedQuestionsData(store.selectedSurvey.value, props.localFilters);
    } else return;
  }, [
    props.localFilters,
    store.activeTab,
    store.questionSelectedOpenEndedCard,
    store.role,
    store.secondaryRole,
    store.overviewBenchmark,
    store.isSurveyHaveResponseCount,
    store.filterUnitList,
  ]);

  useEffect(() => {
    if (
      store.selectedSurvey &&
      store.activeTab === "overview" &&
      store.overviewBenchmark !== undefined &&
      store.selectedOverviewSegmentsCardFilter !== null &&
      store.isSurveyHaveResponseCount
    ) {
      store.getSegmentsData(store.selectedSurvey.value, props.localFilters);
    } else return;
  }, [
    props.localFilters,
    store.selectedSurvey,
    store.activeTab,
    store.overviewSegmentsCardFilter,
    store.selectedOverviewSegmentsCardFilter,
    store.role,
    store.secondaryRole,
    store.overviewBenchmark,
    store.isSurveyHaveResponseCount,
    store.filterUnitList,
  ]);

  useEffect(() => {
    if (
      store.activeTab === "overview" &&
      store.overviewBenchmark !== undefined &&
      store.isSurveyHaveResponseCount &&
      store.selectedSurvey?.status === SURVEY_STATUS_DASHBOARD.Running
    ) {
      const interval = setInterval(() => {
        if (USE_DASHBOARD_API) {
          store.getDashboardData(store.selectedSurvey.value, props.localFilters);
        } else {
          store.getParticipationData(store.selectedSurvey.value, props.localFilters);
        }
      }, 1000 * 60);

      return () => clearInterval(interval);
    }
  }, [
    store.filters,
    store.selectedSurvey,
    store.activeTab,
    store.overviewBenchmark,
    store.isSurveyHaveResponseCount,
  ]);

  const sheduledCallData = () => {
    store.getSegmentsData(store.selectedSurvey.value, props.localFilters);
    store.getTrendsData(store.selectedSurvey.value, props.localFilters, true);
    store.getSurveyResponseCount(props.localFilters);
    if (USE_DASHBOARD_API) {
      store.getDashboardData(store.selectedSurvey.value, props.localFilters);
    } else {
      store.getDimensionQuestions(store.selectedSurvey.value);
      store.getOpenEndedQuestionsData(store.selectedSurvey.value, props.localFilters);
      store.getDimensionsData(store.selectedSurvey.value, props.localFilters);
      store.getParticipationData(store.selectedSurvey.value, props.localFilters);
      store.getOveralScoreData(store.selectedSurvey.value, props.localFilters);
      store.getEnpsData(store.selectedSurvey.value, props.localFilters);
      store.getSurveyQuestionsList(store.selectedSurvey.value, props.localFilters);
    }

    if (store.selectedSurvey?.status === SURVEY_STATUS_DASHBOARD.Running) {
      store.setData(
        `${dayjs().format("h")}:${Number(dayjs().format("m")) >= 30 ? "30" : "00"} ${dayjs().format(
          "A"
        )}`,
        "lastUpdated"
      );
    }
  };

  useEffect(() => {
    let interval = undefined;
    let timeout = undefined;

    if (store.activeTab === "overview" && store.isSurveyHaveResponseCount) {
      var currentTime = new Date();

      // Calculating the time until the next function call
      const min = currentTime.getMinutes();
      const diffMin = min > 30 ? 60 - min : 30 - min;
      const timeToNextCall = diffMin === 0 ? 60 * 30 * 1000 : diffMin * 60 * 1000;

      timeout = setTimeout(function () {
        // Running a function
        sheduledCallData();

        // Setting the interval for the next execution
        interval = setInterval(sheduledCallData, 60 * 30 * 1000);
      }, timeToNextCall);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [props.localFilters, store.selectedSurvey, store.activeTab, store.isSurveyHaveResponseCount]);

  const isPending = store.isPending;

  const handleDownloadPDF = async () => {
    props.onPdfDownload();
  };

  const header = (
    <>
      {IS_OLD_DASHBOARD_OR_ENABLED_FEATURE ? (
        <TopContainer>
          <TopContainerText>
            Viewing results for {store.calculateRespondentsCount} respondents
          </TopContainerText>
          <Divider>|</Divider>
          <CustomWidthTooltip
            title={`Local timezone: UTC ${dayjs().format("Z")}`}
            placement="top"
            arrow
          >
            <TopContainerText>
              Based on results last updated: Today, {store.lastUpdated}
              {store.selectedSurvey?.status === SURVEY_STATUS_DASHBOARD.Running && (
                <FiberManualRecordIcon />
              )}
            </TopContainerText>
          </CustomWidthTooltip>
        </TopContainer>
      ) : (
        <Container />
      )}
      <Group>
          {store.isDashboardMockBenchmarkEnabled && (
            <BenchmarkSelectorWrapper>
              <BenchmarkIcon 
                src="/assets/ic_flag_benchmark.svg" 
                alt="Benchmark flag"
              />
              <BenchmarkLabel>Benchmark against:</BenchmarkLabel>
              <FormControl size="small">
                <StyledSelect
                  value={store.benchmarkSelection}
                  onChange={handleBenchmarkChange}
                  IconComponent={KeyboardArrowDownIcon}
                  displayEmpty
                >
                  {Object.entries(store.benchmarkOptions).map(([key, value]) => (
                    <StyledMenuItem key={key} value={key}>
                      {value}
                    </StyledMenuItem>
                  ))}
                </StyledSelect>
              </FormControl>
            </BenchmarkSelectorWrapper>
          )}
          <DownloadButton 
            disabled={!store.isSurveyHaveResponseCount}
            variant="outlined"
            onClick={handleDownloadPDF}
            startIcon={<DownloadIcon />}
          >
            Download Results
          </DownloadButton>
        </Group>
    </>
  );

  const renderer = new OverViewElementRenderer({localFilters: props.localFilters});

  const content = (
    <DashboardContent>
      {renderer.render()}
    </DashboardContent>
  );

  if (store.isSurveyResponseCountPending) {
    return (
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    );
  }

  return (
    <Container>
      {store.isDashboardDataError ? <ErrorPage /> : <>
        <TopWrapper>{!isPending ? header : null}</TopWrapper>
        {!isPending && !store.isSurveyHaveResponseCount && !store.isDashboardDataError ? <EmptyLabel /> : null}
        {store.isSurveyHaveResponseCount && !store.isDashboardDataError ? content : null}
      </>}
    </Container>
  );
});

export default OverviewScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: 0;
  padding-bottom: 80px;
`;

const TopWrapper = styled.div`
  min-height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px 16px 0px;
  position: relative;
  background-color: inherit;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;

  @media (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  cursor: default;

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const TopBox = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
`;

const MiddleTobBox = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 16px;
  margin-top: 16px;
`;

const MiddleBottomBox = styled.div`
  width: 100%;
  display: flex;
  column-gap: 16px;
  margin-top: 16px;
`;

const BottomBox = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);

  @media (max-width: 1439px) {
    display: none;
  }
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const DownloadButton = styled(Button)`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  margin-left: 10px !important;
  background-color: ${(props) =>
    !props.disabled ? "var(--colorNeutralBackground1)" : "transparent"} !important;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const FilterBox = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 0px 8px 0px 4px;
  white-space: nowrap;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: var(--colorPaletteVioletBackground3);
  width: 18px !important;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  min-width: 150px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorNeutralBackground3);
  }
  
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorBrandForeground1);
  }
  
  .MuiSelect-select {
    padding: 6px 12px;
  }
  
  .MuiSvgIcon-root {
    color: var(--colorNeutralForeground3);
    font-size: 20px;
    right: 6px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  padding: 6px 12px;
  
  &.Mui-selected {
    background-color: var(--colorBrandBackground1);
    color: var(--colorBrandForeground1);
  }
  
  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DashboardContent = styled.div`
  background-color: var(--colorPaletteGrayBackground2);
  padding: 16px;
  border-radius: 4px;
`;

const BenchmarkSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const BenchmarkIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const BenchmarkLabel = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-right: 8px;
  white-space: nowrap;
`;