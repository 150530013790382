import { Dayjs } from "dayjs";
import { useState } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import NoDataIcon from "../NoDataIcon";
import { useNavigate } from "react-router";
import DoneIcon from "@mui/icons-material/Done";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField, LocalizationProvider } from "@mui/x-date-pickers";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { AttributeSegments } from "types/employee";
import CustomButton from "components/button/Button";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import storeEditSegment from "./EditSegment/store";
import store from "./storeSegments";
import EmployeeImportManager from "features/Notification/import-manager/store";

const CustomNoRowsOverlay = observer((props: any) => {
  const navigate = useNavigate();

  return (
    <StyledGridOverlay>
      <StyledTitleNoResult id="Attribute_AttributeList_Segments_Grid_NoResultsFound">
        No results found
      </StyledTitleNoResult>
      <NoDataIcon />
      <WrappedButton>
        <CustomButton
          id="Attribute_AttributeList_Segments_Grid_ImportEmployeesBtn"
          variant="contained"
          disabled={EmployeeImportManager.hasOngoingImport}
          onClick={() => {
            navigate("/employees-import");
          }}
        >
          Import employees
        </CustomButton>
      </WrappedButton>
    </StyledGridOverlay>
  );
});

const AttributeTable = observer((props: any) => {
  const [sortModel, setSortModel] = useState({ field: null, sort: null });

  const changeSort = (field: string, sort: string) => {
    setSortModel({ field, sort });
  };

  const sortedData = [...store.data.slice().sort((a, b) => b.id - a.id)].sort((a, b) => {
    const { sort } = sortModel;
    const left = a[sortModel.field];
    const right = b[sortModel.field];

    if (sort !== "asc" && sort !== "desc") {
      return 0;
    }

    if ((sort === "asc" && left < right) || (sort === "desc" && left > right)) {
      return -1;
    }

    return 1;
  });

  const checkHandler = (row: AttributeSegments) => {
    if (store.selected.includes(row.id)) {
      store.changeValue(
        "selected",
        store.selected.filter((id) => id !== row.id),
      );
    } else {
      store.changeValue("selected", [...store.selected, row.id]);
    }
  };

  const columns = [
    {
      id: "",
      label: "",
      width: 50,
      render: (row: AttributeSegments) => {
        if (store.openEditSegment !== row.id && !StoreLayout.lockEdit) {
          return (
            <IconButton disabled={!!store.openEditSegment} onClick={() => checkHandler(row)}>
              {store.selected.includes(row.id) ? (
                <Checked $active={true}>
                  <DoneIcon />
                </Checked>
              ) : (
                <Checked />
              )}
            </IconButton>
          );
        }
      },
    },
    {
      id: "value",
      label: (
        <StyledContainerLabelColumn>
          <StyledTitle>SEGMENT NAME</StyledTitle>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              style={
                sortModel.field === "value" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("value", "asc")}
            />
            <KeyboardArrowDownIconStyle
              style={
                sortModel.field === "value" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("value", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (row: AttributeSegments) => {
        return (
          <>
            {store.openEditSegment === row.id ? (
              <>
                {!store.attribute?.is_date_attribute ? (
                  <TextField
                    data-id={`SigmentList_InputContainer`}
                    value={storeEditSegment.name}
                    variant="standard"
                    style={{ width: "90%" }}
                    error={storeEditSegment.errorName !== ""}
                    helperText={storeEditSegment.errorName}
                    inputProps={{
                      pattern: "[a-zA-Z0-9s ]+",
                    }}
                    onChange={(e) => storeEditSegment.changeName(e.target)}
                  />
                ) : (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      variant="standard"
                      format="DD.MM.YYYY"
                      style={{ width: "90%" }}
                      value={storeEditSegment.name}
                      helperText={storeEditSegment.errorName}
                      onChange={(newValue: Dayjs) => storeEditSegment.changeDate(newValue)}
                    />
                  </LocalizationProvider>
                )}
              </>
            ) : (
              <p>{row.value}</p>
            )}
          </>
        );
      },
    },
    {
      id: "count",
      label: (
        <StyledContainerLabelColumn>
          <StyledTitle>TOTAL EMPLOYEES</StyledTitle>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              style={
                sortModel.field === "count" && sortModel.sort === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("count", "asc")}
            />
            <KeyboardArrowDownIconStyle
              style={
                sortModel.field === "count" && sortModel.sort === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => changeSort("count", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (row: AttributeSegments) => {
        return (
          <>
            {store.openEditSegment === row.id ? (
              <ButtonTableWrapper>
                <CustomButton
                  data-id={`SigmentList_SaveChanges_Btn`}
                  variant="outlined"
                  disabled={storeEditSegment.errorName !== ""}
                  onClick={() => {
                    store.changeValue("openEditSegment", null);
                    storeEditSegment.checkNameUniqueness();
                  }}
                >
                  Save changes
                </CustomButton>
                <CustomButton
                  data-id={`SigmentList_Cansel_Btn`}
                  onClick={() => {
                    store.changeValue("openEditSegment", null);
                    storeEditSegment.clearStore();
                  }}
                >
                  Cancel
                </CustomButton>
              </ButtonTableWrapper>
            ) : (
              <>
                <p>{row.count}</p>
              </>
            )}
          </>
        );
      },
    },
  ];

  return (
    <TableWrapped id={`Attribute_AttributeList_Segments_DataGridContainer`}>
      <StyledContainer component={Paper} sx={{ height: 505 }}>
        <StyledTable stickyHeader>
          <StyledTableHead>
            <StyledTableRowOne>
              {columns?.map((column, i) => (
                <StyledTableCellHeader style={{ width: column.width, padding: 0 }} key={column.id}>
                  {column.label}
                </StyledTableCellHeader>
              ))}
            </StyledTableRowOne>
          </StyledTableHead>
          {sortedData.length === 0 ? (
            <CustomNoRowsOverlay />
          ) : (
            <TableBody>
              {sortedData.map((row, i) => (
                <StyledTableRow key={i} onClick={() => {}}>
                  {columns.map((column, index) => (
                    <StyledTableCell style={{ width: column.width, padding: 0 }} key={column.id}>
                      {column.render(row)}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </StyledTable>
      </StyledContainer>
    </TableWrapped>
  );
});

export default AttributeTable;

const Checked = styled.span<{ $active?: boolean }>`
  width: 28px;
  min-width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralBackground3)"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 20px;
    color: var(--colorNeutralBackground1);
  }
`;

const StyledGridOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 40%;
`;

const ButtonTableWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;

const WrappedButton = styled.div`
  margin: 10px;
  button {
    padding: 6px 33px;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`;

const StyledTitleNoResult = styled.div`
  color: var(--colorNeutralForeground5);
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 10px;
`;

const TableWrapped = styled.div`
  width: 100%;
  height: 550px;
  margin-top: 10px;
`;

const StyledTable = styled(Table)`
  min-width: 414px !important;
`;

const StyledTableHead = styled(TableHead)`
  background: var(--colorPaletteBlueBackground3) !important;
`;

const StyledContainer = styled(TableContainer)`
  width: 100% !important;
  box-shadow: none !important;
  position: relative;
`;

const StyledTableRowOne = styled(TableRow)`
  height: 56px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--colorNeutralForeground4) !important;
  background: var(--colorPaletteBlueBackground3) !important;
`;

const StyledTableRow = styled(TableRow)<{ $active?: boolean }>`
  width: 100%;
  cursor: pointer;

  border-bottom: 1px solid var(--colorNeutralForeground4);

  background: ${(props) =>
    props.$active
      ? "linear-gradient(90deg, var(--colorNeutralForeground4) 99%, var(--colorBrandForeground1) 6px);"
      : "var(--colorNeutralBackground1)"};
  height: 56px;
`;

const StyledTableCellHeader = styled(TableCell)`
  color: var(--colorNeutralForeground1) !important;
  background: var(--colorPaletteBlueBackground3) !important;
  font-style: normal;
`;

const StyledTableCell = styled(TableCell)`
  color: var(--colorNeutralForeground1) !important;
  font-style: normal;
  > td {
    padding: 0;
  }
`;

const StyledTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--colorNeutralForeground2);
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const BlockSortStyle = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;
