import http from "api/https";
import { createUrl } from "../../helpers/url";
import { APIARY_DOMAIN } from "constants/apiary";

export const getDashboardPermissions = (
  projectId: number,
  roleId: number,
  regionId: number,
): Promise<any> => {
  const query: {
    role_id: string;
    region_id: string;
  } = {
    role_id: roleId.toString(),
    region_id: regionId.toString(),
  };

  const url = createUrl({
    baseUrl: APIARY_DOMAIN,
    path: `/permission/entity_permissions/OtherProjectPermissions/${projectId}`,
    query: query,
  });

  return http.getGO(url);
};
