import styled from "styled-components";
import FileIcon from "components/icons/FileIcon";
import NebulaAccordion from "components/Accordion/NebulaAccordion";
import { WarningIconNew } from "components/icons/WarningIcon";
import EmployeeImportManager from "features/Notification/import-manager/store";
import { parseFileUploaded } from "utils/parseFileUploaded";
import { ImportValidationError } from "types/import-validation-error";

const FileImportErrorSidebar = () => {
  const errors = Array.isArray(EmployeeImportManager.employeeImportUIState.validationErrors)
    ? (EmployeeImportManager.employeeImportUIState.validationErrors || []).reduce<
        Record<string, ImportValidationError[]>
      >((acc, error) => {
        const code = error.code;
        if (!acc[code]) {
          acc[code] = [];
        }
        acc[code].push(error);
        return acc;
      }, {})
    : {};

  const errs = Object.entries(errors).map(([code, errors]) => {
    return {
      title: `${errors[0].column_name} - ${errors[0].description}`,
      list: errors.map(({ value }) => `${value}`),
    };
  });

  const file = parseFileUploaded({
    file: EmployeeImportManager.importSelectedFile,
    fileUploaded: EmployeeImportManager.employeeImportUploadFile,
    progress: EmployeeImportManager.uploadExcelProgress,
  });

  return (
    <ErrorSidebarWrapper>
      <ErrorSidebarTitle>File import errors</ErrorSidebarTitle>
      <ErrorWarningDiv>
        <ErrorWarningIcon>
          <WarningIconNoMargin></WarningIconNoMargin>
        </ErrorWarningIcon>
        <ErrorWarningText>
          We can only display the first 20 rows of errors, but there may be more. Review these
          errors and reupload your file.
        </ErrorWarningText>
      </ErrorWarningDiv>
      <FileImportDiv>
        <FileIcon></FileIcon>
        <FileImportText>{file[0]?.filename}</FileImportText>
      </FileImportDiv>
      <NebulaAccordion items={errs}></NebulaAccordion>
    </ErrorSidebarWrapper>
  );
};

const ErrorSidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  max-width: 500px;
`;

const ErrorSidebarTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const ErrorWarningDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
`;

const FileImportDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

const FileImportText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const ErrorWarningIcon = styled.div`
  width: 44px;
  height: 24px;
  margin: 0;
`;

const ErrorWarningText = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorPaletteRedForeground2);
`;

const WarningIconNoMargin = styled(WarningIconNew)`
  margin: 0;
`;

export default FileImportErrorSidebar;
