import { Button } from "@mui/material";
import { observer } from "mobx-react";
import { StarIcon } from "./StarIcon";
import { FC, Fragment, useEffect, useState } from "react";
import {  TScoredQuestionsDimensionResponse } from "types/dashboard";
import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import LinearBar from "./LinearBar";
import PeopleIcon from "@mui/icons-material/People";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import RemoveIcon from "@mui/icons-material/Remove";
import FormatSizeRounded from "@mui/icons-material/FormatSizeRounded";
import EmptyLabel from "../EmptyLabel";

type QuestionCardProps = {
  question: TScoredQuestionsDimensionResponse;
  isLastQuestion: boolean;
  localFilters: { id: number; name: string; segments: string[] }[];
  isQuestion?: boolean;
  isCommentsExpanded?: boolean;
  isDimensionBelowCT?: boolean;
};

const QuestionCard: FC<QuestionCardProps> = observer((props) => {
  const [isBelowCT, setIsBelowCT] = useState(false);

  useEffect(() => {
    setIsBelowCT(props.question?.respondedCount < topStore.selectedSurvey.threshold);
  }, [props.question]);

  return (
    <Container $isLast={props.isLastQuestion}>
      <ColGroup>
        <Col>
          <Title>{props.question?.title}</Title>

          {!isBelowCT && props.isQuestion && (
            <Group>
              <StyledPeopleIcon />
              <SmallText>{props.question?.respondedCount} responded</SmallText>
            </Group>
          )}

          {props.question.overall && (
            <Group>
              <StyledDataUsageIcon />
              <SmallText>Contributes to overall score</SmallText>
            </Group>
          )}
          {props.question.conditionalLogic && (
            <Group>
              <ConditionalLogicPopupButton
                variant="text"
                onClick={() => {
                  store.setData(props.question, "activeScoredQuestion");
                  store.setData(true, "isOpenConditionalLogicPopup");
                }}
              >
                <StarIcon />
                Conditional logic applied
              </ConditionalLogicPopupButton>
            </Group>
          )}
        </Col>
        {((!props.isQuestion && props.isDimensionBelowCT) || (props.isQuestion && isBelowCT)) ?
            <Col>
              <Row>
                <Label>All respondents</Label>
              </Row>
              <Row>-</Row>
            </Col>
          :
            <Col>
              <Label>Filtered respondents</Label>
              <Row>
                <BoldText>{props.question.allRespondentsPercent}%</BoldText>
                <Fragment>
                    {topStore.selectedSurvey?.isFirst || props.question.allRespondentsPercentDiff === null ? (
                      <StyledRemoveIcon />
                    ) : (
                      <>
                        {
                          props.question.allRespondentsPercentDiff > 0 ? (
                                <StyledArrowDropUpIcon />
                              ) : props.question.allRespondentsPercentDiff < 0 ? (
                                <StyledArrowDropDownIcon />
                              ) : (
                                <StyledEmptyIcon />
                              )}
                            {props.question.allRespondentsPercentDiff !== 0 && props.question.allRespondentsPercentDiff ? (
                              <Text>{Math.abs(props.question.allRespondentsPercentDiff)}</Text>
                            ) : (<Text>{props.question.allRespondentsPercentDiff}</Text>)}
                          </>
                        )}
                </Fragment>
              </Row>
              <Row>
                <LinearBar
                  favorable={props.question.favourablePercent}
                  neutral={props.question.neutralPercent}
                  unFavourable={props.question.unFavourablePercent}
                />
              </Row>
            </Col>
        }
        <BenchmarkCol>
          {topStore.isDashboardMockBenchmarkEnabled && (
            <>
              <Label>Benchmark:</Label>
              <Row>
                <Benchmark>-{Math.abs(props.question.benchmarkDiff)}%</Benchmark>
                <Text>({props.question.benchmark}%)</Text>
              </Row>
            </>
          )}
        </BenchmarkCol>
      </ColGroup>

      {props.isQuestion && isBelowCT && <EmptyLabel setHeight={false} />}
    </Container>
  );
});

export default QuestionCard;

const Container = styled.div<{ $isLast: boolean }>`
  width: 100%;
  padding-bottom: 48px;
  ${(props) => !props.$isLast && "border-bottom: 1px solid var(--colorPaletteBlueBackground1)"};
  margin-top: 24px;
`;

const ColGroup = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 32px;
  margin-bottom: 32px;
`;

const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const BenchmarkCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: right;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-bottom: 12px;
`;

const Group = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

const SmallText = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin-left: 4px;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
  font-size: 16px !important;
  color: var(--colorNeutralForeground5);
`;

const StyledDataUsageIcon = styled(DataUsageIcon)`
  font-size: 16px !important;
  color: var(--colorPaletteVioletBackground3);
`;

const Label = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
  color: var(--colorPaletteGreenBackground2);
  font-size: 24px !important;
  margin-top: -2px !important;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
  color: var(--colorPaletteRedForeground1);
  font-size: 24px !important;
  margin-top: -4px !important;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  ${BenchmarkCol} & {
    justify-content: flex-end;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const BoldText = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const Benchmark = styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorPaletteVioletBackground3);
  margin-right: 4px;
`;

const ConditionalLogicPopupButton = styled(Button)`
  padding: 2px !important;
  text-transform: none !important;

  svg {
    margin-right: 4px;
  }
`;

const StyledRemoveIcon = styled(RemoveIcon)`
  color: var(--colorNeutralForeground5);
  font-size: 18px !important;
  margin-left: 8px;
`;

const StyledEmptyIcon = styled(FormatSizeRounded)`
    color: transparent;
    font-size: 18px !important;
`;