import http from "api/https";
import { createUrl } from "../../helpers/url";
import { DashboardPermission, SurveyManagementPermissions } from "types/permission";

export const updateOtherPermission = (
  projectId: number,
  roleId: number,
  regionId: number,
  updatedSurveyManagementPermissions: SurveyManagementPermissions,
  updatedDashboardPermssions: DashboardPermission[],
): Promise<any> => {
  const data: {
    role_id: number;
    region_id: number;
    survey_management_permissions: SurveyManagementPermissions;
    dashboard_permissions: DashboardPermission[];
  } = {
    role_id: roleId,
    region_id: regionId,
    survey_management_permissions: updatedSurveyManagementPermissions,
    dashboard_permissions: updatedDashboardPermssions,
  };

  const url = createUrl({
    path: `/permission/entity_permissions/OtherProjectPermission/${projectId}`,
  });

  return http.putGO(url, data);
};
