import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

import Page404 from "./pages/404/Page404";
import AppWrapper from "./AppWrapper";
import PrivateRoute from "./PrivateRoute";
import EntitiesPage from "./pages/EntitiesPage/EntitiesPage";
import TemplatesPage from "./pages/TemplatesPage/TemplatesPage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import ImportHistoricalSurveyPage from "./pages/ImportHistoricalSurvey/ImportHistoricalSurveyPage";
import SurveysPage from "./pages/SurveysPage/SurveysPage";
import DashboardPage from "./pages/DasboardPage/DashboardPage";
import SimpleWrapper from "./SimpleWrapper";
import WorkspacePermissionPage from "./pages/WorkspacePermissionPage/PermissionsPage";
import EntityPermissionPage from "./pages/EntityPermissionPage/PermissionsPage";
import EntityPermissionEditPage from "./pages/EntityPermissionEditPage/PermissionsEditPage";
import OtherPermissionsEditPage from "pages/OtherPermissionEditPage/OtherPermissionsEditPage";
import AccountSettingsPage from "./pages/AccountSettingsPage/AccountSettingsPage";
import CreateCommunicationTemplatePage from "./pages/CreateCommunicationTemplatePage/CreateCommunicationTemplatePage";
import CreateQuestionnaireTemplatePage from "./pages/CreateQuestionnaireTemplatePage/CreateQuestionnaireTemplatePage";
import SurveyPreviewPage from "./pages/SurveyPreviewPage/SurveyPreviewPage";
import SurveyPage from "./pages/SurveyPage/SurveyPage";
import CommunicationsPage from "./pages/CommunicationsPage/CommunicationsPage";
import SelectWorkspacePage from "./pages/SelectWorkspacePage/SelectWorkspacePage";
import BackToMainLayoutWrapper from "./BackToMainLayoutWrapper";
import EmployeesPage from "./pages/EmployeesPage/EmployeesPage";
import CreateCommunicationTemplateHeader from "layouts/CommunicationTemplate/CreateCommunicationTemplateHeader";
import CreateQuestionnaireTemplateHeader from "layouts/QuestionnaireTemplate/CreateQuestionnaireTemplateHeader";
import SurveyPreviewHeader from "layouts/SurveyPreview/SurveyPreview";
import SelectWorkspaceScreen from "./pages/WorkspacePage/SelectWorkspaceScreen";
import EmployeeImportPage from "pages/EmployeeImportPage/EmployeeImportPage";

import Error403Page from "pages/403/index";
import RecipientsPage from "pages/Recipients/RecipientsPage";
import SurveyLayout from "layouts/SurveyLayout/SurveyLayout";
import MillstoneRecipientShedulePage from "pages/MillstoneRecipientShedulePage/MillstoneRecipientShedulePage";

import { setLicenseKey } from "survey-core";
import DashboardRecipientsPage from "pages/DashboardRecipientsPage/DashboardRecipientsPage";
import Highcharts from "highcharts";

import ImpersonationPage from "pages/ImpersonationPage/ImpersonationPage";
import { AuthProvider, Environment, Platform } from "@engagerocketco/auth-sdk";
import Docs from "docs";

require("highcharts/modules/accessibility")(Highcharts);

const router = createBrowserRouter([
  {
    element: (
      <AuthProvider
        config={{
          platform: Platform.MAIN,
          environment: process.env.REACT_APP_ENVIRONMENT as Environment,
        }}
      >
        <Outlet />
      </AuthProvider>
    ),
    children: [
      {
        element: <SurveyPreviewHeader />,
        children: [{ path: "survey-preview", element: <SurveyPreviewPage /> }],
      },
      {
        element: <ImpersonationPage />,
        path: "impersonate/initiate",
      },
      {
        children: [
          {
            element: <PrivateRoute />,
            children: [
              {
                element: <AppWrapper />,
                children: [
                  { index: true, element: <Navigate to="/select-workspace" /> },
                  { path: "dashboard", element: <DashboardPage /> },
                  { path: "entities", element: <EntitiesPage /> },
                  { path: "workspace", element: <SelectWorkspaceScreen /> },
                  { path: "workspacePermissions", element: <WorkspacePermissionPage /> },
                  { path: "entityPermissions", element: <EntityPermissionPage /> },
                  { path: "entityPermissions/edit", element: <EntityPermissionEditPage /> },
                  { path: "otherPermisions/edit", element: <OtherPermissionsEditPage /> },
                  { path: "employees", element: <EmployeesPage /> },
                  { path: "employees-import", element: <EmployeeImportPage /> },
                  { path: "employees-import/:importId", element: <EmployeeImportPage /> },
                  { path: "templates", element: <TemplatesPage /> },
                  { path: "projects", element: <ProjectsPage /> },
                  { path: "surveys", element: <SurveysPage /> },
                  { path: "import-historical-survey", element: <ImportHistoricalSurveyPage /> },
                ],
              },
              {
                element: <BackToMainLayoutWrapper />,
                children: [{ path: "account-settings", element: <AccountSettingsPage /> }],
              },
              {
                element: <CreateCommunicationTemplateHeader />,
                children: [
                  { path: "communication-template", element: <CreateCommunicationTemplatePage /> },
                ],
              },
              {
                element: <CreateQuestionnaireTemplateHeader />,
                children: [
                  { path: "questionnaire-template", element: <CreateQuestionnaireTemplatePage /> },
                ],
              },
              {
                element: <SimpleWrapper />,
                children: [{ path: "select-workspace", element: <SelectWorkspacePage /> }],
              },
              {
                element: <SurveyLayout />,
                children: [
                  { path: "survey", element: <SurveyPage /> },
                  { path: "recipients", element: <RecipientsPage /> },
                  {
                    path: "recipients-millstone-schedule",
                    element: <MillstoneRecipientShedulePage />,
                  },
                  { path: "communications", element: <CommunicationsPage /> },
                ],
              },
              { path: "dashboard/recipients", element: <DashboardRecipientsPage /> },
            ],
          },
          { path: "*", element: <Page404 /> },
          { path: "error-404", element: <Page404 /> },
          { path: "access-denied", element: <Error403Page /> },
          ...(process.env.REACT_APP_ENVIRONMENT === "dev" &&
          process.env.REACT_APP_SHOW_DOCS === "true"
            ? [{ path: "docs", element: <Docs /> }]
            : []),
        ],
      },
    ],
  },
]);

const App = () => {
  setLicenseKey(window["_env_"]?.REACT_APP_SURVEYJS_KEY ?? process.env.REACT_APP_SURVEYJS_KEY);

  return <RouterProvider router={router} />;
};

export default App;
