import { observer } from "mobx-react";
import { ROLE_LIST } from "constants/role-list";
import { FC, useEffect } from "react";
import { MenuItem, Select, Switch, SelectChangeEvent, FormControl } from "@mui/material";

import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import FlagIcon from "@mui/icons-material/Flag";
import EmptyLabel from "./EmptyLabel";
import GraphWrapper from "./graph/GraphWrapper";
import LoadingPopup from "./LoadingPopup/LoadingPopup";
import SetUpColumnsPopup from "./SetUpColumnsPopup/SetUpColumnsPopup";
import SetUpSegmentsPopup from "./SetUpSegmentsPopup/SetUpSegmentsPopup";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE } from "constants/config";
import ErrorPage from "components/errors/ErrorPage";

type HotspotsScreenProps = {};

const HotspotsScreen: FC<HotspotsScreenProps> = observer(() => {
  const handleBenchmarkChange = (event: SelectChangeEvent) => {
    topStore.setBenchmarkSelection(event.target.value);
  };

  useEffect(() => {
    if (topStore.selectedSurvey && topStore.activeTab === "hotspots") {
      store.getQuestionsList(topStore.selectedSurvey.value);
      topStore.getSurveyResponseCount([]);
      
      // Load the benchmark feature flag when the component mounts
      topStore.loadFeatureFlagDashboardMockBenchmark();
    }
  }, [topStore.selectedSurvey, topStore.activeTab, topStore.role, topStore.secondaryRole, topStore.filterUnitList]);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "hotspots" &&
      topStore.overviewBenchmark !== undefined &&
      store.questionsList &&
      topStore.role
    ) {
      store.getData(
        StoreLayout.currentWorkspaceId,
        StoreLayout.currentEntityId,
        topStore.selectedProject.id,
        topStore.selectedSurvey.value,
        topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
        topStore.overviewBenchmark
      );
    }
  }, [
    StoreLayout.currentWorkspaceId,
    StoreLayout.currentEntityId,
    topStore.selectedProject.id,
    topStore.selectedSurvey,
    store.isShowENPSColumn,
    store.isFavourableScore,
    store.isOveralScore,
    topStore.overviewBenchmark,
    store.selectedSegments,
    store.questionsList,
    topStore.role,
    topStore.filterUnitList,
  ]);

  return (
    <Container>
      {IS_OLD_DASHBOARD_OR_ENABLED_FEATURE && !topStore.isDashboardDataError && (
        <TopContainer>
          <Group>
            <TopContainerText>
              Viewing results for {topStore.calculateRespondentsCount} respondents
            </TopContainerText>
            <Divider>|</Divider>
            <TopContainerText>
              Last updated: Today, {topStore.lastUpdated} <FiberManualRecordIcon />
            </TopContainerText>
          </Group>
          <Group>
            {topStore.isDashboardMockBenchmarkEnabled && (
              <BenchmarkSelectorWrapper>
                <BenchmarkIcon 
                  src="/assets/ic_flag_benchmark.svg" 
                  alt="Benchmark flag"
                />
                <BenchmarkLabel>Benchmark against:</BenchmarkLabel>
                <FormControl size="small">
                  <StyledSelect
                    value={topStore.benchmarkSelection}
                    onChange={handleBenchmarkChange}
                    IconComponent={KeyboardArrowDownIcon}
                    displayEmpty
                  >
                    {Object.entries(topStore.benchmarkOptions).map(([key, value]) => (
                      <StyledMenuItem key={key} value={key}>
                        {value}
                      </StyledMenuItem>
                    ))}
                  </StyledSelect>
                </FormControl>
              </BenchmarkSelectorWrapper>
            )}
          </Group>
        </TopContainer>
      )}
      {topStore.isDashboardDataError ? (
        <ErrorPage />
      ) : topStore.isSurveyHaveResponseCount ? (
        <GraphWrapper />
      ) : (
        <EmptyLabel />
      )}
      <SetUpColumnsPopup />
      <LoadingPopup />
      <SetUpSegmentsPopup />
    </Container>
  );
});

export default HotspotsScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px 16px 0px;
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const StyledFlagIcon = styled(FlagIcon)`
  color: var(--colorPaletteVioletBackground3);
  width: 18px !important;
  margin-right: 4px;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  min-width: 150px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorNeutralBackground3);
  }
  
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorBrandForeground1);
  }
  
  .MuiSelect-select {
    padding: 6px 12px;
  }
  
  .MuiSvgIcon-root {
    color: var(--colorNeutralForeground3);
    font-size: 20px;
    right: 6px;
  }
`;

const FilterBox = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin: 0px 8px 0px 4px;
  white-space: nowrap;
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  padding: 6px 12px;
  
  &.Mui-selected {
    background-color: var(--colorBrandBackground1);
    color: var(--colorBrandForeground1);
  }
  
  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const StyledSwitch = styled(Switch)`
  max-width: 60px !important;
  margin-right: -12px;
`;

const BenchmarkSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const BenchmarkIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const BenchmarkLabel = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-right: 8px;
  white-space: nowrap;
`;
