import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { ExpandLess, ExpandMore, CheckCircle } from '@mui/icons-material';
import styled from 'styled-components';
import { TDashboardUnitFilter } from 'types/dashboard';
import { Radio } from '@mui/material';

interface SingleTreeSelectProps {
  items: TDashboardUnitFilter[];
  onSelect: (selectedIds: string[]) => void;
  searchTerm?: string;
  currentMatchIndex?: number;
  hideUnselectedDisabledItems?: boolean;
  value?: string[];
  previousSelections?: string[];
}

interface MatchInfo {
  id: string;
  label: string;
}

const getInitialSelectedItems = (items: TDashboardUnitFilter[], value?: string[]): string[] => {
  if (!value || value.length === 0) {
    return [];
  }
  
  const selectedIds: string[] = [];
  
  const findItemsById = (allItems: TDashboardUnitFilter[], ids: string[]) => {
    for (const item of allItems) {
      if (ids.includes(item.id)) {
        selectedIds.push(item.id);
      }
      if (item.children) {
        findItemsById(item.children, ids);
      }
    }
  };
  
  findItemsById(items, value);
  return selectedIds;
};

const isDescendantOf = (items: TDashboardUnitFilter[], itemId: string, potentialAncestorId: string): boolean => {
  const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const item = findItem(items, itemId);
  if (!item) return false;

  let currentItem = item;
  const findParent = (items: TDashboardUnitFilter[], childId: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some(child => child.id === childId)) {
        return item;
      }
      if (item.children) {
        const found = findParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  while (true) {
    const parent = findParent(items, currentItem.id);
    if (!parent) break;
    if (parent.id === potentialAncestorId) return true;
    currentItem = parent;
  }

  return false;
};

const filterItemsByPreviousUnit = (items: TDashboardUnitFilter[], previousUnitId: string | null): TDashboardUnitFilter[] => {
  if (!previousUnitId) return items;

  const findUnit = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findUnit(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const previousUnit = findUnit(items, previousUnitId);
  if (!previousUnit) return [];

  if (previousUnit.children && previousUnit.children.length > 0) {
    return previousUnit.children;
  }

  return [];
};

const findParentUnits = (allItems: TDashboardUnitFilter[], selectedIds: string[]): string[] => {
  const parentUnits = new Set<string>();
  
  const findDirectParent = (items: TDashboardUnitFilter[], childId: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some(child => child.id === childId)) {
        return item;
      }
      if (item.children) {
        const found = findDirectParent(item.children, childId);
        if (found) return found;
      }
    }
    return null;
  };

  selectedIds.forEach(id => {
    let currentId = id;
    while (true) {
      const parent = findDirectParent(allItems, currentId);
      if (!parent) break;
      parentUnits.add(parent.id);
      currentId = parent.id;
    }
  });
  
  return Array.from(parentUnits);
};

export const SingleTreeSelect = forwardRef<{
  scrollToMatch: (itemId: string) => void;
  clearSelection: () => void;
  handleSelect: (id: string) => void;
}, SingleTreeSelectProps>(({ 
  items, 
  onSelect, 
  searchTerm, 
  currentMatchIndex = 0,
  hideUnselectedDisabledItems = false,
  value,
  previousSelections = []
}, ref) => {
  const [expandedItems, setExpandedItems] = useState<Set<string>>(new Set());
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const [matches, setMatches] = useState<MatchInfo[]>([]);
  const [currentMatchId, setCurrentMatchId] = useState<string>('');
  const itemRefs = useRef<Map<string, HTMLElement>>(new Map());
  const [previousUnitId, setPreviousUnitId] = useState<string | null>(null);
  const [previouslySelectedItems, setPreviouslySelectedItems] = useState<Set<string>>(new Set(previousSelections));
  const [previouslySelectedParents, setPreviouslySelectedParents] = useState<Set<string>>(new Set());
  
  const [isSelectionMode, setIsSelectionMode] = useState<boolean>(previousSelections.length === 0);
  const isSecondFilterRef = useRef(previousSelections.length > 0);
  
  const itemLevelMap = useRef<Map<string, number>>(new Map());
  
  const isFirstRenderRef = useRef(true);

  const isSecondFilter = () => {
    return previousSelections && previousSelections.length > 0;
  };

  useEffect(() => {
    if (isSecondFilter()) {
      if (isSelectionMode) {
        setIsSelectionMode(false);
      }
      isSecondFilterRef.current = true;
    }
  }, [previousSelections]);

  useEffect(() => {
    itemLevelMap.current.clear();
    
    const buildLevelMap = (itemsList: TDashboardUnitFilter[], level: number) => {
      itemsList.forEach(item => {
        itemLevelMap.current.set(item.id, level);
        
        if (item.children && item.children.length > 0) {
          buildLevelMap(item.children, level + 1);
        }
      });
    };
    
    buildLevelMap(items, 0);
  }, [items]);

  useEffect(() => {
    const currentPrevSelected = Array.from(previouslySelectedItems);
    const newPrevSelected = Array.from(new Set(previousSelections));
    
    const prevSelectionsChanged = JSON.stringify(currentPrevSelected.sort()) !== JSON.stringify(newPrevSelected.sort());
    
    if (prevSelectionsChanged) {
      setPreviouslySelectedItems(new Set(previousSelections));
    }
    
    if (previousSelections.length > 0) {
      if (isSelectionMode) {
        setIsSelectionMode(false);
      }
      isSecondFilterRef.current = true;
    }
  }, [previousSelections, isSelectionMode, previouslySelectedItems]);

  useEffect(() => {
    const initialSelected = getInitialSelectedItems(items, value);
    const newSelectedItems = new Set(initialSelected);
    
    // In second filter mode, pre-select the last selected item from previous filter
    if (isSecondFilterRef.current && previousSelections.length > 0 && newSelectedItems.size === 0) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      newSelectedItems.add(lastSelectedId);
    }
    
    if (JSON.stringify(Array.from(selectedItems).sort()) !== JSON.stringify(Array.from(newSelectedItems).sort())) {
      setSelectedItems(newSelectedItems);
    }
    
    if (previousSelections.length > 0) {
      const newExpandedItems = new Set(expandedItems);
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      
      if (previousUnitId !== lastSelectedId) {
        setPreviousUnitId(lastSelectedId);
      }
      
      let hasChanges = false;
      previousSelections.forEach(selectedId => {
        if (!newExpandedItems.has(selectedId)) {
          newExpandedItems.add(selectedId);
          hasChanges = true;
        }
        
        const parentIds = getParentIds(items, selectedId);
        parentIds.forEach(parentId => {
          if (!newExpandedItems.has(parentId)) {
            newExpandedItems.add(parentId);
            hasChanges = true;
          }
        });
        
        const selectedItem = findItem(items, selectedId);
        if (selectedItem && selectedItem.children) {
          selectedItem.children.forEach(child => {
            if (!newExpandedItems.has(child.id)) {
              newExpandedItems.add(child.id);
              hasChanges = true;
            }
          });
        }
      });
      
      if (hasChanges) {
        setExpandedItems(newExpandedItems);
      }
    }
  }, [items, value, previousSelections]);

  useEffect(() => {
    if (isSecondFilterRef.current && previousSelections.length > 0 && selectedItems.size === 0) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];

      setSelectedItems(new Set([lastSelectedId]));
      setPreviousUnitId(lastSelectedId);
      
      const newExpandedItems = new Set(expandedItems);
      newExpandedItems.add(lastSelectedId);
      
      const selectedItem = findItem(items, lastSelectedId);
      
      if (selectedItem) {
        if (selectedItem.children) {
          const addAllChildren = (childItems: TDashboardUnitFilter[]) => {
            childItems.forEach(childItem => {
              newExpandedItems.add(childItem.id);
              if (childItem.children && childItem.children.length > 0) {
                addAllChildren(childItem.children);
              }
            });
          };
          
          addAllChildren(selectedItem.children);
        }
        
        const parentIds = getParentIds(items, lastSelectedId);
        parentIds.forEach(parentId => {
          newExpandedItems.add(parentId);
        });
      }
      
      setExpandedItems(newExpandedItems);
    }
  }, []);

  const toggleExpand = (itemId: string, event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    
    const newExpanded = new Set(expandedItems);
    if (newExpanded.has(itemId)) {
      const itemToCollapse = findItem(items, itemId);
      if (itemToCollapse) {
        const descendantIds = getAllDescendantIds(itemToCollapse);
        newExpanded.delete(itemId);
        descendantIds.forEach(id => newExpanded.delete(id));
      }
    } else {
      newExpanded.add(itemId);
    }
    setExpandedItems(newExpanded);
  };

  const ensureItemsExpanded = () => {
    const newExpandedItems = new Set(expandedItems);
    let hasChanges = false;
    
    if (previousUnitId) {
      if (!newExpandedItems.has(previousUnitId)) {
        newExpandedItems.add(previousUnitId);
        hasChanges = true;
      }
      
      const parentIds = getParentIds(items, previousUnitId);
      parentIds.forEach(parentId => {
        if (!newExpandedItems.has(parentId)) {
          newExpandedItems.add(parentId);
          hasChanges = true;
        }
      });
      
      const previousUnit = findItem(items, previousUnitId);
      if (previousUnit && previousUnit.children && previousUnit.children.length > 0) {
        previousUnit.children.forEach(child => {
          if (!newExpandedItems.has(child.id)) {
            newExpandedItems.add(child.id);
            hasChanges = true;
          }
        });
      }
    }
    
    selectedItems.forEach(itemId => {
      if (!newExpandedItems.has(itemId)) {
        newExpandedItems.add(itemId);
        hasChanges = true;
      }
      
      const parentIds = getParentIds(items, itemId);
      parentIds.forEach(parentId => {
        if (!newExpandedItems.has(parentId)) {
          newExpandedItems.add(parentId);
          hasChanges = true;
        }
      });
    });
    
    if (hasChanges) {
      setExpandedItems(newExpandedItems);
    }
  };
  
  useEffect(() => {
    ensureItemsExpanded();
  }, [selectedItems, previousUnitId, items]);

  const handleSelect = (id: string) => {
    const item = findItem(items, id);
    if (!item) {
      return;
    }
    
    if (isItemDisabled(id)) {
      return;
    }
    
    const newSelected = new Set<string>([id]);
    
    setPreviousUnitId(id);
    
    const newExpanded = new Set(expandedItems);
    
    newExpanded.add(id);
    
    const parentIds = getParentIds(items, id);
    parentIds.forEach(parentId => {
      newExpanded.add(parentId);
    });
    
    previousSelections.forEach(selectionId => {
      newExpanded.add(selectionId);
    });
    
    if (item.children && item.children.length > 0) {
      newExpanded.add(id);
      
      const addAllChildren = (childItems: TDashboardUnitFilter[]) => {
        childItems.forEach(childItem => {
          newExpanded.add(childItem.id);
          if (childItem.children && childItem.children.length > 0) {
            addAllChildren(childItem.children);
          }
        });
      };
      
      if (item.children) {
        addAllChildren(item.children);
      }
    }
    
    setExpandedItems(newExpanded);
    setIsSelectionMode(false);
    setSelectedItems(newSelected);
    
    onSelect(Array.from(newSelected));
  };

  const findItem = (items: TDashboardUnitFilter[], id: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.id === id) return item;
      if (item.children) {
        const found = findItem(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };
    
  const getAllDescendantIds = (item: TDashboardUnitFilter): string[] => {
    const ids: string[] = [];
    if (item.children) {
      for (const child of item.children) {
        ids.push(child.id);
        ids.push(...getAllDescendantIds(child));
      }
    }
    return ids;
  };

  const expandParents = (items: TDashboardUnitFilter[], targetId: string, parents: Set<string> = new Set()): boolean => {
    for (const item of items) {
      if (item.id === targetId) {
        return true;
      }
      if (item.children) {
        if (expandParents(item.children, targetId, parents)) {
          parents.add(item.id);
          return true;
        }
      }
    }
    return false;
  };
    
  const getParentIds = (items: TDashboardUnitFilter[], targetId: string, path: string[] = []): string[] => {
    for (const item of items) {
      if (item.id === targetId) {
        return path;
      }
      if (item.children) {
        const found = getParentIds(item.children, targetId, [...path, item.id]);
        if (found.length) return found;
      }
    }
    return [];
  };

  const findAllMatches = (items: TDashboardUnitFilter[]): MatchInfo[] => {
    const matches: MatchInfo[] = [];

    const searchItems = (item: TDashboardUnitFilter) => {
      if (item.label.toLowerCase().includes(searchTerm?.toLowerCase() || '')) {
        matches.push({ id: item.id, label: item.label });
      }
      
      if (item.children) {
        item.children.forEach(searchItems);
      }
    };
    
    items.forEach(searchItems);
    return matches;
  };

  useEffect(() => {
    if (searchTerm) {
      const foundMatches = findAllMatches(items);
      setMatches(foundMatches);
      
      if (foundMatches.length > 0) {
        const matchIndex = Math.min(currentMatchIndex, foundMatches.length) - 1;
        setCurrentMatchId(foundMatches[matchIndex]?.id || '');
      } else {
        setCurrentMatchId('');
      }

      const nodesToExpand = new Set<string>(expandedItems);
      foundMatches.forEach(match => {
        nodesToExpand.add(match.id);
        const parentIds = getParentIds(items, match.id);
        parentIds.forEach(id => nodesToExpand.add(id));
      });
      
      previousSelections.forEach(selectionId => {
        nodesToExpand.add(selectionId);
        const selectedItem = findItem(items, selectionId);
        if (selectedItem && selectedItem.children) {
          selectedItem.children.forEach(child => {
            nodesToExpand.add(child.id);
          });
        }
      });
      
      setExpandedItems(nodesToExpand);
    } else {
      setMatches([]);
      setCurrentMatchId('');
      
      const nodesToExpand = new Set<string>(expandedItems);
      
      if (previousUnitId) {
        nodesToExpand.add(previousUnitId);
      }
      
      [...previousSelections, ...Array.from(selectedItems)].forEach(id => {
        nodesToExpand.add(id);
        
        const parentIds = getParentIds(items, id);
        parentIds.forEach(parentId => nodesToExpand.add(parentId));
        
        const selectedItem = findItem(items, id);
        if (selectedItem && selectedItem.children) {
          selectedItem.children.forEach(child => {
            nodesToExpand.add(child.id);
          });
        }
      });
      
      setExpandedItems(nodesToExpand);
    }
  }, [searchTerm, items, currentMatchIndex]);

  const renderHighlightedText = (text: string, itemId: string) => {
    if (!searchTerm) return text;
    
    const matchIndex = text.toLowerCase().indexOf(searchTerm.toLowerCase());
    if (matchIndex === -1) return text;

    const isCurrentMatch = itemId === currentMatchId;

    return (
      <span>
        {text.slice(0, matchIndex)}
        <HighlightedText 
          $isCurrent={isCurrentMatch}
          ref={el => {
            if (el) {
              itemRefs.current.set(itemId, el);
            }
          }}
        >
          {text.slice(matchIndex, matchIndex + searchTerm.length)}
        </HighlightedText>
        {text.slice(matchIndex + searchTerm.length)}
      </span>
    );
  };

  const isDirectMembersItem = (item: TDashboardUnitFilter): boolean => {
    return item.label.toLowerCase().includes("direct members");
  };

  const getParentOfDirectMembers = (directMembersItem: TDashboardUnitFilter): string | null => {
    const parentIds = getParentIds(items, directMembersItem.id);
    return parentIds.length > 0 ? parentIds[parentIds.length - 1] : null;
  };

  const shouldShowItem = (item: TDashboardUnitFilter): boolean => {
    if (isSecondFilterRef.current && previousSelections.length > 0) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      
      // Always show the previously selected item itself
      if (item.id === lastSelectedId) {
        return true;
      }
      
      // Show parent path of the selected item
      if (getParentIds(items, lastSelectedId).includes(item.id)) {
        return true;
      }
      
      // Show descendants of the last selected item
      if (isDescendantOf(items, item.id, lastSelectedId)) {
        return true;
      }
      
      // Handle "direct members" items
      if (isDirectMembersItem(item)) {
        const parentId = getParentOfDirectMembers(item);
        // Only show direct members of the selected item
        return parentId === lastSelectedId;
      }
      
      // Hide other items not in the hierarchy of the selected item
      return false;
    }
    
    // First filter mode - show all items
    if (isSelectionMode && (!isSecondFilterRef.current || itemLevelMap.current.get(item.id) !== 0)) {
      return true;
    }

    if (previousSelections.length === 0 && selectedItems.size === 0) {
      return true;
    }

    // Original logic for showing items based on selections
    if (previousSelections.length > 0) {
      if (previouslySelectedItems.has(item.id)) {
        return true;
      }

      const lastSelectedId = previousSelections[previousSelections.length - 1];
      
      if (getParentIds(items, lastSelectedId).includes(item.id)) {
        return true;
      }

      const currentItemLevel = itemLevelMap.current.get(item.id);
      if (currentItemLevel !== undefined) {
        for (const prevId of previousSelections) {
          const prevLevel = itemLevelMap.current.get(prevId);
          if (prevLevel !== undefined && currentItemLevel === prevLevel && item.id !== prevId) {
            const parentItem = findParentItem(items, item.id);
            if (parentItem) {
              const siblingSelected = parentItem.children?.some(sibling => 
                sibling.id !== item.id && selectedItems.has(sibling.id)
              );
              
              if (siblingSelected) {
                return false;
              }
            }
          }
        }
      }

      for (const selectedId of Array.from(selectedItems)) {
        if (item.id === selectedId) {
          return true;
        }
        
        if (isDescendantOf(items, item.id, selectedId)) {
          return true;
        }
      }
    }

    return true;
  };

  const getItemLevel = (items: TDashboardUnitFilter[], itemId: string): number => {
    return itemLevelMap.current.get(itemId) ?? -1;
  };

  const isRootItem = (items: TDashboardUnitFilter[], itemId: string): boolean => {
    return itemLevelMap.current.get(itemId) === 0;
  };

  const isItemDisabled = (itemId: string): boolean => {
    const item = findItem(items, itemId);
    if (!item) return true;

    // Items already selected in previous filters are disabled
    if (previouslySelectedItems.has(itemId)) {
      return true;
    }

    // In second filter mode
    if (isSecondFilterRef.current && previousSelections.length > 0) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      
      // Disable the previously selected item itself
      if (itemId === lastSelectedId) {
        return true;
      }
      
      // Disable parent items (they can't be selected in second filter)
      if (getParentIds(items, lastSelectedId).includes(itemId)) {
        return true;
      }
      
      // Disable items that are not descendants of the last selected item
      if (!isDescendantOf(items, itemId, lastSelectedId) && itemId !== lastSelectedId) {
        // Exception: Don't disable direct members items, they should always be selectable
        if (!item || !isDirectMembersItem(item)) {
          return true;
        }
      }
    }

    return false;
  };

  const findParentItem = (items: TDashboardUnitFilter[], targetId: string): TDashboardUnitFilter | null => {
    for (const item of items) {
      if (item.children?.some(child => child.id === targetId)) {
        return item;
      }
      if (item.children) {
        const found = findParentItem(item.children, targetId);
        if (found) return found;
      }
    }
    return null;
  };

  const shouldHideItem = (itemId: string, isSelected: boolean): boolean => {
    if (!hideUnselectedDisabledItems) return false;
    return isItemDisabled(itemId) && !isSelected;
  };

  const renderItem = (item: TDashboardUnitFilter, level: number = 0, isLastItem: boolean = false) => {
    if (previousSelections.length > 0 && 
        itemLevelMap.current.get(item.id) === 0 && 
        !previouslySelectedItems.has(item.id)) {
      return null;
    }
    
    if (!shouldShowItem(item)) return null;

    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedItems.has(item.id);
    const isSelected = selectedItems.has(item.id) || previouslySelectedItems.has(item.id);
    const isDisabled = isItemDisabled(item.id);
    
    if (shouldHideItem(item.id, isSelected)) {
      return null;
    }

    return (
      <ItemContainer key={item.id} level={level}>
        <ItemWrapper isLastItem={isLastItem}>
          <ItemRow 
            isSelected={isSelected}
            hasMatch={searchTerm ? item.label.toLowerCase().includes(searchTerm.toLowerCase()) : false}
            role="treeitem"
            tabIndex={-1}
            $isCurrentMatch={item.id === currentMatchId}
            $isDisabled={isDisabled}
          >
            <CheckboxLabel>
              <CheckboxContainer>
                <div onClick={(e) => {
                  e.stopPropagation();
                  if (!isDisabled) {
                    handleSelect(item.id);
                  }
                }}>
                  <Radio
                    checked={isSelected}
                    disabled={isDisabled}
                    size="small"
                  />
                </div>
              </CheckboxContainer>
              <ItemText isDisabled={isDisabled} isSelected={isSelected}>
                {renderHighlightedText(item.label, item.id)}
              </ItemText>
              {hasChildren && (
                <ExpandButton
                  onClick={(e) => toggleExpand(item.id, e)}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </ExpandButton>
              )}
            </CheckboxLabel>
          </ItemRow>
        </ItemWrapper>
        {hasChildren && isExpanded && (
          <ChildrenWrapper isLastItem={isLastItem}>
            <ChildrenContainer>
              {item.children!.map((child, index) => 
                renderItem(
                  child, 
                  level + 1, 
                  index === item.children!.length - 1
                )
              )}
            </ChildrenContainer>
          </ChildrenWrapper>
        )}
      </ItemContainer>
    );
  };

  const scrollToMatch = (itemId: string) => {
    const element = itemRefs.current.get(itemId);
    if (element) {
      setCurrentMatchId(itemId);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  };

  const clearSelection = () => {
    setSelectedItems(previouslySelectedItems);
    onSelect(Array.from(previouslySelectedItems));
  };

  useImperativeHandle(ref, () => ({
    scrollToMatch,
    clearSelection,
    handleSelect
  }));

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
  }, []);

  // Add debug logging
  useEffect(() => {
    
    // Force selection of previous item if in second filter mode
    if (isSecondFilterRef.current && previousSelections.length > 0 && selectedItems.size === 0) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      if (findItem(items, lastSelectedId)) {
        setSelectedItems(new Set([lastSelectedId]));
      }
    }
  }, [items, previousSelections, selectedItems]);

  // Handle changes to previousSelections
  useEffect(() => {
    // Only run this when we have previous selections and we're in second filter mode
    if (previousSelections.length > 0 && isSecondFilterRef.current) {
      
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      
      const selectedItem = findItem(items, lastSelectedId);
      
      if (selectedItem) {
        // Update selected items
        setSelectedItems(new Set([lastSelectedId]));
        setPreviousUnitId(lastSelectedId);
        
        // Expand all necessary items
        const newExpandedItems = new Set(expandedItems);
        newExpandedItems.add(lastSelectedId);
        
        // Expand parents
        const parentIds = getParentIds(items, lastSelectedId);
        parentIds.forEach(parentId => {
          newExpandedItems.add(parentId);
        });
        
        // Expand children
        if (selectedItem.children) {
          const addAllChildren = (childItems: TDashboardUnitFilter[]) => {
            childItems.forEach(childItem => {
              newExpandedItems.add(childItem.id);
              if (childItem.children && childItem.children.length > 0) {
                addAllChildren(childItem.children);
              }
            });
          };
          
          addAllChildren(selectedItem.children);
        }
        
        setExpandedItems(newExpandedItems);
      }
    }
  }, [previousSelections, items]);

  const items_to_render = !items || items.length === 0 ? [] : items.filter(item => {
    // In second filter mode, we need special handling
    if (isSecondFilterRef.current && previousSelections.length > 0) {
      const lastSelectedId = previousSelections[previousSelections.length - 1];
      
      // If the item with lastSelectedId doesn't exist in the items array, 
      // show all items to prevent a blank screen
      if (!findItem(items, lastSelectedId)) {
        return true;
      }

      // Other filtering logic...
      // Show the previously selected item
      if (item.id === lastSelectedId) {
        return true;
      }
      
      // Show items in the path of the previously selected one
      if (getParentIds(items, lastSelectedId).includes(item.id)) {
        return true;
      }
      
      // Handle "direct members" items
      if (isDirectMembersItem(item)) {
        const parentId = getParentOfDirectMembers(item);
        // Only show direct members of the selected item
        return parentId === lastSelectedId;
      }
      
      // For root items, only show those relevant to the hierarchy
      if (itemLevelMap.current.get(item.id) === 0) {
        // Only show the root item that is in the path of the selected item
        return item.id === lastSelectedId || getParentIds(items, lastSelectedId).includes(item.id);
      }
      
      // Show descendants of the selected item
      if (isDescendantOf(items, item.id, lastSelectedId)) {
        return true;
      }
      
      // Hide all other items
      return false;
    }
    
    return true;
  });

  return (
    <Container>
      <TreeSelectWrapper>
        <TreeSelectContainer>
          {!items || items.length === 0 ? (
            <div>No items to display</div>
          ) : (
            items_to_render.map((item, index) => 
              renderItem(item, 0, index === items_to_render.length - 1)
            )
          )}
        </TreeSelectContainer>
      </TreeSelectWrapper>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TreeSelectContainer = styled.div`
  padding-top: 8px;
  min-width: fit-content;
  overflow-x: auto;
  
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`;

const ItemContainer = styled.div<{ level: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${props => props.level * 24}px;
  padding-bottom: 2px;

  ${props => props.level === 0 && `
    > ${ItemWrapper}:before,
    > ${ItemWrapper}:after,
    > ${ChildrenWrapper}:before {
      display: none;
    }
  `}
`;

const ItemWrapper = styled.div<{ isLastItem?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  
  &:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 50%;
    width: 24px;
    height: 2px;
    background-color: var(--colorBrandForeground2);
  }

  &:after {
    content: '';
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${props => props.isLastItem ? '50%' : '110%'};
    background-color: var(--colorBrandForeground2);
  }
`;

const ItemRow = styled.div<{ 
  isSelected: boolean; 
  hasMatch: boolean;
  $isDisabled?: boolean;
  $isCurrentMatch?: boolean;
}>`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${props => {
    if (props.isSelected && props.$isDisabled) return 'var(--colorPaletteGreenBackground4)';
    if (props.isSelected) return 'var(--colorNeutralBackground2)';
    return 'var(--colorNeutralBackground1)';
  }};
  margin: 2px 0;
  width: fit-content;
  border: 1px solid ${props => {
    if (props.$isCurrentMatch) return '#6B4EFF';
    if (props.isSelected) return 'var(--colorBrandForeground3)';
    return 'transparent';
  }};
  min-height: 36px;
  transition: all 0.3s;
  outline: none;
  box-shadow: ${props => props.$isDisabled ? '0 0 0 1px var(--colorNeutralStroke2)' : 'none'};
  opacity: ${props => props.$isDisabled && !props.isSelected ? 0.7 : 1};

  &:hover {
    background-color: ${props => {
      if (props.isSelected && props.$isDisabled) return 'var(--colorPaletteGreenBackground4)';
      if (props.isSelected) return 'var(--colorNeutralBackground2)';
      return 'var(--colorNeutralBackground1)';
    }};
  }

  &:focus {
    box-shadow: ${props => props.$isDisabled ? '0 0 0 1px var(--colorNeutralStroke2)' : '0 0 0 2px #6B4EFF'};
  }
`;

const ItemText = styled.div<{ isDisabled: boolean; isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${props => {
    if (props.isDisabled && !props.isSelected) return 'var(--colorNeutralForeground3)';
    if (props.isDisabled && props.isSelected) return 'var(--colorNeutralForeground1)';
    if (props.isSelected) return 'var(--colorNeutralForeground1)';
    return 'inherit';
  }};
  font-weight: ${props => props.isSelected ? '500' : 'normal'};
`;

const DisabledNote = styled.span`
  font-size: 11px;
  color: var(--colorNeutralForeground3);
  font-style: italic;
  margin-top: 2px;
`;

const SelectedNote = styled.span`
  font-size: 11px;
  color: var(--colorBrandForeground3);
  display: flex;
  align-items: center;
  margin-top: 2px;
  
  svg {
    color: var(--colorBrandForeground3);
    font-size: 16px;
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-height: 24px;
  cursor: pointer;

  span {
    user-select: none;
  }
`;

const ChildrenContainer = styled.div`
  margin-left: 20px;
  width: 100%;
`;

const ExpandButton = styled.button`
  border: none;
  background-color: var(--colorNeutralBackground1);
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  svg {
    width: 20px;
    height: 20px;
    color: var(--colorBrandForeground3);
  }

  &:active {
    background-color: var(--colorNeutralBackground1);
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const ChildrenWrapper = styled.div<{ isLastItem?: boolean }>`
  position: relative;
  display: flex;
  
  &:before {
    content: '';
    position: absolute;
    left: -24px;
    top: 0;
    width: 2px;
    height: ${props => props.isLastItem ? '0' : 'calc(100% + 12px)'};
    background-color: var(--colorBrandForeground2);
  }
`;

const HighlightedText = styled.span<{ $isCurrent: boolean }>`
  color: #6B4EFF;
  font-weight: 600;
  background-color: ${props => props.$isCurrent ? '#F5F3FF' : 'transparent'};
  padding: ${props => props.$isCurrent ? '2px 4px' : '0'};
  border-radius: ${props => props.$isCurrent ? '4px' : '0'};
  scroll-margin: 100px;
`;

const TreeSelectWrapper = styled.div.attrs({ className: 'TreeSelectWrapper' })`
  flex: 1;
  overflow: auto;
  padding-bottom: 24px;
  
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorNeutralStroke1);
    border-radius: 4px;
  }
`; 