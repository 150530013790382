import styled from "styled-components";

const StyledSvg = styled.svg<{ $margin?: string }>`
  margin: ${({ $margin }) => $margin || "0"};
  min-width: 17px;
`;

type WarningIconProps = {
  color?: string;
  width?: string;
  height?: string;
  margin?: string;
};

export const WarningIconNew = ({ color, width, height, margin }: WarningIconProps) => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "24"}
    height={height || "25"}
    viewBox="0 0 24 25"
    fill="none"
    $margin={margin}
  >
    <path
      d="M2 21.375H22L12 4.375L2 21.375ZM12.9091 18.6908H11.0909V16.9013H12.9091V18.6908ZM12.9091 15.1118H11.0909V11.5329H12.9091V15.1118Z"
      fill={color || "#FA6B6B"}
    />
  </StyledSvg>
);
