import { observer } from "mobx-react";
import styled from "styled-components";
import store from "../store";
import TablePermission from "./TablePermission";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSearchField from "components/customSearchField";
import SearchResultBanner from "components/searchResultBanner";

const MainScreen = observer(() => {
  return (
    <>
      <FlexJustifyBetweenBox>
        <Header id="SurveyPermissions_Title">Survey Permissions</Header>
        <CustomSearchField
          id="SurveyPermissions_SearchInput"
          placeholder="Search by survey project name"
          searchedValue={store.surveySearchedValue}
          onKeyDown={store.surveyKeyPress}
          onChange={(e) => store.changeSurveySearchedValue(e.target.value)}
          onClearClick={() => store.onClearSurveySearchClicked()}
        />
      </FlexJustifyBetweenBox>

      {store.surveyResultSearchValue && (
        <SearchResultBanner
          resultsCount={store.surveyDataCount}
          searchText={store.surveyResultSearchValue}
          onClearContainer={() => {
            store.onClearSurveySearchClicked();
          }}
          clearLabel="Clear search"
        />
      )}

      {!store.surveyPermissionIsFetched ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <TablePermission />
      )}
    </>
  );
});

const FlexJustifyBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 41px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MainScreen;
