import React from "react";
import styled from "styled-components";
import InfoIcon from "@mui/icons-material/Info";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import store from "./workspaces-sidebar/StoreLayout";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

export type NotificationType = "SUCCESS" | "WARNING" | "INFO";

export interface Notification {
  id: string;
  key: string;
  account_id: number;
  type: NotificationType;
  message: string;
  hyperlink_message?: string | null;
  hyperlink_url?: string | null;
  created_at: string;
  dismissed_at?: string | null;
}

interface BannerProps {
  data: Notification;
  dataIndex: number;
  totalData: number;
  onDismiss?: (id: string) => void;
  updateIndex: (action: string) => void;
}

interface BannerContainerProps {
  type: NotificationType;
}

const AlertBanner: React.FC<BannerProps> = ({
  data,
  dataIndex,
  totalData,
  onDismiss,
  updateIndex,
}) => {
  const { type, message, hyperlink_message, hyperlink_url, id } = data;

  const getIcon = () => {
    const iconProps = {
      style: {
        fontSize: 20,
        color: type === "SUCCESS" ? "#16a34a" : type === "WARNING" ? "#dc2626" : "#7562FC",
      },
    };

    switch (type) {
      case "SUCCESS":
        return <CheckCircleIcon {...iconProps} />;
      case "WARNING":
        return <ErrorIcon {...iconProps} />;
      default:
        return <InfoIcon {...iconProps} />;
    }
  };

  const handleDismissClick = () => {
    if (onDismiss) {
      onDismiss(id);
    }
  };

  const handleLeftClick = () => {
    updateIndex("Decrease");
  };

  const handleRightClick = () => {
    updateIndex("Increase");
  };

  const nav = useNavigate();

  return (
    <BannerContainer type={type} id={id}>
      <BannerContent>
        <LeftSection>
          {getIcon()}
          <Message>{message}</Message>
          {hyperlink_message && hyperlink_url && (
            <LinkWrap to={hyperlink_url}>{hyperlink_message}</LinkWrap>
          )}
        </LeftSection>

        <RightSection>
          <DismissButton onClick={handleDismissClick}>
            Dismiss
            <CloseIcon style={{ fontSize: 20 }} />
          </DismissButton>

          <MultipleBannerSection
            style={
              store.featureFlagMultiBanner?.enabled &&
              store.featureFlagMultiBanner?.multi_banner_enabled
                ? null
                : MultipleBannerSectionStyle.hideSection
            }
          >
            <StyledIconButton
              {...multiBannerButton}
              onClick={handleLeftClick}
              disabled={dataIndex <= 0}
            >
              <KeyboardArrowLeftRoundedIcon {...multiBannerIcon} />
            </StyledIconButton>
            <MultipleBannerText>
              {" "}
              {dataIndex + 1} of {totalData}{" "}
            </MultipleBannerText>
            <StyledIconButton
              {...multiBannerButton}
              onClick={handleRightClick}
              disabled={dataIndex + 1 >= totalData}
            >
              <KeyboardArrowRightRoundedIcon {...multiBannerIcon} />
            </StyledIconButton>
          </MultipleBannerSection>
        </RightSection>
      </BannerContent>
    </BannerContainer>
  );
};

const BannerContainer = styled.div<BannerContainerProps>`
  width: 100%;
  height: 52px;
  background-color: #e4e9ff;
  font-family: Roboto;
`;

const BannerContent = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 0 24px 0 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: auto;
`;

const Message = styled.p`
  color: #3e4450;
  margin: 0;
`;

const LinkWrap = styled(Link)`
  color: #1477f8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const DismissButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #1477f8;
  margin-left: 16px;

  &:hover {
    opacity: 0.8;
  }

  svg {
    color: #1477f8;
  }
`;

const MultipleBannerSection = styled.div`
  display: flex;
  align-items: center;
`;

const MultipleBannerSectionStyle = {
  hideSection: {
    display: "none",
  },
};

const MultipleBannerText = styled.p`
  color: #3e4450;
  margin: 0;
  padding-left: 5px;
  padding-right: 5px;
`;

const multiBannerButton = {
  style: {
    height: "20px",
    width: "20px",
    backgroundColor: "#FFFFFF",
  },
};

const multiBannerIcon = {
  style: {
    fontSize: 15,
    color: "#1477F8",
  },
};

const StyledIconButton = styled(IconButton)`
  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

export default AlertBanner;
