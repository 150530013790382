import { TDashboardUnitFilter } from "types/dashboard";
import { decodeUnicode } from "utils/decodeUnicode";
import topStore  from "features/Dashboard/store";

type ProcessedFilter = { id: number; name: string; segments: string[] };

/**
 * Processes dashboard filters with special handling for unit filters
 * @param filters Original filters array
 * @returns Processed filters array
 */
export const processFilters = (filters: { id: number; name: string; segments: string[] }[], unitFilters: TDashboardUnitFilter[]): ProcessedFilter[] => {
  return filters.map(filter => {

    // Special handling for unit filters
    if (filter.name.toLowerCase() === "unit" && topStore.isDashboardUnitFilterEnabled) {
      const flattenedUnitFilters = flattenUnitFilters(unitFilters);
      console.log("flattenedUnitFilters", JSON.stringify(flattenedUnitFilters, null, 2));
      
      // Flatten and process segments
      const segments = filter.segments
        .flatMap(segment => {
          // Handle direct members case by removing '-direct' suffix from segment
          const isDirect = segment.endsWith("-direct");
          const segmentWithoutDirect = isDirect 
            ? segment.replace("-direct", "") 
            : segment;
          
          const unit = flattenedUnitFilters.find(u => u.id.toString() === segment);
          
          // If it's a direct filter with single segment, return only the segment without unit values
          if (isDirect && filter.segments.length === 1) {
            return segmentWithoutDirect;
          }

          if (unit) {
            // Handle -direct suffix in each value of the array
            return Array.isArray(unit.value) ? unit.value.map(val => 
              typeof val === 'string' && val.endsWith("-direct") ? val.replace("-direct", "") : val
            ) : [];
          }
          
          // If no unit found, remove -direct suffix if present
          return typeof segment === 'string' ? segment.replace("-direct", "") : segment;
        })
        // Remove duplicates
        .filter((value, index, self) => self.indexOf(value) === index);

      console.log("segments", JSON.stringify(segments, null, 2));
      return {
        id: filter.id,
        name: filter.name,
        segments : typeof segments === "string" 
          ? [decodeUnicode(segments)] 
          : segments.map(v => typeof v === 'string' ? decodeUnicode(v) : String(v))
      };
    }

    // Default handling for other filters
    return {
      id: filter.id,
      name: filter.name,
      segments: typeof filter.segments === "string" 
        ? [decodeUnicode(filter.segments)] 
        : filter.segments.map(v => typeof v === 'string' ? decodeUnicode(v) : String(v))
    };
  }).filter((filter): filter is ProcessedFilter => filter !== null);
};

/**
 * Flattens hierarchical unit filters into a single-level array
 * @param unitFilters Array of unit filters that may contain children
 * @returns Flattened array of unit filters
 */
export const flattenUnitFilters = (unitFilters: TDashboardUnitFilter[]): TDashboardUnitFilter[] => {
  return unitFilters.reduce<TDashboardUnitFilter[]>((acc, filter) => {
    acc.push(filter);
    if (filter.children?.length) {
      acc.push(...flattenUnitFilters(filter.children));
    }
    return acc;
  }, []);
};

