import { IconWord } from "./IconWord";
import { observer } from "mobx-react";
import { useScrollSync } from "react-use-scroll-sync";
import { FC, useEffect, useRef, useState } from "react";

import store from "../store";
import topStore from "../../store";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { ROLE_LIST } from "constants/role-list";
import styled from "styled-components";
import LeftBar from "./LeftBar";
import GraphBody from "./GraphBody";
import ErrorIcon from "@mui/icons-material/Error";
import SettingsIcon from "@mui/icons-material/Settings";
import CustomGraphHeader from "./GraphHeader";
import Button from "components/button/Button";
import MainStore from "../../store"
import InfoIcon from "@mui/icons-material/Info";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const BENCHMARK_OPTIONS = [
  { value: "all", label: "Show all available benchmarks" },
  { value: "enps_only", label: "eNPS benchmark only" },
  { value: "dimensions_mixed", label: "Dimensions (mixed availability)" },
  { value: "dimensions_complete", label: "Dimensions (complete benchmarks only)" },
  { value: "overall_none", label: "Overall (no contributing benchmarks)" },
  { value: "overall_some", label: "Overall (some contributing benchmarks)" },
  { value: "overall_all", label: "Overall (all contributing benchmarks)" },
  { value: "none", label: "Hide all benchmarks" }
];

type GraphWrapperProps = {};

const GraphWrapper: FC<GraphWrapperProps> = observer(() => {
  const [isSticky, setIsSticky] = useState(false);

  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);
  const ref3 = useRef<HTMLDivElement>(null);

  useScrollSync([ref1, ref2, ref3], {
    horizontal: true,
    vertical: false,
    proportional: true,
  });

  const handleScroll = (el: HTMLElement) => {
    setIsSticky(Boolean(el.scrollTop > 80));
  };

  const handleBenchmarkChange = (value: string) => {
    store.setBenchmarkDisplayOption(value);
  };
  
  // Function to get benchmark legend text based on selected option
  const getBenchmarkLegendText = () => {
    switch(store.benchmarkDisplayOption) {
      case 'enps_only':
        return "eNPS benchmark only";
      case 'dimensions_mixed':
        return "Dimensions with mixed benchmark availability";
      case 'dimensions_complete':
        return "Dimensions with complete benchmarks only";
      case 'overall_none':
        return "Overall score (no contributing benchmarks)";
      case 'overall_some':
        return "Overall score (some contributing benchmarks)";
      case 'overall_all':
        return "Overall score (all contributing benchmarks)";
      case 'none':
        return "";
      default:
        return "All available benchmarks";
    }
  };

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");

    el.addEventListener("scroll", () => handleScroll(el));

    return () => {
      window.removeEventListener("scroll", () => handleScroll(el));
    };
  });

  return (
    <Container>
      <SegmentsList>
        <SegmentsListTitle $isSticky={isSticky}>
          <Title>Hotspots chart</Title>
          <SubTitle $isSegments>Segments</SubTitle>
          <SelectSegmentsButton
            variant="contained"
            onClick={() => store.setData(true, "isOpenSetupSegmnetsPopup")}
          >
            <SettingsIcon />
            {store.selectedSegments.length === 0 ? "Set up segments" : "Reselect segments"}
          </SelectSegmentsButton>
        </SegmentsListTitle>
        <List>
          <LeftBar />
        </List>
      </SegmentsList>

      <GraphContainer>
        <GraphHeader $isSticky={isSticky}>
          <Row>
            <Group>
              <Text>Columns: Dimensions</Text>
              <SetUpButton
                variant="text"
                onClick={() => store.setData(true, "isOpenColumnsSetupPopup")}
              >
                Set up columns
              </SetUpButton>
            </Group>
            <ActionsGroup>
              {topStore.isDashboardMockBenchmarkEnabled && (
                <BenchmarkSelectorWrapper>
                  <BenchmarkIcon src="/assets/ic_flag_benchmark.svg" alt="Benchmark" />
                  <BenchmarkLabel>Benchmarks:</BenchmarkLabel>
                  <FormControl size="small">
                    <StyledSelect
                      value={store.benchmarkDisplayOption || "all"}
                      onChange={(e) => handleBenchmarkChange(e.target.value as string)}
                      IconComponent={KeyboardArrowDownIcon}
                      displayEmpty
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            borderRadius: '4px',
                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            maxHeight: '300px',
                          }
                        },
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                      }}
                    >
                      {BENCHMARK_OPTIONS.map((option) => (
                        <StyledMenuItem key={option.value} value={option.value}>
                          {option.label}
                        </StyledMenuItem>
                      ))}
                    </StyledSelect>
                  </FormControl>
                  <Tooltip title="Filter by benchmark availability: complete dimensions, overall with all/some/no benchmarks, or eNPS only." placement="top" arrow>
                    <InfoIconWrapper>
                      <InfoIcon fontSize="small" />
                    </InfoIconWrapper>
                  </Tooltip>
                </BenchmarkSelectorWrapper>
              )}
              <DownloadButton 
                variant="text" 
                onClick={() => {
                  store.sendHotspotsEmail();
                }} 
              >
                <IconWord />
                Download hotspots data (.xlsx)
              </DownloadButton>
            </ActionsGroup>
          </Row>
          <div className="sync-fake-scrollable-header" ref={ref1}>
            <FakeBody>
              {store.graphData &&
                store.graphData?.categories?.map((_, index) => <FakeItem key={index}></FakeItem>)}
            </FakeBody>
          </div>
          <div className="sync-scrollable-header" ref={ref2}>
            <CustomGraphHeader />
            {topStore.isDashboardMockBenchmarkEnabled && store.benchmarkDisplayOption !== 'none' && (
              <BenchmarkLegend>
                <BenchmarkIcon src="/assets/ic_flag_benchmark.svg" alt="Benchmark" />
                <span>{getBenchmarkLegendText()}</span>
              </BenchmarkLegend>
            )}
          </div>
        </GraphHeader>
        <div className="sync-scrollable-body" ref={ref3}>
          <GraphBody />
        </div>
        {!store.selectedSegments.length && (
          <EmptyState>
            <ErrorIcon />
            <EmptyStateText>
              Set up your segments and you'll be able to analyze more hotspots here
            </EmptyStateText>
          </EmptyState>
        )}
      </GraphContainer>
    </Container>
  );
});

export default GraphWrapper;

const Container = styled.div`
  width: 100%;
  background-color: var(--colorPaletteGrayBackground1);
  margin-top: 16px;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  min-height: 480px;
  border: 1px solid var(--colorNeutralBackground3);
`;

const SegmentsList = styled.div`
  width: 100%;
  height: 100%;
  max-width: 308px;
`;

const GraphContainer = styled.div`
  width: calc(100% - 308px);
  position: relative;
`;

const Title = styled.h1`
  font-family: Roboto;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  color: var(--colorPaletteVioletBackground1);
  margin: 0px 0px 3px 0px;
`;

const SubTitle = styled.p<{ $isSegments?: boolean }>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 44px;
  color: ${(props) =>
    props.$isSegments ? `var(--colorPaletteVioletBackground1)` : `var(--colorNeutralForeground1)`};
`;

const SelectSegmentsButton = styled(Button)`
  margin-top: 8px !important;
  text-transform: none !important;

  svg {
    font-size: 18px;
    margin-right: 8px;
  }
`;

const List = styled.div`
  width: 100%;
  box-shadow: 8px -4px 12px 0px var(--colorShadowInverted1);
`;

const GraphHeader = styled.div<{ $isSticky?: boolean }>`
  height: 208px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colorNeutralBackground1);
  position: sticky;
  top: 137px;
  transition: all 0.3s;
  z-index: 2;
  border-radius: 0px 10px 0px 0px;
  ${(props) => props.$isSticky && "box-shadow: 0px 5px 5px -5px #10141f29"};
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const ActionsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const BenchmarkSelectorWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BenchmarkIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
`;

const BenchmarkLabel = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);
  margin-right: 8px;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  min-width: 200px;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  color: var(--colorNeutralForeground1);
  
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorNeutralBackground3);
  }
  
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--colorBrandForeground1);
  }
  
  .MuiSelect-select {
    padding: 6px 12px;
  }
  
  .MuiSvgIcon-root {
    color: var(--colorNeutralForeground3);
    font-size: 20px;
    right: 6px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  height: 36px;
  padding: 6px 12px;
  
  &.Mui-selected {
    background-color: var(--colorBrandBackground1);
    color: var(--colorBrandForeground1);
  }
  
  &:hover {
    background-color: var(--colorNeutralBackground2);
  }
`;

const InfoIconWrapper = styled.div`
  cursor: pointer;
  margin-left: 6px;
  display: flex;
  align-items: center;
  color: var(--colorNeutralForeground2);
  
  &:hover {
    color: var(--colorBrandForeground1);
  }
`;

const DownloadButton = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;

  svg {
    margin-right: 8px;
  }
`;

const SetUpButton = styled(Button)`
  text-transform: none !important;
  padding: 0px !important;
  margin-left: 8px !important;
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorPaletteVioletBackground1);
`;

const SegmentsListTitle = styled.div<{ $isSticky?: boolean }>`
  height: 208px;
  width: 100%;
  padding: 40px 24px;
  background-color: var(--colorNeutralBackground1);
  position: sticky;
  top: 137px;
  transition: all 0.3s;
  z-index: 3;
  border-radius: 10px 0px 0px 0px;
  ${(props) =>
    props.$isSticky
      ? "box-shadow: 0px 5px 5px -5px #10141f29"
      : "box-shadow: 20px -2px 14px -10px var(--colorShadowInverted1)"};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 51px 25px 2px 25px;
`;

const FakeItem = styled.div`
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 1px;
  margin: 0px 1px;
`;

const FakeBody = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 4px;
`;

const EmptyState = styled.div`
  max-width: 382px;
  padding: 18px;
  display: grid;
  grid-template-columns: 36px 1fr;
  background-color: var(--colorPalleteLightBlue);
  position: absolute;
  bottom: 56px;
  left: calc(50%);
  transform: translate(-50%, 0%);

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyStateText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const BenchmarkLegend = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 0 16px 0;
  font-family: Roboto;
  font-size: 12px;
  color: var(--colorNeutralForeground2);
  padding-left: 25px;
  position: relative;
  z-index: 2;
`;
