import http from "api/https";
import { createUrl } from "../../helpers/url";

export const getFeatureFlagStatus = (flagKey: string): Promise<any> => {
  const featureFlagHost = process.env.REACT_APP_FEATURE_FLAG_HOST || "";
  const featureFlagEnvironment = process.env.REACT_APP_FEATURE_FLAG_ENV || "";
  const featureFlagProfile = process.env.REACT_APP_FEATURE_FLAG_PROFILE || "";

  if (!featureFlagHost || !featureFlagEnvironment || !featureFlagProfile) {
    return Promise.resolve(null);
  }

  const query: {
    flag: string;
  } = {
    flag: flagKey,
  };

  const url = createUrl({
    baseUrl: featureFlagHost,
    path: `/applications/engagerocket/environments/${featureFlagEnvironment}/configurations/${featureFlagProfile}`,
    query: query,
  });
  
  return http.getGO(url);
};
